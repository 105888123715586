import { validate as uuidValidate } from "uuid";
import { Evidence, EvidenceLocation } from "../../models/Evidence";
import { labeledObject } from "./interfaces";
import { extraTruncated, maxTextLength } from "../configs";
import { defaultFontSize, largeFontSize, smallFontSize } from "../theme/default";
import { AddEvidenceForm } from "../addEvidenceForms/AddEvidenceForm";
import { Platforms } from "../../models/Platform";
import { formatDateToUTC } from "./dateFormatters";
import { EmailTypes } from "../../models/Email";
import { City, Country } from "../../models/Locations";
import { EvidenceFilters } from "../../models/EvidenceFilters";

export interface S3FilenameProps {
  // UUID v4 generated from the uuid library
  uuid: string;
  // For example ".jpg"
  fileExtension: string;
  // If there are multiple attachments for the same object we need a filename qualifier
  attachmentIndex?: number;
}

export function formatUniqueS3Filename({
  uuid,
  fileExtension,
  attachmentIndex,
}: S3FilenameProps): string {
  const startsWithPeriod = /^\./;
  if (startsWithPeriod.test(fileExtension) && uuidValidate(uuid)) {
    return attachmentIndex
      ? `${uuid}_${attachmentIndex}${fileExtension}`
      : `${uuid}${fileExtension}`;
  }
  throw new Error("Invalid parameters provided during S3 name generation.");
}

interface CountryDisplay {
  name: string;
  flag?: string;
  locations?: (string | undefined)[];
}

// Reconstruct nested locations list of countries with cities listed as secondary data
export function formatLocationsForDisplay(locations: string[], countries: Country[]) {
  let list = [] as CountryDisplay[];

  // Create unique set of countries from locations
  const countrySet = new Set(locations.map((location) => location.slice(0, 2)));
  for (const countryCode of countrySet) {
    // Look up country and filter locations
    const country = countries.find((country) => country.id === countryCode);
    const filteredLocations = locations
      .filter((location) => location.slice(0, 2) === countryCode)
      .map((location) => location.split("|")[1]);

    // Build country-first list
    list.push({
      name: country?.label || "",
      // Add flag syntax per flag-icons package
      flag: countryCode ? "fi fi-" + countryCode.toLowerCase() : undefined,
      // Filter undefined locations, alphabetize the rest
      locations: filteredLocations.filter((item) => item !== undefined).sort(),
    });
  }

  // Alphabetize by country and return
  list.sort((a, b) => a.name.localeCompare(b.name));
  return list;
}

// Format City name to cities-index ID
export function formatCityNameAsId(input: string): string {
  const splitInput = input.split("|");
  let normalized = splitInput[1].replace(/\s+/g, "_");
  normalized = normalized.replace(/[^a-zA-Z0-9_]/g, "-");
  normalized = normalized.toLowerCase();

  return splitInput[0].toLowerCase() + "_" + normalized;
}

// Get location name and lat/long
export function formatLocationsForMap(key: string, countries: Country[], cityData: any) {
  const splitKey = key.split("|");
  let location = {} as { name: string; latitude: string; longitude: string };
  const country = countries.find((country) => country.id === splitKey[0]);
  let city: any;

  if (splitKey.length > 1) {
    const cityId = formatCityNameAsId(key);
    city = cityData[cityId];
  }
  if (city) {
    location = {
      // Append country code to sub-national location names
      name: city.name + ", " + country?.label,
      latitude: city.latitude ? city.latitude.toString() : "",
      longitude: city.longitude ? city.longitude.toString() : "",
    };
  } else {
    if (country) {
      location = {
        name: country.label,
        latitude: country.latitude.toString(),
        longitude: country.longitude.toString(),
      };
    }
  }

  return location;
}

// Filter locations for specific country
export function formatLocationsForDropdown(locations?: string[], country?: string) {
  if (!locations || !country) {
    return [];
  }
  const filteredLocations = locations?.filter((location) => location.startsWith(country));

  // Split off the country code so its just the location name
  return filteredLocations.map((location) => location.split("|")[1]);
}

// Flatten locations to operate in String[] fields
export function flattenLocations(locations?: EvidenceLocation[]) {
  if (!locations) {
    return [];
  }
  return locations.map((location) =>
    location.locationName
      ? location.countryCode + "|" + location.locationName
      : location.countryCode
  );
}

// Isolate countries from all locations (unique first two chars)
export function getCountriesFromLocations(locations: string[], countries: Country[]): string[] {
  const firstTwoChars = locations.map((location) => location.slice(0, 2));
  const uniqueChars = Array.from(new Set(firstTwoChars));
  // Alphabetize
  uniqueChars.sort((a, b) => {
    const aLabel = countries.find((country) => country.id === a)?.label || "";
    const bLabel = countries.find((country) => country.id === b)?.label || "";
    if (aLabel < bLabel) {
      return -1;
    }
    if (aLabel > bLabel) {
      return 1;
    }
    return 0;
  });
  return uniqueChars;
}

// This function reconstructs the fill locations string[]
// By folding one country's newLocations with untouched countries
export function formatLocationsChanges(
  value: labeledObject[],
  country?: string,
  locations?: string[]
) {
  let mergedLocations = [] as string[];
  const valueIds = value.map((value) => value.id);
  if (!locations) {
    locations = [];
  }

  if (country === undefined) {
    // Handle Country Changes
    // Loop through the locations and check if the beginning of the string matches any value.id
    for (const location of locations) {
      if (valueIds.some((id) => location.startsWith(id))) {
        mergedLocations.push(location);
      }
    }

    // Check if there are any value.id that have no matching locations
    for (const id of valueIds) {
      if (!mergedLocations.some((location) => location.startsWith(id))) {
        mergedLocations.push(id);
      }
    }
  } else if (country) {
    // Handle City Changes
    const otherCountryLocations = locations.filter((location) => !location.startsWith(country));
    const countryLocations =
      valueIds.length > 0
        ? valueIds.map((id) => country + "|" + value.find((item) => item.id === id)?.label)
        : [country];
    mergedLocations = [...otherCountryLocations, ...countryLocations];
  }

  return mergedLocations;
}

// Function to format EvidenceLocations[] into string[]
export function formatEvidenceLocationsForReviewSubmit(locations: EvidenceLocation[]): string[] {
  return locations.map((location) => {
    let formattedLocation = "";
    if (location.countryCode) {
      formattedLocation += location.countryCode;
    }
    if (location.locationName) {
      formattedLocation += "|" + location.locationName;
    }
    return formattedLocation;
  });
}

interface TruncateTextProps {
  text?: string;
  maxLength?: number;
  isNotTruncated?: boolean;
  isExtraTruncated?: boolean;
}

// Truncate text to a specified length and append "..." if it exceeds the maximum length
export const truncateText = ({
  text,
  maxLength = maxTextLength,
  isNotTruncated,
  isExtraTruncated,
}: TruncateTextProps): string => {
  if (text?.length) {
    if (text.length > maxLength && !isNotTruncated) {
      return text.slice(0, isExtraTruncated ? extraTruncated : maxLength) + "...";
    } else {
      return text;
    }
  } else {
    return "";
  }
};

// Select body font size
export const getBodyFontSize = (fontSize?: string) => {
  switch (fontSize) {
    case "small":
      return smallFontSize;
    case "default":
      return defaultFontSize;
    case "large":
      return largeFontSize;
    default:
      return "auto";
  }
};

interface StringMap {
  [key: string]: string | undefined;
}

interface StringListMap {
  [key: string]: string[] | undefined;
}

// Format ID / keyword search terms from free text
// Formats a CSV String object to a list
export const csvStringObjToList = (obj: StringMap): StringListMap => {
  const key = Object.keys(obj)[0];
  const text = obj[key];
  if ((text?.length && text.length < 3) || !text?.length) {
    return { [key]: undefined };
  }
  const cleanedString = text?.split(",").map((term) => term.trim());
  return { [key]: cleanedString };
};

// Format Evidence for AddEvidenceFormData
export function formatEvidenceForReviewSubmit(evidence: Evidence): AddEvidenceForm {
  const platformObject = (Object.keys(Platforms) as Array<keyof typeof Platforms>).find(
    (key) => Platforms[key].name === evidence.platform
  );

  return {
    id: evidence.id,
    status: evidence.status,
    platformObject: platformObject ? Platforms[platformObject] : undefined,
    text: evidence.text,
    date: formatDateToUTC(evidence.date),
    mediaName: evidence.mediaName,
    socialMediaPostType: evidence.socialMediaPostType,
    locations: evidence.locations
      ? formatEvidenceLocationsForReviewSubmit(evidence.locations)
      : undefined,
    url: evidence.url,
    originalPost: evidence.originalPost,
    likeCount: evidence.likeCount,
    commentCount: evidence.commentCount,
    shareCount: evidence.shareCount,
    viewCount: evidence.viewCount,
    retweetCount: evidence.retweetCount,
    notes: evidence.notes,
    attachments: evidence.attachments,
    authorId: evidence.authorId,
  };
}

// generates an AMP route URL with id param, using the current host domain
export function formatHyperlink(id: string, emailType?: EmailTypes): string {
  let route: string;
  switch (emailType) {
    case EmailTypes.NeedsDetail:
      route = "reviewSubmit";
      break;
    case EmailTypes.ReviewComplete:
      route = "analyze";
      break;
    default:
      route = "qa";
  }

  const splitURL = window.location.host.split(".");
  const subdomain =
    splitURL[0] === "amp"
      ? splitURL[0]
      : splitURL[0].startsWith("local")
      ? "develop.amp"
      : splitURL[0] + ".amp";

  return `https://${subdomain}.rootwise.co/${route}?id=${id}`;
}

// Get current stage
export function getStage(): string {
  const subdomain = window.location.host.split(".")[0];
  const stage = subdomain === "amp" ? "prod" : subdomain === "test" ? "staging" : "dev";
  return stage;
}

interface AppendUrlProps {
  baseFilters: EvidenceFilters;
  idTerm?: string;
  idString?: string;
  trendingPeriod: number;
}
// Convert current evidenceFilters to URL Search params
export function appendUrlParamsFromFilters(props: AppendUrlProps): URLSearchParams {
  const urlParams = new URLSearchParams(window.location.search);

  // Append countries from baseFilters to urlParams
  if ((props.baseFilters.countries?.length ?? 0) > 0) {
    const filter = props.baseFilters.countries?.join(",");
    if (filter) {
      if (filter) {
        urlParams.append("countries", filter);
      }
    }
  }

  // Append issues from baseFilters to urlParams
  if ((props.baseFilters.issueIds?.length ?? 0) > 0) {
    const filter = props.baseFilters.issueIds?.join(",");
    if (filter) {
      if (filter) {
        urlParams.append("issueIds", filter);
      }
    }
  }

  // Append idTerm & date filter
  if (props.idTerm && props.idString) {
    urlParams.append(props.idTerm, props.idString);
  }

  urlParams.append("days", props.trendingPeriod.toString());

  return urlParams;
}
