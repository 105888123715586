// Global
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAuthToken from "./useAuthToken";

// Local
import { locationsQuery } from "../lib/api/keyConstants";
import useAxios from "./useAxios";
import { AxiosResponse } from "axios";
import { LocationQueryTypes } from "../lib/util/enums";
import { City, Country, State } from "../models/Locations";

interface GetLocationsProps {
  apiToken: string;
  locationType?: LocationQueryTypes;
  docIds?: string[];
  countryCodes?: string[];
  stateCodes?: string[];
}

interface QueryProps {
  queryKey: [string, GetLocationsProps];
}

// getThemesIssues business logic
const getLocations = async ({ queryKey }: QueryProps) => {
  const [_key, props] = queryKey;
  return await useAxios({
    token: props.apiToken,
    endpoint: "getLocations",
    params: {
      type: props.locationType,
      // JSON.stringify(evidenceFilters.ids)
      docIds: props.docIds && props.docIds.length > 0 ? JSON.stringify(props.docIds) : undefined,
      countryCodes: JSON.stringify(props.countryCodes),
      stateCodes: JSON.stringify(props.stateCodes),
    },
  });
};

interface ParseProps {
  data: AxiosResponse;
  locationType?: LocationQueryTypes;
}

// Parse results from API into a sorted list of location objects
const parseResults = ({ data, locationType }: ParseProps) => {
  if (!locationType) {
    return;
  }
  const hits = data.data.response.body.hits.hits;

  const createLocation = (hit: any) => {
    switch (locationType) {
      case LocationQueryTypes.Countries:
        return new Country(
          hit._id,
          hit._source.name,
          hit._source.flag,
          hit._source.latitude,
          hit._source.longitude
        );
      case LocationQueryTypes.States:
        return new State(
          hit._id,
          hit._source.name,
          hit._source.countryCode,
          hit._source.isoCode,
          hit._source.latitude,
          hit._source.longitude
        );
      case LocationQueryTypes.Cities:
        return new City(
          hit._id,
          hit._source.name,
          hit._source.countryCode,
          hit._source.stateCode,
          hit._source.latitude,
          hit._source.longitude
        );
      default:
        throw new Error(`Invalid location locationType: ${locationType}`);
    }
  };

  const locations = hits.map(createLocation);
  locations.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return { [locationType.toLowerCase()]: locations };
};

interface Props {
  type?: LocationQueryTypes;
  // docIds?: string[];
  countryCodes?: string[];
  stateCodes?: string[];
}

// State management for Locations
const useLocations = (props: Props) => {
  const apiToken = useAuthToken();

  // State management for calling the API
  const [locationType, setLocationType] = useState<LocationQueryTypes | undefined>(props.type);
  const [docIds, setDocIds] = useState<string[]>([]);

  // Get locations from Opensearch
  const locations = useQuery({
    queryKey: [
      locationsQuery,
      {
        apiToken,
        locationType: locationType,
        docIds: docIds,
        countryCodes: props.countryCodes,
        stateCodes: props.stateCodes,
      },
    ],
    queryFn: getLocations,
    select: (data: any) => parseResults({ data, locationType: locationType }),
    staleTime: Infinity,
    enabled: !!apiToken && !!locationType,
  });

  // API Success: Return data
  return {
    countries: locations.data?.countries || [],
    states: locations.data?.states || [],
    cities: locations.data?.cities || [],
    setLocationType,
    setDocIds
  };
};

export default useLocations;
