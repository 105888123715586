// Global
import { useTranslation } from "react-i18next";

// MUI
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { Box, Tooltip } from "@mui/material";
import { TranslationState } from "../../lib/util/enums";

interface Props {
  translationState: TranslationState | undefined;
  handleTranslationToggle: () => void;
}

// Allow user to change display language
const TranslateButton = ({
  translationState,
  handleTranslationToggle,
}: Props) => {
  const { t } = useTranslation();

  // Return null if original language is user's preferred lang
  if (translationState === TranslationState.native) {
    return null;
  }

  // Display icon with color and opacity reflecting active/inactive state
  return (
    <Box
      onClick={handleTranslationToggle}
      sx={{
        cursor: "pointer",
      }}
      display='flex'
    >
      {" "}
      <Tooltip
        title={t(
          translationState === TranslationState.translated
            ? "cards.tooltip.translation.Hide"
            : "cards.tooltip.translation.Show"
        )}
      >
        <GTranslateIcon
          color={
            translationState === TranslationState.translated
              ? "secondary"
              : "primary"
          }
        />
      </Tooltip>{" "}
    </Box>
  );
};

export default TranslateButton;
