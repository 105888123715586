// Globals
import { useState } from "react";

// Import local
import { Stack, Box, useMediaQuery, useTheme } from "@mui/material";

// Import MUI
import AmpButton from "../buttons/AmpButton";
import LinearLoader from "../navigation/LinearLoading";
import CancelAddEvidence from "./CancelAddEvidence";

interface InputNavProps {
  stepHandler: {
    currentStep: number;
    isSubmitting: boolean;
    allowNext: boolean;
    isDetailAdd: boolean;
    isFetching: boolean;
    changeStep: (direction: string) => void;
    setIsSubmitting: (value: boolean) => void;
  };
  isSubmitForLater: boolean;
  handleAddDetail: () => void;
  isReviewSubmit?: boolean;
  submitAgain: () => void;
}

// Visualize the buttons to navigate Add Signals
// Various buttons display at given times based on business logic
const AddEvidenceNavButtons = ({
  stepHandler,
  isSubmitForLater,
  handleAddDetail,
  isReviewSubmit,
  submitAgain,
}: InputNavProps) => {
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (!isSubmitForLater && (stepHandler.isSubmitting || stepHandler.isFetching)) {
    return <LinearLoader />;
  }

  const prevButton = (
    <Box display={"inline"} width={{ xs: 1, md: "auto" }}>
      <AmpButton
        text="Previous"
        type="secondary"
        symbol="arrowBack"
        onClick={() => stepHandler.changeStep("b")}
      />
    </Box>
  );

  return (
    <Stack direction={"row"} justifyContent="space-between">
      {/* Cancel button and dialogue (desktop only) */}
      {!isSmallScreen && (
        <Stack direction={"row"} spacing={2}>
          <AmpButton type="secondary" text="Cancel" onClick={() => setIsCancelDialog(true)} />
          {isCancelDialog && (
            <CancelAddEvidence
              submitAgain={submitAgain}
              isReviewSubmit={isReviewSubmit}
              setIsCancelDialog={setIsCancelDialog}
            />
          )}
          {/* Previous button (desktop only) */}
          {stepHandler.currentStep > 1 && prevButton}
        </Stack>
      )}
      {/* Next, Submit buttons */}
      <Box
        display={{ xs: "flex", md: "flex" }}
        width={{ xs: 1 }}
        justifyContent={{ xs: "start", md: "flex-end" }}
      >
        {/* QuickAdd and Add Detail */}

        <Stack
          direction={isSmallScreen ? "column" : "row"}
          width={isSmallScreen ? "100%" : "auto"}
          spacing={2}
        >
          {stepHandler.currentStep === 2 && !stepHandler.isDetailAdd && (
            <AmpButton
              text="AddQuick"
              symbol="bolt"
              disabled={!stepHandler.allowNext}
              onClick={() => {
                stepHandler.setIsSubmitting(true);
              }}
            />
          )}
          {stepHandler.currentStep === 2 && !stepHandler.isDetailAdd && (
            <AmpButton
              text="AddDetail"
              type="secondary"
              symbol="plus"
              disabled={!stepHandler.allowNext}
              // DO DO: Don't always go forward... treat like
              onClick={handleAddDetail}
            />
          )}

          {stepHandler.currentStep === 3 && (
            <AmpButton
              text="Submit"
              type="accent"
              symbol="check"
              disabled={!stepHandler.allowNext}
              onClick={() => stepHandler.setIsSubmitting(true)}
            />
          )}

          {((stepHandler.currentStep === 2 && stepHandler.isDetailAdd) ||
            (stepHandler.currentStep === 1 && stepHandler.allowNext)) && (
            <Box display={"inline"} width={{ xs: 1, md: "auto" }}>
              <AmpButton
                text="Next"
                symbol="arrowForward"
                disabled={!stepHandler.allowNext}
                onClick={() => stepHandler.changeStep("f")}
              />
            </Box>
          )}

          {isSmallScreen && stepHandler.currentStep > 1 && (
            <Box display={"flex"} justifyContent="center">
              {prevButton}
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddEvidenceNavButtons;
