// Global
import { useRef, useEffect, useState, useCallback } from "react";
import classnames from "classnames";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import SubNav from "../components/navigation/SubNav";
import MainStage from "../components/navigation/MainStage";
import { AmpCardTypes, RouteOptions } from "../lib/util/enums";
import AmpCard from "../components/cards/AmpCard";
import { useEvidence } from "../hooks/useEvidence";
import CircleLoading from "../components/navigation/CircleLoading";
import { Evidence } from "../models/Evidence";
import EvidenceCard from "../components/cards/EvidenceCard";
import useEvidenceFilters from "../hooks/useEvidenceFilters";
import LineChart from "../components/visualizations/LineChart";
import LoadMoreButton from "../components/buttons/LoadMoreButton";
import EvidenceMap from "../components/visualizations/EvidenceMap";
import DisplayResultCount from "../components/cards/DisplayResultCount";
import EvidenceFilterControls from "../components/filters/EvidenceFilterControls";
import GenerateReportControl from "../components/cards/GenerateReportControl";
import { appMobileWidth } from "../lib/theme/default";
import SentimentChart from "../components/visualizations/SentimentChart";
import BarChartViz from "../components/visualizations/BarChart";

// User view with filters, evidence cards and charts
const Analyze = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const urlParams = new URLSearchParams(window.location.search);
  const subNavRef = useRef<HTMLDivElement | null>(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  // Local hooks
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const { evidenceFilters, updateEvidenceFilters, clearEvidenceFilters } = useEvidenceFilters({
    view: RouteOptions.analyze,
    urlParams,
  });

  const { evidenceList, resultCount, chartData, error, manageNextPage, isLoading } = useEvidence({
    evidenceFilters,
  });

  // Infinite scroll
  const lastCardRef = useCallback((node: HTMLElement | null) => {
    if (node !== null && manageNextPage && manageNextPage.hasNextPage && !manageNextPage.isFetchingNextPage) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // Save current scroll position before fetching
            if (scrollContainerRef.current) {
              setScrollPosition(scrollContainerRef.current.scrollTop);
            }
            manageNextPage.fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(node);
      return () => observer.disconnect();
    }
  }, [manageNextPage]);

  // Reference for line chart visualization or null
  const displayLineChart =
    chartData?.lineChartData && chartData.lineChartData.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <LineChart lineChartData={chartData?.lineChartData} dateRange={evidenceFilters.dateRange} />
      </AmpCard>
    ) : null;

  // Reference for map visualization or null
  const displayMap =
    chartData?.mapData && chartData.mapData.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <EvidenceMap data={chartData?.mapData} />
      </AmpCard>
    ) : null;

  // Reference for sentiment viz, or null
  const displayBarChart =
    chartData?.evidenceByTheme && chartData.evidenceByTheme.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <BarChartViz barChartData={chartData?.evidenceByTheme} />
      </AmpCard>
    ) : null;

  // Reference for sentiment viz, or null
  const displaySentiment =
    chartData?.sentimentData && chartData.sentimentData.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <SentimentChart sentimentList={chartData?.sentimentData} />
      </AmpCard>
    ) : null;

  useEffect(() => {
    if (subNavRef.current) {
      setSubNavHeight(subNavRef.current.offsetHeight);
    }
  }, [evidenceFilters]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Restore scroll position after new content is loaded
  useEffect(() => {
    if (scrollContainerRef.current && scrollPosition > 0) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [evidenceList, scrollPosition]);

  return (
    <Box className={classnames({ "fade-out": fadeOut })}>
      {/* SubNav Container */}
      <SubNav view={RouteOptions.analyze} ref={subNavRef}>
        <EvidenceFilterControls
          view={RouteOptions.analyze}
          evidenceFilters={evidenceFilters}
          updateEvidenceFilters={updateEvidenceFilters}
          clearEvidenceFilters={clearEvidenceFilters}
        />
      </SubNav>

      <MainStage subNavHeight={subNavHeight} ref={scrollContainerRef}>
        {/* Display loading state */}
        {(isLoading || !evidenceList) && <CircleLoading />}
        {!!error && error.message}

        {/* Display number of results to user  */}
        <DisplayResultCount count={resultCount?.toString()} />

        <Grid container columnGap={isSmallScreen ? 0 : 2} maxWidth={appMobileWidth}>
          {/* <Grid container spacing={2} maxWidth={appMaxWidth} width={appMobileWidth}> */}
          {/* Mobile: charts at top */}
          {isSmallScreen && chartData && (
            <Grid xs={12}>
              {displayLineChart}
              {displayMap}
              {displaySentiment}
              {displayBarChart}
            </Grid>
          )}

          {/* Evidence cards */}
          <Grid xs={12} md>
            {evidenceList &&
              // Map individual evidence cards
              evidenceList.map((evidence: Evidence, i: number) =>
                // Card with infinite scroll ref trigger: 3rd to last card
                i === evidenceList.length - 1 ? (
                  <AmpCard type={AmpCardTypes.evidence} key={evidence.id} ref={lastCardRef}>
                    <EvidenceCard evidence={evidence} />
                  </AmpCard>
                ) : (
                  // All other cards
                  <AmpCard type={AmpCardTypes.evidence} key={evidence.id}>
                    <EvidenceCard evidence={evidence} />
                  </AmpCard>
                )
              )}
          </Grid>
          {/* Desktop: Charts at right */}
          {!isSmallScreen && chartData && (
            <Grid md="auto">
              {displayLineChart} {displayMap} {displaySentiment} {displayBarChart}
            </Grid>
          )}
        </Grid>
      </MainStage>
      {evidenceList && chartData && (
        <GenerateReportControl
          data={{ evidenceFilters, evidenceList, chartData }}
          setFadeOut={setFadeOut}
        />
      )}
    </Box>
  );
};

export default Analyze;
