// Global
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// MUI
import { Box, CardActionArea, Stack, Typography } from "@mui/material";

// Local
import { Report } from "../../models/GeneratedReport";
import AmpCard from "../cards/AmpCard";
import { AmpCardTypes, RouteOptions } from "../../lib/util/enums";
import { COLORS } from "../../lib/theme/default";

interface Props {
  reportList: Report[];
}

const ReportList = (props: Props) => {
  // Global
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userLang = i18n.language;
  // Local

  // Navigate to full report
  const handleClick = (id: string) => {
    navigate(`${RouteOptions.report}?id=${id}`);
  };
  return (
    <Stack my={2}>
      {props.reportList.map((report) => (
        <Box key={report.id}>
          <AmpCard type={AmpCardTypes.report}>
            <CardActionArea onClick={() => handleClick(report.id)}>
              <Typography variant="h2">{report.data.Subtitle}</Typography>
              <Stack spacing={1} mt={2}>
                <Box display="flex">
                  <Typography variant="h4" mr={0.5}>
                    {t("generatedReport.UpdatedAt")}
                  </Typography>
                  <Typography variant="h3" color={COLORS.primaryBlue}>
                    {dayjs(report.updatedAt).format("D-MMM-YYYY HH:mm")}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="h4" mr={0.5}>
                    {t("generatedReport.CreatedAt")}
                  </Typography>
                  <Typography variant="h3" color={COLORS.primaryBlue}>
                    {dayjs(report.createdAt).format("D-MMM-YYYY HH:mm")}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="h4" mr={0.5}>
                    {t("generatedReport.AuthorId")}
                  </Typography>
                  <Typography variant="h3" color={COLORS.primaryBlue}>
                    {report.authorId}
                  </Typography>
                </Box>
              </Stack>
            </CardActionArea>
          </AmpCard>
          {/* <HideListItem id={report.id} archiveItem={archiveExample} /> */}
        </Box>
      ))}
    </Stack>
  );
};

export default ReportList;
