// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EditNoteIcon from "@mui/icons-material/EditNote";

// Local
import useEditEvidence from "../../hooks/useEditEvidence";
import { Evidence } from "../../models/Evidence";
import { EvidenceStatus, RouteOptions } from "../../lib/util/enums";

interface Props {
  evidence: Evidence;
}

const ReviewControl = ({ evidence }: Props) => {
  // Global
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const [status, setStatus] = useState<EvidenceStatus>(evidence.status);

  const { submitEdits, isLoading } = useEditEvidence({
    evidenceId: evidence.id,
  });

  const handleStatus = (newValue: EvidenceStatus) => {
    // Submit change to Opensearch & reflect locally
    submitEdits("status", newValue);
    setStatus(newValue);
  };

  // Elements
  const reviewed = (
    <Tooltip title={status === EvidenceStatus.Reviewed ? "" : t("cards.tooltip.SetReviewed")}>
      <CheckCircleIcon
        color={status === EvidenceStatus.Reviewed ? "secondary" : "primary"}
        fontSize="large"
        onClick={
          status === EvidenceStatus.Reviewed
            ? undefined
            : () => handleStatus(EvidenceStatus.Reviewed)
        }
        className={status === EvidenceStatus.Reviewed ? "isNotButton" : undefined}
      />
    </Tooltip>
  );

  const archived = (
    <Tooltip title={status === EvidenceStatus.Archived ? "" : t("cards.tooltip.SetArchived")}>
      <DeleteIcon
        color={status === EvidenceStatus.Archived ? "secondary" : "primary"}
        fontSize="large"
        onClick={
          status === EvidenceStatus.Archived
            ? undefined
            : () => handleStatus(EvidenceStatus.Archived)
        }
        className={status === EvidenceStatus.Archived ? "isNotButton" : undefined}
      />
    </Tooltip>
  );

  const duplicate = (
    <Tooltip title={status === EvidenceStatus.Duplicate ? "" : t("cards.tooltip.SetDuplicate")}>
      <FileCopyIcon
        color={status === EvidenceStatus.Duplicate ? "secondary" : "primary"}
        fontSize="large"
        onClick={
          status === EvidenceStatus.Duplicate
            ? undefined
            : () => handleStatus(EvidenceStatus.Duplicate)
        }
        className={status === EvidenceStatus.Duplicate ? "isNotButton" : undefined}
      />
    </Tooltip>
  );

  const stashed = (
    <Tooltip title={status === EvidenceStatus.Stashed ? "" : t("cards.tooltip.SetStashed")}>
      <SaveAltIcon
        color={status === EvidenceStatus.Stashed ? "secondary" : "primary"}
        fontSize="large"
        onClick={
          status === EvidenceStatus.Stashed ? undefined : () => handleStatus(EvidenceStatus.Stashed)
        }
        className={status === EvidenceStatus.Stashed ? "isNotButton" : undefined}
      />
    </Tooltip>
  );

  const editEvidence = (
    <Tooltip title={t("cards.tooltip.ReviewSubmit")}>
      <EditNoteIcon
        color={"primary"}
        fontSize="large"
        onClick={() => navigate(`${RouteOptions.reviewSubmit}?id=${evidence.id}`)}
      />
    </Tooltip>
  );

  return isLoading ? (
    <Box
      mt={isSmallScreen ? 2 : 0}
      ml={isSmallScreen ? "auto" : 4}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      height="35px"
      width={isSmallScreen ? "auto" : "85px"}
    >
      {isLoading}
    </Box>
  ) : isSmallScreen ? (
    <Stack mt={2} justifyContent={"center"} direction="row" spacing={2}>
      {reviewed}
      {duplicate}
      {stashed}
      {editEvidence}
      {archived}
    </Stack>
  ) : (
    <Box ml={4}>
      {" "}
      <Stack mt={2} justifyContent={"center"} direction="row" spacing={2}>
        {reviewed}
        {archived}
      </Stack>
      <Stack mt={2} justifyContent={"center"} direction="row" spacing={2}>
        {duplicate}
        {stashed}
        {editEvidence}
      </Stack>
    </Box>
  );
};

export default ReviewControl;
