// Global
import { useContext } from "react";

// Import MUI
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

// Import local
import { CountriesContext } from "../../contexts/InitContexts";
import { InputStepProps } from "../../lib/addEvidenceForms/InputStepProps";
import TextFieldInput from "./TextFieldInput";
import { PlatformTypes, Platforms } from "../../models/Platform";
import LShapedSpacer from "./LShapedSpacer";
import { formatLocationsForDropdown, getCountriesFromLocations } from "../../lib/util/formatters";
import { numberFormSize } from "../../lib/theme/default";
import AutocompleteMultiple from "./AutocompleteMultiple";

const AddEvidenceDetail = ({ updateFormData, formData, handleAllowNext }: InputStepProps) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const countryData = useContext(CountriesContext);

  // Reusable constants for each type of interactions
  const likeCount = (
    <TextFieldInput
      field="likeCount"
      width={numberFormSize}
      inline={true}
      noTitle={formData.platformObject === Platforms.Twitter ? true : false}
    />
  );
  const commentCount = (
    <TextFieldInput field="commentCount" width={numberFormSize} inline={true} noTitle={true} />
  );
  const shareCount = (
    <TextFieldInput field="shareCount" width={numberFormSize} inline={true} noTitle={true} />
  );
  const retweetCount = (
    <TextFieldInput field="retweetCount" width={numberFormSize} inline={true} noTitle={false} />
  );

  const viewCount = (
    <TextFieldInput field="viewCount" width={numberFormSize} inline={true} noTitle={true} />
  );

  // Custom sequence of interactions specific to platform
  // This matches the sequence the user sees online for fast, easy data input
  function getSequence(platform: string) {
    switch (platform) {
      case "Twitter":
        return (
          <>
            {/* TODO: add Twitter bookmarks */}
            {retweetCount} {likeCount} {viewCount}
          </>
        );
      case "Youtube":
        return (
          <>
            {likeCount} {viewCount} {commentCount}
          </>
        );
      case "Telegram":
        return (
          <>
            {likeCount} {viewCount}
          </>
        );
      case "Instagram":
        return (
          <>
            {likeCount} {commentCount}
          </>
        );
      default:
        return (
          <>
            {/* Use for Facebook and default */}
            {likeCount} {commentCount} {shareCount} {viewCount}
          </>
        );
    }
  }

  return (
    <Box display="flex" flexDirection="column" height={1} width={1} mt={2}>
      {formData.platformObject?.type === PlatformTypes.Media && (
        <TextFieldInput field="mediaName" />
      )}

      <AutocompleteMultiple
        field="locations"
        locations={formData.locations}
        selectedValues={
          !!formData.locations ? getCountriesFromLocations(formData.locations, countryData) : undefined
        }
        updateFormData={updateFormData}
      />

      {/* If user selects a country, show specific cities  */}
      {!!formData.locations &&
        getCountriesFromLocations(formData.locations, countryData).map((country) => (
          <Stack direction="row" width={"100%"} key={country}>
            <LShapedSpacer isDropdown />
            <Box flexGrow={1}>
              <AutocompleteMultiple
                field="cities"
                country={country}
                locations={formData.locations}
                selectedValues={formatLocationsForDropdown(formData.locations, country)}
                updateFormData={updateFormData}
              />
            </Box>
          </Stack>
        ))}

      {/* If platformObject type === Social media 
             Display interactions boxes. Sequenced per specific platform */}
      {formData.platformObject?.type === PlatformTypes.SocialMedia && (
        <Stack display='block' direction={isSmallScreen ? "column" : "row"}>{getSequence(formData.platformObject.name)}</Stack>
      )}

      <TextFieldInput field="notes" rows={3} rowsMobile={4} />
    </Box>
  );
};

export default AddEvidenceDetail;
