// Local
import { EvidenceStatus, SentimentValues, SocialMediaPostType } from "../lib/util/enums";
import { DataIntensity } from "../lib/util/enums";

export class Evidence {
  readonly id: string;
  readonly status: EvidenceStatus;
  readonly attachments?: string[];
  readonly authorId?: string;
  readonly commentCount?: number;
  readonly createdAt: Date;
  readonly date: Date;
  readonly detectedLanguage?: string;
  readonly interactionCount?: number;
  readonly isMisinformation?: string;
  readonly issueIds?: string[];
  readonly likeCount?: number;
  readonly locations?: EvidenceLocation[];
  readonly mediaName?: string;
  readonly notes?: string;
  readonly originalPost?: string;
  readonly platform?: string;
  readonly retweetCount?: number;
  readonly sentiment?: SentimentValues;
  readonly shareCount?: number;
  readonly socialMediaPostType?: SocialMediaPostType;
  readonly submittedLanguage?: string;
  readonly text?: string;
  readonly textTranslated?: Record<string, string>;
  readonly themeIds?: string[];
  readonly updatedAt: Date;
  readonly url?: string;
  readonly viewCount?: number;

  constructor(data: any) {
    const source = data._source;
    this.id = data._id;
    this.status = source.hasOwnProperty("status") ? source.status : EvidenceStatus.Active;
    this.createdAt = new Date(source.createdAt);
    this.date = new Date(source.date);
    this.updatedAt = new Date(source.updatedAt);

    if (source) {
      this.attachments = source.attachments;
      this.authorId = source.authorId;
      this.commentCount = source.commentCount;
      this.locations =
        !!source.locations &&
        source.locations.map((location: any) => new EvidenceLocation(location));
      this.detectedLanguage = source.detectedLanguage;
      this.isMisinformation = source.isMisinformation;
      this.issueIds = source.issueIds;
      this.likeCount = source.likeCount;
      this.mediaName = source.mediaName;
      this.notes = source.notes;
      this.originalPost = source.originalPost;
      this.platform = source.platform;
      this.retweetCount = source.retweetCount;
      this.sentiment = source.sentiment;
      this.shareCount = source.shareCount;
      this.socialMediaPostType = source.socialMediaPostType;
      this.submittedLanguage = source.submittedLanguage;
      this.text = source.text;
      this.textTranslated = source.textTranslated;
      this.themeIds = source.themeIds;
      this.url = source.url;
      this.viewCount = source.viewCount;

      // Build interactionCount
      if (source.interactionCount) {
        this.interactionCount = source.interactionCount;
      } else if (
        source.likeCount ||
        source.commentCount ||
        source.shareCount ||
        source.retweetCount
      ) {
        this.interactionCount =
          (Number(source.likeCount) || 0) +
          (Number(source.commentCount) || 0) +
          (Number(source.shareCount) || 0) +
          (Number(source.retweetCount) || 0);
      }
    }
  }
}

// Location info built from raw evidence data
// Pre-parsed syntax: US|New York, US, CA|Toronto
export class EvidenceLocation {
  countryCode: string;
  locationName?: string;

  constructor(data: string) {
    const deconstructed = data.split("|");
    this.countryCode = deconstructed[0];
    this.locationName = deconstructed[1];
  }
}

// A structured Evidence object for charts from Opensearch data
export class EvidenceAggregatedByDay {
  count: number;
  date: Date;

  constructor(data: any) {
    this.count = data.doc_count;
    this.date = new Date(data.key);
  }
}

// A structured Evidence object for maps from Opensearch data
export class EvidenceAggregatedByLocation {
  count: number;
  location: { name: string; latitude: string; longitude: string };
  intensity?: DataIntensity;

  constructor(
    location: any,
    formattedLocation: { name: string; latitude: string; longitude: string }
  ) {
    this.count = location.doc_count;
    this.location = formattedLocation;
  }
}

// A structured object with a name/count pair for visualizations from index
export class ValueCountPair {
  readonly count: number;
  readonly value: string;

  constructor(data: any) {
    this.count = data.doc_count;
    this.value = data.key;
  }
}
