// MUI / Formik
import { Formik, Form } from "formik";

// Local
import AmpRadioGroup from "../buttons/AmpRadioGroup";
import TextFieldInput from "../addEvidence/TextFieldInput";
import { GenerateReportPromptOptions } from "../../lib/util/enums";

interface Props {
  selected?: string;
  setSelected: (selected: string) => void;
}

// Multiple choice options for Generate Report dialog
const GenerateReportPrompt = ({ selected, setSelected }: Props) => {
  const values = Object.values(GenerateReportPromptOptions);

  const calcSelected = () => {
    if (selected === undefined) {
      return undefined;
    } else if (values.toString().includes(selected)) {
      return selected;
    } else {
      return "userDefined";
    }
  };

  const handleClick = (values: Record<string, string>) => {
    setSelected(values["promptOptions"]);
  };

  return (
    <>
      <AmpRadioGroup
        label="promptOptions"
        values={values}
        isNotRows
        updateFormData={handleClick}
        selectedValue={calcSelected()}
      />
      {calcSelected() === GenerateReportPromptOptions.UserDefined && (
        <Formik
          initialValues={{
            userPrompt: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSelected(values.userPrompt || "");
            setSubmitting(false);
          }}
        >
          <Form>
            <TextFieldInput field="userPrompt" noTitle />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default GenerateReportPrompt;
