// Global
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, BarElement, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(BarElement, Tooltip, ChartDataLabels);

// MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

// Local
import { ValueCountPair } from "../../models/Evidence";
import { COLORS } from "../../lib/theme/default";
import { truncThemeLabelLength } from "../../lib/configs";

interface Props {
  barChartData?: ValueCountPair[];
}

export const options = {
  indexAxis: "y" as const,

  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  grid: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 40,
    },
  },
  plugins: {
    datalabels: {
      color: COLORS.secondaryGray,
      font: {
        family: "Manrope",
        weight: "bold" as "bold",
        size: 14,
      },
      display: true,
      align: "end" as "end",
      anchor: "end" as "end",
      formatter: (value: number) => value.toString(),
    },
    legend: {
      display: false,
    },
    title: { display: false },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      afterFit: function (scaleInstance: any) {
        scaleInstance.width = scaleInstance.maxWidth + 40; // Adjust the value as needed
      },
      grid: {
        display: false,
      },
      ticks: {
        color: COLORS.nearBlack,
        font: {
          family: "Manrope",
          weight: "bold" as "bold",
          size: 14,
        },
      },
    },
  },
};

const BarChartViz = (props: Props) => {
  if (!props.barChartData || props.barChartData.length < 1) {
    return null;
  }

  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const data = {
    // Limit to 23 characters
    labels: props.barChartData.filter((d) => d.value !== undefined).map((d) =>
      d.value.slice(0, isSmallScreen ? truncThemeLabelLength : undefined)
    ),
    datasets: [
      {
        data: props.barChartData.map((d) => d.count),
        backgroundColor: COLORS.primaryBlue,
      },
    ],
  };

  return (
    <Box width={1}>
      <Box display="flex" justifyContent="center" my={1} width={1}>
        <Typography variant="h3">{t("charts.bar.title")}</Typography>
      </Box>

      <Box width={1} height={250}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
};

export default BarChartViz;
