// Global
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // TimeSeriesScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// Local
import { EvidenceAggregatedByDay } from "../../models/Evidence";
import { COLORS } from "../../lib/theme/default";
import { constructFullDateRange, getUtcDateLocalized } from "../../lib/util/dateFormatters";
import { lineChartHeight } from "../../lib/theme/default";
import { DateRangeProps } from "../../lib/util/interfaces";
import { Box, Typography } from "@mui/material";

interface Props {
  lineChartData?: EvidenceAggregatedByDay[];
  dateRange?: DateRangeProps;
}

// Display linechart within an AmpCard
const LineChart = ({ lineChartData, dateRange }: Props) => {
  if (!lineChartData?.length || lineChartData.length < 1 || !dateRange) {
    return null;
  }
  // TODO: Calc total days and adjust x axis labeling as appropriate

  const { t, i18n } = useTranslation();
  // TODO: Replace default language with hook from user preferences
  const userLang = i18n.language;

  // Display options for line chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {},
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
    },
  };

  // If date range exceeds opensearch histogram range:
  // Extend charted days with count: 0
  const dataObj = constructFullDateRange({
    data: lineChartData,
    dateRange,
  });

  // Map the object data over the time range
  const data = {
    // Get localized date format
    labels: dataObj.map((day) =>
      getUtcDateLocalized({
        date: day.date,
        userLang: userLang,
        isShortDate: true,
      })
    ),
    datasets: [
      {
        label: t("charts.lineChart.label"),
        data: dataObj.map((day) => day.count),
        borderColor: COLORS.primaryBlue,
        backgroundColor: COLORS.primaryBlue,
      },
    ],
  };

  // Return the line module with viz options and data
  return (
    <Box width={1}>
            <Box display="flex" justifyContent="center" my={1} width={1}>
        <Typography variant="h3">{t("charts.lineChart.title")}</Typography>
      </Box>
      <Box>
      <Line options={options} data={data} height={lineChartHeight} />
      </Box>
    </Box>
  );
};
export default LineChart;
