import { PresignedUrlTypes } from "../util/enums";
import useAxios from "../../hooks/useAxios";

interface QueryProps {
  queryKey: [
    string,
    { token: string; key?: string; type?: PresignedUrlTypes; isBulkUpload?: boolean }
  ];
}

// Build createPresignedUrl API call
const createPresignedUrl = async ({ queryKey }: QueryProps) => {
  const [_key, { token, key, type, isBulkUpload = false }] = queryKey;
  if (!key) {
    throw new Error("Missing Presigned URL Key!");
  }
  const response = await useAxios({
    token: token,
    endpoint: "createPresignedUrl",
    params: { objectKey: key, attachmentType: type || PresignedUrlTypes.Evidence, isBulkUpload },
  });
  return response;
};

export default createPresignedUrl;
