// Global
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Paper, Typography } from "@mui/material";

// Local
import AmpDrawer from "../components/drawers/AmpDrawer";
import CircleLoading from "../components/navigation/CircleLoading";
import MainStage from "../components/navigation/MainStage";
import SubNav from "../components/navigation/SubNav";
import ExportReportDrawer from "../components/report/ExportReportDrawer";
import ReportMainStage from "../components/report/ReportMainStage";
import ReportViewControls from "../components/report/ReportViewControls";
import useAmpDrawer from "../hooks/useAmpDrawer";
import { AmpDrawerState, RouteOptions } from "../lib/util/enums";
import useGetReport from "../hooks/useGetReport";
import useEvidence from "../hooks/useEvidence";
import useEvidenceFilters from "../hooks/useEvidenceFilters";
import { Evidence } from "../models/Evidence";
import { ReportDataWithFullText } from "../lib/util/interfaces";
import ReportList from "../components/report/ReportList";
import useMutateGeneratedReport from "../hooks/useMutateGeneratedReport";
import { Link } from "react-router-dom";

const GeneratedReport = () => {
  // Global
  const { t } = useTranslation();
  const subNavRef = useRef<HTMLDivElement | null>(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  // Local
  const { drawerState, setDrawerState } = useAmpDrawer();

  // Get reportList from database
  const { reportList, report, isLoading, errorMessage } = useGetReport();

  // When report loads, set evidenceFilters for the Example Ids
  const [hasRun, setHasRun] = useState<boolean>(false);
  const { evidenceFilters, updateEvidenceFilters } = useEvidenceFilters({
    view: RouteOptions.report,
    isDateFilter: false,
  });
  useEffect(() => {
    if (report && !hasRun) {
      let Ids: string[] = report.data.dataGroups?.flatMap((group) => group.Ids) || [];
      updateEvidenceFilters({ ids: Ids }, false);
      setHasRun(true);
    }
  }, [report, hasRun]);

  // Get examples from evidence index
  const { evidenceList } = useEvidence({
    evidenceFilters,
  });

  const { updateReport, isMutating } = useMutateGeneratedReport({ report });

  // Consolidated data for Main Stage
  const exportData: ReportDataWithFullText = {
    reportData: report || undefined,
    evidenceList: evidenceList || ([] as Evidence[]),
  };

  const handleClickExport = () => {
    // navigate(RouteOptions.exportReport, { state: exportData });
    setDrawerState(AmpDrawerState.ExportReport);
  };

  const removeIdFromEvidenceFilters = (id: string) => {
    const newIds = evidenceFilters.ids?.filter((currentId) => currentId !== id);
    updateEvidenceFilters({ ids: newIds }, false);
  };

  useEffect(() => {
    if (subNavRef.current) {
      setSubNavHeight(subNavRef.current.offsetHeight);
    }
  }, [evidenceFilters]);

  console.log("report", report);

  // IsLoading Page
  return isLoading || isMutating || errorMessage ? (
    <MainStage subNavHeight={subNavHeight}>
      {(isLoading || isMutating) && <CircleLoading />}
      {errorMessage}
    </MainStage>
  ) : reportList ? (
    <Box>
      <SubNav view={RouteOptions.report} ref={subNavRef}>
        <Typography variant="body1">{t("generatedReport.SelectReport")}</Typography>
      </SubNav>
      <MainStage subNavHeight={subNavHeight}>
        {reportList.length === 0 ? (
          <Typography variant="h3" mt={2}>
            <Box>{t("generatedReport.NoReport1")}</Box>
            <Box mt={1}>
              <Link to={`/analyze`}>{t("generatedReport.NoReport2")}</Link>
            </Box>
          </Typography>
        ) : (
          <ReportList reportList={reportList} />
        )}
      </MainStage>
    </Box>
  ) : (
    <Box>
      {/* SubNav Container */}
      <SubNav view={RouteOptions.report} ref={subNavRef}>
        <ReportViewControls clickExport={handleClickExport} />
      </SubNav>

      {/* Generate Report Main Stage */}
      <MainStage subNavHeight={subNavHeight}>
        <Paper>
          <ReportMainStage
            exportData={exportData}
            updateReport={updateReport}
            archiveExample={removeIdFromEvidenceFilters}
          />
        </Paper>
      </MainStage>
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <ExportReportDrawer exportData={exportData} />
        </AmpDrawer>
      )}
    </Box>
  );
};

export default GeneratedReport;
