// MUI
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import { RouteOptions } from "../../lib/util/enums";
import SearchBar from "./SearchBar";
import FilterButton from "./FilterButton";
import AmpCalendar from "./Calendar";
import DateSort from "./DateSort";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import SortableFieldsControl from "./SortableFieldsControl";
import ActiveFiltersView from "./ActiveFiltersView";

interface Props extends EvidenceFiltersProps {
  view: RouteOptions;
}

// This component is a child of the subnav
// It holds filter controls for views with Evidence data
const EvidenceFilterControls = ({
  view,
  evidenceFilters,
  updateEvidenceFilters,
  clearEvidenceFilters,
}: Props) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isQA = view === RouteOptions.QA;

  return (
    <>
      {/* Mobile only: DateSort up top */}
      {isSmallScreen && (
        <Grid xs={5} container display="flex" justifyContent="flex-end">
          <DateSort
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={updateEvidenceFilters}
          />
        </Grid>
      )}

      {/* Mobile & Desktop: Search bar and filters */}
      <Grid xs={12} md={4} display="flex">
        <Stack
          direction="row"
          justifyContent={"stretch"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <SearchBar
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={updateEvidenceFilters}
          />
          <FilterButton
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={updateEvidenceFilters}
            clearEvidenceFilters={clearEvidenceFilters}
            isQA={isQA}
          />
        </Stack>
      </Grid>

      {/* Desktop only: Calendar and date sort */}
      {!isSmallScreen && (
        <Grid xs={0} md={isQA ? 5 : 4} container display="flex" justifyContent={"flex-end"}>
          <Stack direction="row" alignItems={"center"}>
            <AmpCalendar
              evidenceFilters={evidenceFilters}
              updateEvidenceFilters={updateEvidenceFilters}
            />
            {view === RouteOptions.QA && (
              <SortableFieldsControl
                evidenceFilters={evidenceFilters}
                updateEvidenceFilters={updateEvidenceFilters}
              />
            )}

            <DateSort
              evidenceFilters={evidenceFilters}
              updateEvidenceFilters={updateEvidenceFilters}
            />
          </Stack>
        </Grid>
      )}
      <ActiveFiltersView
        evidenceFilters={evidenceFilters}
        updateEvidenceFilters={updateEvidenceFilters}
        clearEvidenceFilters={clearEvidenceFilters}
        isQA={isQA}
      />
    </>
  );
};

export default EvidenceFilterControls;
