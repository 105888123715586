// Global
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Stack, Typography, CardActionArea, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Local
import { labeledObject } from "../../lib/util/interfaces";
import EvidenceMap from "../visualizations/EvidenceMap";
import useEvidenceFilters from "../../hooks/useEvidenceFilters";
import useEvidence from "../../hooks/useEvidence";
import { RouteOptions } from "../../lib/util/enums";
import CircleLoading from "../navigation/CircleLoading";
import { COLORS } from "../../lib/theme/default";
import { EvidenceFilters } from "../../models/EvidenceFilters";
import { appendUrlParamsFromFilters } from "../../lib/util/formatters";

interface Props {
  data: labeledObject;
  baseFilters: EvidenceFilters;
  isIssuesAggregation?: boolean;
  trendingPeriod: number;
}

// Display a Theme Card
const ThemeOrIssuesCard = ({ data, baseFilters, isIssuesAggregation, trendingPeriod }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get clickthrough and urlParam data
  const idTerm = isIssuesAggregation ? "issueIds" : "themeIds";
  const idString: string = data.id;
  const urlParams = appendUrlParamsFromFilters({
    baseFilters,
    idTerm,
    idString,
    trendingPeriod,
  });

  const { evidenceFilters, updateEvidenceFilters } = useEvidenceFilters({
    view: RouteOptions.analyze,
    urlParams,
  });

  // Get evidence aggregations
  const { chartData, isLoading } = useEvidence({
    evidenceFilters,
    isMapsOnly: true,
    isStaleOk: true,
  });

  // Drilldown: Click to navigate into Analyze pre-filtered for Theme
  const handleClick = () => {
    navigate({
      pathname: RouteOptions.analyze,
      search: urlParams.toString(),
    });
  };

  const description = data.description;

  return (
    <Grid container spacing={1}>
      {/* Mini-map */}
      <Grid xs={12} md={5}>
        {isLoading && <CircleLoading />}
        {!!chartData && <EvidenceMap data={chartData.mapData} isMinimap />}
      </Grid>

      <Grid xs={12} md={7}>
        <CardActionArea onClick={handleClick}>
          {/* Text area */}
          <Tooltip title={t("cards.tooltip.Drilldown")}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="subtitle1"
                color={isIssuesAggregation ? "auto" : COLORS.airaPink}
              >
                {data.label}{" "}
              </Typography>
              <Typography variant="body1">{description} </Typography>
            </Stack>
          </Tooltip>
        </CardActionArea>
      </Grid>
    </Grid>
  );
};

export default ThemeOrIssuesCard;
