export class Country {
  readonly id: string;
  readonly label: string;
  readonly flag: string;
  readonly latitude: number;
  readonly longitude: number;

  constructor(id: string, name: string, flag: string, latitude: number, longitude: number) {
    this.id = id.toUpperCase();
    this.label = name;
    this.flag = flag;
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class State {
  readonly id: string;
  readonly label: string;
  readonly countryCode: string;
  readonly isoCode: string;
  readonly latitude: number;
  readonly longitude: number;

  constructor(
    id: string,
    name: string,
    countryCode: string,
    isoCode: string,
    latitude: number,
    longitude: number
  ) {
    this.id = id;
    this.label = name;
    this.countryCode = countryCode.toUpperCase();
    this.isoCode = isoCode.toUpperCase();
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class City {
  readonly id: string;
  readonly label: string;
  readonly countryCode: string;
  readonly stateCode: string;
  readonly latitude: number;
  readonly longitude: number;

  constructor(
    id: string,
    name: string,
    countryCode: string,
    stateCode: string,
    latitude: number,
    longitude: number
  ) {
    this.id = id;
    this.label = name;
    this.countryCode = countryCode.toUpperCase();
    this.stateCode = stateCode.toUpperCase();
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
