// Global
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// MUI
import { Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsightsIcon from "@mui/icons-material/Insights";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import HelpIcon from "@mui/icons-material/Help";

// Local
import { COLORS, appMaxWidth, appMobileWidth } from "../../lib/theme/default";
import AmpLogo from "../landingPage/AmpLogo";
import UserMenu from "../user/UserMenu";
import AmpButton from "../buttons/AmpButton";
import { AmpDrawerState, RouteOptions } from "../../lib/util/enums";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import AmpDrawer from "../drawers/AmpDrawer";
import HelpDrawer from "../drawers/help/HelpDrawer";

// Navbar component
const Navbar = () => {
  // Global
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const navigate = useNavigate();

  // Local
  const { drawerState, setDrawerState } = useAmpDrawer();

  // Individual text nav options
  const navItems: RouteOptions[] = [
    RouteOptions.collection,
    RouteOptions.analyze,
    RouteOptions.report,
  ];

  const getIcon = (item: RouteOptions) => {
    switch (item) {
      case RouteOptions.collection:
        return <VisibilityIcon />;
      case RouteOptions.analyze:
        return <TroubleshootIcon />;
      case RouteOptions.report:
        return <InsightsIcon />;
      default:
        return null;
    }
  };

  // Logout of app
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box maxWidth={appMaxWidth} width={appMobileWidth}>
        <Grid container>
          <Grid
            xs={8}
            md={2.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            {/* AMP Logo */}
            <Link to={`/`}>
              <AmpLogo />
            </Link>
          </Grid>
          <Grid xs={0} md={6.5} display="flex" alignItems="center" justifyContent={"flex-end"}>
            {/* Map the text navigation items (desktop) */}
            {!isMediumScreen &&
              navItems.map((item, index) => (
                <Stack direction="row" key={index} display="flex-inline" alignItems={"center"}>
                  <Link to={item}>
                    <Typography
                      variant="h4"
                      sx={{
                        color: item === location.pathname ? COLORS.primaryBlue : "auto",
                        display: "flex",
                        alignItems: "center",
                        "&:hover":
                          item === location.pathname
                            ? null
                            : {
                                color: COLORS.hoverBlue,
                                ".icon, .text": {
                                  color: COLORS.hoverBlue,
                                },
                              },
                      }}
                      display="flex"
                      alignItems={"center"}
                    >
                      <Box mr={1} display="flex" alignItems={"center"}>
                        {getIcon(item)}
                      </Box>
                      <Box display="flex" alignItems={"center"}>
                        {t("viewName." + item)}
                      </Box>
                    </Typography>
                  </Link>
                  {index < navItems.length - 1 ? (
                    <Typography
                      ml={2}
                      mr={2}
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 400,
                        color: COLORS.secondaryGray,
                        opacity: "40%",
                      }}
                    >
                      |
                    </Typography>
                  ) : null}
                </Stack>
              ))}
          </Grid>

          <Grid xs={0} md={3} display="flex" alignItems="center" justifyContent={"flex-end"}>
            {/* Add Signals and User Menu (desktop) */}
            {!isMediumScreen && (
              <Stack
                direction="row"
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <AmpButton
                  text="AddSignals"
                  symbol="plus"
                  onClick={() => navigate(RouteOptions.submit)}
                />

                <Tooltip title={t("navbar.tooltip.Help")}>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    onClick={() => setDrawerState(AmpDrawerState.Help)}
                  >
                    <HelpIcon fontSize="large" color="primary" />
                  </Box>
                </Tooltip>

                <UserMenu setDrawerState={setDrawerState} handleLogout={handleLogout} />
              </Stack>
            )}
          </Grid>
          <Grid xs={4} md={0} display="flex" alignItems="center" justifyContent={"flex-end"}>
            {/* Hamburger menu (mobile only) */}
            {isMediumScreen && (
              <UserMenu
                isMobile={true}
                setDrawerState={setDrawerState}
                handleLogout={handleLogout}
              />
            )}{" "}
          </Grid>
        </Grid>
      </Box>

      <AmpDrawer
        drawerState={drawerState}
        handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
      >
        <HelpDrawer />
      </AmpDrawer>
    </Box>
  );
};

export default Navbar;
