// Global
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useContext } from "react";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import { COLORS, smallFontSize } from "../../lib/theme/default";
import DotSpacer from "./DotSpacer";
import useEditEvidence from "../../hooks/useEditEvidence";
import EditButton from "../buttons/EditButton";
import { EvidenceLocation } from "../../models/Evidence";
import {
  flattenLocations,
  formatLocationsForDisplay,
} from "../../lib/util/formatters";
import { CountriesContext } from "../../contexts/InitContexts";

interface Props {
  locations?: EvidenceLocation[];
  isQA?: boolean;
  evidenceId: string;
}

// Return an alphabatized list of locations, organized by Country
const LocationsList = ({ locations, isQA, evidenceId }: Props) => {
  // Global
  const { t } = useTranslation();
  
  // Local
  const countries = useContext(CountriesContext);

  if (!locations && !isQA) {
    return null;
  }

  // Local hooks
  const { values, isEditting, handleEdit, editView, isLoading } =
    useEditEvidence({
      type: "locations",
      ids: flattenLocations(locations),
      evidenceId,
    });

  // Fetch and alphabetize country name, flag, and sub-national locations
  const countryList = values ? formatLocationsForDisplay(values, countries) : [];

  // Display isLoading or isEditting as needed
  return isLoading ? (
    isLoading
  ) : isEditting ? (
    editView
  ) : (
    // Default: Display source country component
    <Box mt={1}>
      <Typography variant="h4" display="inline" sx={{ fontSize: smallFontSize }}>
        {t("cards.EvidenceLocation")}:
      </Typography>{" "}
      {!!values &&
        countryList.map((country, index) => (
          <Box display="inline" key={index} mx={0.5}>
            {/* Insert country flag, or if none, DotSpacer */}
            {country.flag ? (
              <Box className={country.flag} sx={{ fontSize: smallFontSize }} />
            ) : (
              <DotSpacer />
            )}

            {/* Country name */}
            <Typography variant="body1" display="inline" ml={0.5}>
              {country.name}
            </Typography>

            {/* City names */}
            {country?.locations && country.locations.length > 0 && (
              <Typography
                variant="body1"
                display="inline"
                color={COLORS.secondaryGray}
                pl={0.5}
              >
                ({country.locations?.join(", ").toString()})
              </Typography>
            )}
          </Box>
        ))}
      {isQA && <EditButton handleEdit={handleEdit} type="Location" />}
    </Box>
  );
};

export default LocationsList;
