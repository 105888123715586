import { useTranslation } from "react-i18next";

// MUI Imports
import { Card, Stack, Typography, Box, CardActionArea } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import NotesIcon from "@mui/icons-material/Notes";
import AssignmentIcon from "@mui/icons-material/Assignment";

// Local imports
import { Platform, PlatformTypes, Platforms, QuickSubmitTypes } from "../../models/Platform";
import ChipInput from "./ChipInput";
import { SocialMediaPostType } from "../../lib/util/enums";
import { COLORS } from "../../lib/theme/default";

interface Props {
  platform: Platform;
  isOutlined?: boolean;
  onClick: (platform: Platform) => void;
  handleSocialMediaClick?: (values: any) => void;
}

// Build the complete platform card from array item provided
export default function PlatformCard({
  platform,
  isOutlined,
  onClick,
  handleSocialMediaClick,
}: Props) {
  // Global
  const { t } = useTranslation();

  // If user clicks on social media, combine with platform and send to parent
  const handleSocialMediaType = (values: Record<string, string> | Record<string, string[]>) => {
    const data = {
      ...values,
      platformObject: platform,
    };
    if (handleSocialMediaClick) {
      handleSocialMediaClick(data);
    }
  };

  // Function to get the correct icon based on the platform type
  const getIcon = () => {
    switch (platform) {
      case Platforms.Survey:
        return <AssignmentIcon fontSize="large" color="primary" />;
      case QuickSubmitTypes.Attachment:
        return <AttachFileIcon fontSize="large" color="primary" />;
      case QuickSubmitTypes.URL:
        return <InsertLinkIcon fontSize="large" color="primary" />;
      case QuickSubmitTypes.Text:
        return <NotesIcon fontSize="large" color="primary" />;
      // case QuickSubmitTypes.Date:
      //   return <DateRangeIcon fontSize="large" color="primary" />;
      default:
        return <QuestionMarkIcon fontSize="large" color="primary" />;
    }
  };

  const cardContent = (
    <Stack justifyContent="center" alignItems={"center"} py={2}>
      {/* Insert the icon */}
      <Box height={40} width={40} display="flex" alignItems={"center"} justifyContent={"center"}>
        {platform.type === PlatformTypes.UserDefined ||
        platform.type === PlatformTypes.QuickSubmit ||
        platform === Platforms.Survey ? (
          getIcon()
        ) : (
          <img
            src={"/icons/" + platform.icon}
            alt={platform.name}
            style={{ maxWidth: "40px", maxHeight: "40px" }}
          />
        )}
      </Box>
      {/* Display the text below the icon */}
      <Typography variant="h3" textAlign={"center"} mt={1}>
        {t("platforms." + platform.name)}
      </Typography>
      {platform.type === PlatformTypes.SocialMedia && (
        <Box mt={2}>
          <ChipInput
            label="socialMediaPostType"
            values={
              platform === Platforms.Twitter
                ? [SocialMediaPostType.Tweet, SocialMediaPostType.Reply]
                : platform === Platforms.YouTube || platform === Platforms.TikTok
                ? [SocialMediaPostType.Video, SocialMediaPostType.Comment]
                : [SocialMediaPostType.Post, SocialMediaPostType.Comment]
            }
            exclusive={true}
            updateFormData={handleSocialMediaType}
          />
        </Box>
      )}
    </Stack>
  );

  return (
    <Card
      onClick={() => onClick(platform)}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",

        // backgroundColor: COLORS.primaryBlue
      }}
      variant={isOutlined ? "outlined" : undefined}
      sx={isOutlined ? { borderColor: COLORS.primaryBlue, borderWidth: 2 } : {}}
    >
      {/* Big click target for most cards */}
      {platform.type !== PlatformTypes.SocialMedia && (
        <CardActionArea>{cardContent}</CardActionArea>
      )}

      {/* Small click target for Social Media */}
      {platform.type === PlatformTypes.SocialMedia && <Box>{cardContent}</Box>}
    </Card>
  );
}
