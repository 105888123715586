// Global
import { useTranslation } from "react-i18next";

// Import formik and formik-mui
import { Box, useMediaQuery, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import SearchIcon from '@mui/icons-material/Search';

// Local
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import { COLORS } from "../../lib/theme/default";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import { AmpDrawerState } from "../../lib/util/enums";
import AmpDrawer from "../drawers/AmpDrawer";
import SearchHelpDrawer from "../drawers/SearchHelpDrawer";

const SearchBar = ({ evidenceFilters, updateEvidenceFilters }: EvidenceFiltersProps) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const { drawerState, setDrawerState } = useAmpDrawer();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={{
          searchBar: evidenceFilters?.searchText || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateEvidenceFilters({ searchText: values.searchBar });
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mr={1} py={1}>
              <Field
                component={TextField}
                name={"searchBar"}
                type={"searchBar"}
                placeholder={t("subNav.SearchBarPlaceholder")}
                multiline={true}
                maxRows={3}
                size={"small"}
                sx={{
                  width: "100%",
                  backgroundColor: isSmallScreen ? COLORS.white : COLORS.backgroundGray,
                }}
                onBlur={(e: any) => {
                  // Update the form on blur
                  submitForm();
                }}
                onKeyPress={(event: any) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="isNotButton" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box display="flex" onClick={() => setDrawerState(AmpDrawerState.SearchHelp)}>
                        <HelpOutlineIcon />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {/* Toggle components */}
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <SearchHelpDrawer />
        </AmpDrawer>
      )}
    </Box>
  );
};

export default SearchBar;
