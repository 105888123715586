// Global
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Locals
import { EvidenceFilters } from "../models/EvidenceFilters";
import { RouteOptions } from "../lib/util/enums";
import { setEmptyArraysToUndefined } from "../lib/util/dataCleaning";
import { ClearedEvidenceFilters } from "../models/EvidenceFilters";
import { getNDaysAgo } from "../lib/util/dateFormatters";

export interface EvidenceProps {
  view: RouteOptions;
  urlParams?: URLSearchParams;
  isDateFilter?: boolean;
  countries?: string[];
}

// This hook manages the state for filters and search terms
// View parameter tells DB if this is a Collection or Analyze query
const useEvidenceFilters = ({ view, urlParams, isDateFilter = true }: EvidenceProps) => {
  const { user } = useAuth0();

  // Set initial form data with user preferred country
  const defaultCountry =
    user && user["https://amp.rootwise.co/country"]
      ? [user["https://amp.rootwise.co/country"]]
      : undefined;

  const [evidenceFilters, setEvidenceFilters] = useState<EvidenceFilters>(
    new EvidenceFilters({ view, urlParams, isDateFilter, countries: defaultCountry })
  );

  // Keep function in sync with current view and trendingPeriod
  if (
    view !== evidenceFilters.view ||
    (urlParams?.get("days") &&
      !getNDaysAgo(Number(urlParams?.get("days"))).isSame(evidenceFilters.dateRange?.gte))
  ) {
    setEvidenceFilters(new EvidenceFilters({ view, urlParams, isDateFilter }));
  }

  // This function cleans and updates filters
  const updateEvidenceFilters = (values: object, shouldClearWindow: boolean = true) => {
    // Clear any URL params
    if (shouldClearWindow) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    setEmptyArraysToUndefined(values);
    setEvidenceFilters({ ...evidenceFilters, ...values });
    console.log("evidenceFilters: ", { ...evidenceFilters, ...values });
  };

  // Clear all optional filters in filters panel only
  // i.e., does not clear date or search text
  const clearEvidenceFilters = () => {
    updateEvidenceFilters(new ClearedEvidenceFilters());
  };

  return { evidenceFilters, clearEvidenceFilters, updateEvidenceFilters };
};

export default useEvidenceFilters;
