// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import { COLORS } from "../../lib/theme/default";

interface Props {
  isDropdown?: boolean;
}

const LShapedSpacer = ({ isDropdown }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width={isSmallScreen ? "10vw" : "3vw"}>
      <Box
        sx={{
          width: "35%",
          height: "2px",
          backgroundColor: COLORS.secondaryGray,
          position: "relative",
          top: isDropdown ? "65%" : isSmallScreen ? "45%" : "50%",
          left: "50%",
          transform: "translateY(-50%)",
        }}
      />
      <Box
        sx={{
          width: "2px",
          height: isDropdown ? "65%" : isSmallScreen ? "42%" : "45%",
          backgroundColor: COLORS.secondaryGray,
          position: "relative",
          top: isDropdown ? "64%" : isSmallScreen ? "44%" : "49%",
          left: "50%",
          transform: "translate(-50%, -100%)",
        }}
      />
    </Box>
  );
};

export default LShapedSpacer;
