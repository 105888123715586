// Local
import ReportMainStage from "./ReportMainStage";
import { ReportDataWithFullText } from "../../lib/util/interfaces";

interface Props {
  exportData: ReportDataWithFullText;
}

const ExportReportDrawer = ({ exportData }: Props) => {

  return (
    <>
      <ReportMainStage exportData={exportData} isForExport />
    </>
  );
};

export default ExportReportDrawer;
