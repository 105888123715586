// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography } from "@mui/material";
import CodeTypography from "../../lib/theme/customTypography";

interface Props {
  isAccordion?: boolean;
}

// Display search syntax for users
const SearchHelpDrawer = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Box m={2}>
      {!props.isAccordion && (
        <Typography variant="h2" my={2}>
          {t("drawers.searchHelp.Guide")}
        </Typography>
      )}
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.CaseSensitive")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.Conjuctions")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.Wildcard")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.Phrase")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.Fuzzy")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("drawers.searchHelp.DefaultLogic")}
      </Typography>
      <Typography variant="h4" mt={2}>
        {t("drawers.searchHelp.Examples")}
      </Typography>
      <CodeTypography>pizza OR pasta</CodeTypography>
      <CodeTypography>pizza AND sauce</CodeTypography>
      <CodeTypography>pizza AND slic*</CodeTypography>
      <CodeTypography>(pizza AND sauce) AND NOT pasta</CodeTypography>
      <CodeTypography>(pizza OR pasta) AND sauce</CodeTypography>
      <CodeTypography>(pizza OR pasta) AND (sauce OR slice)</CodeTypography>
      <CodeTypography>"New York" AND pizza</CodeTypography>
      <CodeTypography>"New York pizza"~5</CodeTypography>
      <CodeTypography>pizaz~1</CodeTypography>
      <CodeTypography>
        ((pizaz~1 AND "New York") AND (style crust*)) AND NOT "Chicago style"~3
      </CodeTypography>
    </Box>
  );
};

export default SearchHelpDrawer;
