// Global
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

// Local
import { UseAggregateThemesProps, idCountPair, labeledObject } from "../lib/util/interfaces";
import useAxios from "./useAxios";
import { getAggregateThemesQuery } from "../lib/api/keyConstants";
import { ApiTokenContext, ThemesContext, IssuesContext } from "../contexts/InitContexts";
import { AxiosResponse } from "axios";
import { shortStaleTime } from "../lib/configs";
import { idLookups } from "../lib/util/idLookups";
import { EvidenceFilters } from "../models/EvidenceFilters";

interface QueryProps {
  queryKey: [
    string,
    { token: string; evidenceFilters?: EvidenceFilters; isIssuesAggregation?: boolean }
  ];
}

// Business logic for fetching themes
const getAggregateThemes = async ({ queryKey }: QueryProps) => {
  const [_key, { token, evidenceFilters, isIssuesAggregation }] = queryKey;

  // If days param, limit the aggregation to number of days
  let params: {
    isIssuesAggregation?: boolean;
    startDate?: Date;
    countries?: string;
    issueIds?: string;
  } = {
    isIssuesAggregation,
  };

  if (evidenceFilters) {
    
    params = {
      ...params,
      startDate: evidenceFilters.dateRange?.gte.toDate(),
      countries: JSON.stringify(evidenceFilters.countries),
      issueIds: JSON.stringify(evidenceFilters.issueIds),
    };
  }

  return await useAxios({
    endpoint: "aggregateThemes",
    token: token,
    params: params,
  });
};

interface ParseProps {
  data: AxiosResponse;
  contextData: labeledObject[];
}

interface labeledObjectWithCount extends labeledObject {
  count?: number;
}

// Post-succesful fetch: data processing
// Convert hits to labeledObject[] and return it
const parseResults = ({ data, contextData }: ParseProps) => {
  const hits = data?.data.response.occurrences.buckets;

  const themeList: idCountPair[] = hits.map((hit: any) => ({ id: hit.key, count: hit.doc_count }));
  const themeListIds: string[] | undefined = themeList?.map(({ id }) => id);

  const trendingThemes: labeledObject[] | null = themeListIds?.length
    ? idLookups({
        ids: themeListIds,
        data: contextData,
        isAlphabetized: true,
      })
    : null;

  // Add count from themeList and group from contextData to each trendingTheme
  const enhancedTrendingThemes: labeledObjectWithCount[] | undefined = trendingThemes?.map(
    (theme: labeledObject) => {
      const matchingTheme = themeList.find(({ id }) => id === theme.id);
      return { ...theme, count: matchingTheme?.count };
    }
  );

  // Sort by count DESC
  enhancedTrendingThemes?.sort((a, b) => (b.count || 0) - (a.count || 0));
  return enhancedTrendingThemes;
};

// State management for aggregating theme frequency
const useAggregateThemes = ({ evidenceFilters, isIssuesAggregation }: UseAggregateThemesProps) => {
  const token = useContext(ApiTokenContext);
  const contextData = isIssuesAggregation ? useContext(IssuesContext) : useContext(ThemesContext);

  // Get Theme aggregations from DB
  const { isLoading, error, data } = useQuery({
    queryKey: [getAggregateThemesQuery, { token, evidenceFilters, isIssuesAggregation }],
    queryFn: getAggregateThemes,
    select: (data) => parseResults({ data, contextData }),
    staleTime: shortStaleTime,
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading) {
    return { isLoading: true };
  }

  if (error instanceof Error) {
    return { errorMessage: "NoContent" };
  }

  // if (isThemeGroupAggregation) {
  //   // Create an array corresponding to each unique "group" in enhancedTrendingThemes array
  //   const groupList: groupObj[] = [];

  //   data?.forEach((theme) => {
  //     const existingGroup = groupList.find((group) => group.id === theme.group);

  //     if (existingGroup) {
  //       existingGroup.count += theme.count || 0;
  //       existingGroup.childThemesList.push(theme.id);
  //     } else {
  //       groupList.push({
  //         id: theme.group || "",
  //         count: theme.count || 0,
  //         childThemesList: [theme.id],
  //       });
  //     }
  //   });
  //   groupList.sort((a, b) => b.count - a.count);

  //   const groupedThemes: labeledObject[] = groupList.map((group) => ({
  //     id: group.id,
  //     label: group.id,
  //     description: JSON.stringify(group.childThemesList),
  //   }));

  //   return { themeList: groupedThemes };
  // }

  return { themeList: data };
};

export default useAggregateThemes;
