// Global
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Fab, Tooltip } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import Zoom from "@mui/material/Zoom";

// Local
import AlertDialogSlide from "../AlertSlideDialog";
import { EvidenceDataProps } from "../../lib/util/interfaces";
import useAddReport from "../../hooks/useAddReport";
import useEvidence from "../../hooks/useEvidence";
import dayjs from "dayjs";

interface Props extends EvidenceDataProps {
  isLandingPage?: boolean;
  openDialogNow?: number;
  setFadeOut: (value: boolean) => void;
  setGenerateReportCounter?: (value: number) => void;
}

// Display a button to trigger the Generate Report capability
// Component also facilitates the report generation API call
const GenerateReportControl = ({
  data,
  isLandingPage,
  openDialogNow,
  setFadeOut,
  setGenerateReportCounter,
}: Props) => {
  // Global
  const { t } = useTranslation();

  // Local
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [generatingDialogOpen, setGeneratingDialogOpen] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>();

  // Get List of Evidence IDs (not full Evidence)
  const { reportIds } = useEvidence({
    evidenceFilters: data.evidenceFilters,
    isIdsOnly: true,
    isStaleOk: true,
  });

  const initialReportInputsRef = useRef<{ sampledIds: string[] } | undefined>();

  useEffect(() => {
    if (reportIds && !initialReportInputsRef.current) {
      initialReportInputsRef.current = reportIds;
    }
  }, [reportIds]);

  // Open dialog from parent
  useEffect(() => {
    if (openDialogNow) {
      setDialogOpen(true);
    }
  }, [openDialogNow]);

  /// THIS STUFF HERE HAS TO DO WITH CALLING OPENAI AND GENERATING A REPORT
  const { customError, cancelReport } = useAddReport({
    reportInputs: initialReportInputsRef.current,
    chartData: data.chartData,
    dateRange: data.evidenceFilters.dateRange
      ? {
          gte: dayjs(data.evidenceFilters.dateRange.gte),
          lte: dayjs(data.evidenceFilters.dateRange.lte),
        }
      : undefined,
    isReady: generatingDialogOpen,
    customPrompt: prompt,
    setFadeOut,
  });

  // Close dialog, may route to Report
  const handleUserChoice = (choice: string) => {
    if (choice === "confirm") {
      setDialogOpen(false);
      setGeneratingDialogOpen(true);
      if (setGenerateReportCounter) {
        setGenerateReportCounter(0);
      }
    } else {
      setDialogOpen(false);
      if (setGenerateReportCounter) {
        setGenerateReportCounter(0);
      }
    }
  };

  return (
    <Box>
      {/* Floating Action Button (Analyze page only) */}
      {!isLandingPage && (
        <Zoom in={true} timeout={3000}>
          <Tooltip
            title={t("alertSlideDialog.generateReport.Title")}
            sx={{ position: "fixed", bottom: 16, right: 16, maxWidth: "inherit" }}
          >
            <Fab color="primary" onClick={() => setDialogOpen(!dialogOpen)}>
              <InsightsIcon fontSize="large" />
            </Fab>
          </Tooltip>
        </Zoom>
      )}

      {/* Dialogue message */}
      <AlertDialogSlide
        title={"generateReport.Title"}
        message={"generateReport.ChoosePrompt"}
        confirm="GenerateReportDialog"
        isConfirmDisabled={!prompt || prompt === "userDefined"}
        cancel="Cancel"
        isMultipleChoice
        showCancel
        isOpen={dialogOpen}
        selected={prompt}
        handleUserChoice={handleUserChoice}
        setSelected={setPrompt}
        evidenceFilters={data.evidenceFilters}
      />

      {/* Loading Dialogue */}
      <AlertDialogSlide
        title={"generateReport.Generating"}
        message={customError?.message || "generateReport.GeneratingMessage"}
        message2={customError?.message ? undefined : "generateReport.GeneratingMessage2"}
        isConfirmHidden={true}
        cancel="Cancel"
        showCancel
        isOpen={generatingDialogOpen}
        isLoading={customError?.message ? false : true}
        handleUserChoice={() => {
          if (cancelReport) {
            cancelReport();
          }
          setGeneratingDialogOpen(false);
        }}
        setSelected={setPrompt}
        isDisableBackgroundClick
      />
    </Box>
  );
};

export default GenerateReportControl;
