// Route / View options for App
export enum RouteOptions {
  landing = "/",
  submit = "/submit",
  reviewSubmit = "/reviewSubmit",
  collection = "/collection",
  analyze = "/analyze",
  report = "/report",
  exportReport = "/export",
  QA = "/qa",
  content = "/content",
  unauthorized = "/unauthorized",
  admin = "/admin",
  bulkUpload = "/bulk",
}

// Data Types
export enum AmpDataSubmitTypes {
  Evidence = "evidence",
  Content = "content",
  Email = "email",
  GeneratedReport = "generatedReport",
  UserRegistration = "userRegistration",
}

// AmpCard Types
export enum AmpCardTypes {
  evidence = "evidence",
  theme = "theme",
  content = "content",
  chart = "chart",
  report = "report",
}

// Trend types
export enum AmpTrendTypes {
  Content = "content",
  Themes = "themes",
  Issues = "issues",
}

// Evidence Translation states
export enum TranslationState {
  native = "native",
  translated = "translated",
  untranslated = "untranslated",
}

// Evidence Status states
export enum EvidenceStatus {
  NeedsDetail = "needsDetail",
  NeedsReview = "needsReview",
  Active = "active",
  Reviewed = "reviewed",
  // GoldStandard = "goldStandard",
  Duplicate = "duplicate",
  Stashed = "stashed",
  Archived = "archived",
}

// AmpDrawer states
export enum AmpDrawerState {
  Closed = "closed",
  Help = "help",
  Filters = "filters",
  Flag = "flag",
  Responses = "responses",
  EvidenceDetails = "evidenceDetails",
  SearchHelp = "searchHelp",
  ExportReport = "exportReport",
  LandingPage = "landingPage",
}

// AMP Badges
export enum AmpBadges {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
}

// Data Intensities
export enum DataIntensity {
  "High" = "High",
  "Low" = "Low",
}

// Sub-view options
export enum contentSubviews {
  AddContent = "AddContent",
  ViewContent = "ViewContent",
}

// Social Media Post Types
export enum SocialMediaPostType {
  Post = "Post",
  Video = "Video",
  Comment = "Comment",
  Tweet = "Tweet",
  Reply = "Reply",
}

// Presigned URL Upload types
export enum PresignedUrlTypes {
  Evidence = "Evidence",
  Content = "Content",
}

// SortField options
export enum SortableFields {
  Date = "date",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

// Sentiment options
export enum SentimentValues {
  Positive = "positive",
  Neutral = "neutral",
  Negative = "negative",
  Undefined = "undefined",
}

export enum GenerateReportPromptOptions {
  Describe = "describe",
  // Explain = "explain",
  // Classify = "classify",
  UserDefined = "userDefined",
}

// Body font size
export enum BodyFontSize {
  Small = "small",
  Default = "default",
  Large = "large",
  Auto = "auto",
}

// Location Query types
export enum LocationQueryTypes {
  Countries = "countries",
  States = "states",
  Cities = "cities",
}
