// Global
import { useState } from "react";

// MUI * Formik
import { Stack } from "@mui/material";
import { Formik, Form } from "formik";

// Local
import TextFieldInput from "../components/addEvidence/TextFieldInput";
import CancelSubmitButtonPair from "../components/buttons/CancelSubmitButtonPair";

interface Props {
  text: string;
  handleUpdateText: (updatedText: string) => void;
}

// Make report elements interactive and editable
const useEditReport = ({ text, handleUpdateText }: Props) => {
  const [currentText, setCurrentText] = useState<string>(text);
  const [cancelText, setCancelText] = useState<string>();
  const [isEditting, setIsEditting] = useState<boolean>(false);

  const handleEditor = () => {
    setCancelText(currentText);
    setIsEditting(true);
  };

  // If changes to text, send to opensearch index
  const handleSubmit = () => {
    if (currentText !== text) {
      handleUpdateText(currentText);
    }
    setIsEditting(false);
  };
  const handleCancel = () => {
    setCurrentText(cancelText || "");
    setIsEditting(false);
  };

  const editBar = (
    <Stack direction={"column"} display="flex" justifyContent={"space-between"} spacing={1}>
      <Formik
        initialValues={{
          // Show blank initial value if the value is translateKey
          editableText: currentText,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setCurrentText(values.editableText);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <TextFieldInput field={"editableText"} noTitle />
          </Form>
        )}
      </Formik>
      <CancelSubmitButtonPair submit={handleSubmit} cancel={handleCancel} />
    </Stack>
  );
  if (isEditting) {
    return {
      editBar,
    };
  } else {
    return {
      // text,
      handleEditor,
    };
  }
};

export default useEditReport;
