// Global 
import { useContext } from "react";

// MUI
import { Box, Stack } from "@mui/material";

// Local
import AutocompleteMultiple from "../../addEvidence/AutocompleteMultiple";
import CancelSubmitButtonPair from "../../buttons/CancelSubmitButtonPair";
import { Evidence } from "../../../models/Evidence";
import LShapedSpacer from "../../addEvidence/LShapedSpacer";
import {
  formatLocationsForDropdown,
  getCountriesFromLocations,
} from "../../../lib/util/formatters";
import { CountriesContext } from "../../../contexts/InitContexts";

interface Props {
  updateFormData: (values: Record<string, string[]>) => void;
  handleEdit: () => void;
  submitEdits: (type: keyof Evidence, ids: string[]) => void;
  ids?: string[];
  type?: keyof Evidence;
}

// This function displays an user-editable UI for editing
// a specific property on an Evidence document
const EditEvidenceProperty = ({
  updateFormData,
  handleEdit,
  submitEdits,
  ids,
  type,
}: Props) => {
  const countries = useContext(CountriesContext);

  // Re-insert full location data on edits
  const handleUpdate = (values: Record<string, string[]>) => {
    updateFormData(values);
  };

  // Submit edits, if inputs exist
  const handleClick = () => {
    if (type && ids) {
      submitEdits(type, ids);
    } else {
      console.log("Missing inputs! Not submitting");
    }
  };

  return (
    <Stack
      direction={"column"}
      display="flex"
      justifyContent={"space-between"}
      spacing={1}
    >
      {type && (
        <AutocompleteMultiple
          field={type}
          isFilter
          selectedValues={
            ids && type === "locations" ? getCountriesFromLocations(ids, countries) : ids
          }
          locations={ids}
          updateFormData={handleUpdate}
        />
      )}

      {/* Display Cities for Locations */}
      {type === "locations" &&
        ids &&
        getCountriesFromLocations(ids, countries).map((country) => (
          <Stack direction="row" width={"100%"} key={country}>
            <LShapedSpacer isDropdown />
            <Box flexGrow={1}>
              <AutocompleteMultiple
                field="cities"
                country={country}
                selectedValues={formatLocationsForDropdown(ids, country)}
                locations={ids}
                updateFormData={handleUpdate}
              />
            </Box>
          </Stack>
        ))}
      <CancelSubmitButtonPair submit={handleClick} cancel={handleEdit} />
    </Stack>
  );
};

export default EditEvidenceProperty;
