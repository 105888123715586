// Global
import { useTranslation } from "react-i18next";

// MUI
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Tooltip } from "@mui/material";

const AttachmentMissing = () => {
  const { t } = useTranslation();


  return (
    <Box display="flex" justifyContent={"center"}>
      <Tooltip title={t("cards.tooltip.AttachmentMissing")}>
        <ErrorIcon color="error" sx={{ fontSize: "3.5rem" }} />
      </Tooltip>
    </Box>
  );
};

export default AttachmentMissing;
