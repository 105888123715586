// Globals
import { useContext } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Locals
import useAxios from "./useAxios";
import { ApiTokenContext } from "../contexts/InitContexts";
import { doDataAnalysisQuery } from "../lib/api/keyConstants";
import { getStage } from "../lib/util/formatters";
import useCustomError from "./useCustomError";
import { RouteOptions } from "../lib/util/enums";
import { ChartDataProps, DateRangeProps } from "../lib/util/interfaces";

interface QueryProps {
  queryKey: [
    string,
    {
      token: string;
      username?: string;
      sampledIds: string[];
      chartData: ChartDataProps;
      dateRange?: DateRangeProps;
      customPrompt?: string;
    }
  ];
}

const getDataAnalysis = async ({ queryKey }: QueryProps) => {
  const [_key, { token, username, sampledIds, chartData, dateRange, customPrompt }] = queryKey;

  // Use evidence attributes as params for /getContent endpoint
  let data = {
    sampledIds,
    chartData,
    dateRange,
    username,
    customPrompt,
  };

  // Set AWS Function URL for specific stage
  const stage = getStage();
  const url =
    stage === "prod"
      ? import.meta.env.REACT_APP_DO_DATA_ANALYSIS_URL_PROD
      : stage === "staging"
      ? import.meta.env.REACT_APP_DO_DATA_ANALYSIS_URL_STAGING
      : import.meta.env.REACT_APP_DO_DATA_ANALYSIS_URL_DEV;

  console.log("# of evidence in report:", sampledIds.length);

  const response = await useAxios({
    external: true,
    endpoint: url,
    method: "POST",
    token: token,
    data: data,
  });

  return response;
};

interface Props {
  isReady: boolean;
  reportInputs?: { sampledIds: string[] };
  chartData: ChartDataProps;
  dateRange: DateRangeProps | undefined;
  customPrompt?: string;
  setFadeOut: (value: boolean) => void;
}

// This hook calls the AMP API which calls Open AI GPT to generate data analysis
const useAddReport = (props: Props) => {
  // Global
  const token = useContext(ApiTokenContext);
  const navigate = useNavigate();
  const { user } = useAuth0();
  const queryClient = useQueryClient();

  // Local
  const { customError, setCustomError } = useCustomError();

  // Allow user to cancel report generation
  const cancelReport = () => {
    console.log("Report generation canceled!");
    queryClient.cancelQueries([
      doDataAnalysisQuery,
      {
        token,
        username: user?.name,
        sampledIds: props.reportInputs?.sampledIds || [],
        chartData: props.chartData,
        dateRange: props.dateRange,
        customPrompt: props.customPrompt,
      },
    ]);
  };

  // Query GPT-4, only set inputs are set.
  const { isLoading, error, data } = useQuery({
    queryKey: [
      doDataAnalysisQuery,
      {
        token,
        username: user?.name,
        sampledIds: props.reportInputs?.sampledIds || [],
        chartData: props.chartData,
        dateRange: props.dateRange,
        customPrompt: props.customPrompt,
      },
    ],
    queryFn: getDataAnalysis,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!token && props.isReady,
  });

  // Show user a spinner or handle error
  if (isLoading) {
    return { isLoading, cancelReport };
  }

  if (error instanceof Error && customError?.message?.length && customError.message.length < 1) {
    // TODO: Update this to handle missing content gracefully
    setCustomError({ message: error.message });
    return { customError };
  }

  if (data) {
    console.log("New report:", data.data.response.id);
    props.setFadeOut(true);
    setTimeout(() => {
      navigate(`${RouteOptions.report}?id=${data.data.response.id}`);
    }, 2000);
  }

  return { cancelReport };
};

export default useAddReport;
