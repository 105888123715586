import { FileTypes } from "../../models/FileTypes";

// Extracts the file type from full path
export default function getFileExtension (path: string) {
  const lastPeriod = path.lastIndexOf(".")
  // Get the extension as uppercase
  const extension = path.slice(lastPeriod+1,).toUpperCase();

  // Get the type from the FileTypes array
  return FileTypes.find(file => file.ext === extension)?.type;
}