import { useState, useEffect } from "react";
import { useFormikContext } from "formik";

// Import MUI and Formik
import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Import local
import PlatformCard from "./PlatformCard";
import { InputStepProps } from "../../lib/addEvidenceForms/InputStepProps";
import { Platform, QuickSubmitTypes } from "../../models/Platform";
import TextFieldInput from "./TextFieldInput";
import AddAttachments from "./AddAttachments";
import AmpDatePicker from "./AmpDatePicker";
import { SocialMediaPostType } from "../../lib/util/enums";
import LShapedSpacer from "./LShapedSpacer";
import { AttachmentHandlerProps } from "../../lib/util/interfaces";
import { formikEvidenceFormValues } from "../../lib/addEvidenceForms/AddEvidenceForm";

interface Props extends InputStepProps {
  attachmentHandler: AttachmentHandlerProps;
  stepHandler: { isDetailAdd: boolean };
  setShowingAllEssentialFields: (v: boolean) => void;
}

const AddEvidenceEssentials = ({
  handleAllowNext,
  updateFormData,
  formData,
  attachmentHandler,
  stepHandler,
  setShowingAllEssentialFields,
}: Props) => {
  // Globals
  const formik = useFormikContext();

  // Check if quickAdd workflow should be activate
  const [isQuickAddWorkflow, setIsQuickAddWorkflow] = useState<boolean>(
    (formData.platformObject?.isQuickAddType || false) &&
      formData.socialMediaPostType !== SocialMediaPostType.Comment &&
      Object.keys(formData).every(
        (key) =>
          key === "id" ||
          key === "url" ||
          key === "platformObject" ||
          key === "socialMediaPostType" ||
          formData[key as keyof typeof formData] === null ||
          formData[key as keyof typeof formData] === undefined ||
          formData[key as keyof typeof formData] === ""
      )
  );

  const [shareItems, setShareItems] = useState<Platform[]>(() => {
    // Set initial open defined by isQuickAdd or is there data?
    // There might be data if this is a reviewSubmit workflow
    const initialOpen: Platform[] = [];
    if (formData.url || isQuickAddWorkflow) {
      initialOpen.push(QuickSubmitTypes.URL);
    }
    if (formData.text && !isQuickAddWorkflow) {
      initialOpen.push(QuickSubmitTypes.Text);
    }
    if (attachmentHandler.fileArray.length > 0 && !isQuickAddWorkflow) {
      initialOpen.push(QuickSubmitTypes.Attachment);
    }
    return initialOpen;
  });

  // Create an iterable list of Platforms (only URL if quickAdd)
  const quickSubmitTypes: Platform[] = isQuickAddWorkflow
    ? [QuickSubmitTypes.URL]
    : Object.keys(QuickSubmitTypes).map(
        (key) => QuickSubmitTypes[key as keyof typeof QuickSubmitTypes]
      );

  // Update form data with user selection
  const handleClick = (platform: Platform) => {
    const isAdding: boolean = !shareItems.includes(platform);
    if (isAdding) {
      setShareItems([...shareItems, platform]);
    } else {
      setShareItems(shareItems.filter((item) => item !== platform));
    }
  };

  // handle Allow Next Button
  useEffect(() => {
    if (
      ((formData.url || attachmentHandler.fileArray.length > 0 || formData.text) &&
        formData.date) ||
      (formData.url && isQuickAddWorkflow)
    )
      handleAllowNext(true);
    else handleAllowNext(false);
  }, [formData, attachmentHandler]);

  // Open Forms and Populate on AddDetail
  useEffect(() => {
    if (stepHandler.isDetailAdd) {
      setIsQuickAddWorkflow(false);
      formik.resetForm({ values: formikEvidenceFormValues(formData) });
      setShareItems(Object.values(QuickSubmitTypes));
    }
  }, [stepHandler.isDetailAdd]);

  // Tell parent if all fields are showing
  useEffect(() => {
    if (shareItems.length === 3) {
      setShowingAllEssentialFields(true);
    } else {
      setShowingAllEssentialFields(false);
    }
  }, [shareItems]);

  return (
    <Box>
      <Grid container spacing={2} marginTop={2}>
        {/* Map the Platforms cards */}
        {quickSubmitTypes.map((option, index) => (
          <Grid xs={4} key={index}>
            <PlatformCard
              platform={option}
              onClick={handleClick}
              isOutlined={shareItems.includes(option)}
            />
          </Grid>
        ))}

        <Grid xs={12}>
          {shareItems.length > 0 && !isQuickAddWorkflow && (
            <Box>
              {" "}
              <AmpDatePicker field="date" updateFormData={updateFormData} date={formData.date} />
            </Box>
          )}
          {/* URL */}
          {shareItems.includes(QuickSubmitTypes.URL) && <TextFieldInput field="url" />}

          {/* Original Post */}
          {shareItems.includes(QuickSubmitTypes.Text) &&
            (formData.socialMediaPostType === SocialMediaPostType.Comment ||
              formData.socialMediaPostType === SocialMediaPostType.Reply) && (
              <TextFieldInput field="originalPost" rows={3} rowsMobile={4} />
            )}

          {/* L-Shaped Spacer if Text is in response to Original Post */}

          {shareItems.includes(QuickSubmitTypes.Text) && (
            <Stack direction="row" width={"100%"}>
              {(formData.socialMediaPostType === SocialMediaPostType.Comment ||
                formData.socialMediaPostType === SocialMediaPostType.Reply) && <LShapedSpacer />}

              {/* Primary Signal Text */}
              <Box flexGrow={1}>
                <TextFieldInput field="text" rows={3} rowsMobile={4} required={false} />
              </Box>
            </Stack>
          )}

          {shareItems.includes(QuickSubmitTypes.Attachment) && (
            <AddAttachments attachmentHandler={attachmentHandler} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEvidenceEssentials;
