// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import { Evidence } from "../../../models/Evidence";
import EvidenceDetails from "./EvidenceDetails";
import ShowResponses from "../content/ShowResponses";
import { AmpDrawerState } from "../../../lib/util/enums";

interface Props {
  evidence: Evidence;
  initialOpenTab: AmpDrawerState;
  isQA?: boolean;
}

// This component controls the display of the drawer when viewing Evidence
// (i.e., in My Collection or Analyze views)
const EvidenceDrawer = ({ evidence, initialOpenTab, isQA }: Props) => {
  const [openTab, setOpenTab] = useState<AmpDrawerState>(initialOpenTab);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Box>
      <Box m={isSmallScreen ? 1 : 3}>
        <Tabs
          value={openTab}
          onChange={(_, newValue) => setOpenTab(newValue)}
          aria-label="Choose Tab"
        >
          <Tab label={t("drawers.details.Title")} value={AmpDrawerState.EvidenceDetails} />
          <Tab label={t("drawers.responses.Title")} value={AmpDrawerState.Responses} />
        </Tabs>
      </Box>

      {/* Display correct subcomponent */}
      <Box m={isSmallScreen ? 1 : 3}>
        {openTab === AmpDrawerState.Responses && (
          <div>
            <ShowResponses evidence={evidence} />
          </div>
        )}
        {openTab === AmpDrawerState.EvidenceDetails && (
          <EvidenceDetails evidence={evidence} isQA={isQA} />
        )}
      </Box>
    </Box>
  );
};

export default EvidenceDrawer;
