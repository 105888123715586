// MUI
import { Box, CardMedia } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

// Local
import useAttachments from "../../hooks/useAttachments";
import { AttachmentTypes } from "../../models/FileTypes";
import CircleLoading from "../navigation/CircleLoading";
import AttachmentIcon from "./AttachmentIcon";
import { AttachmentListProps } from "../../lib/util/interfaces";
import AttachmentMissing from "./AttachmentMissing";

interface Props {
  attachments?: string[] | undefined;
}

const EvidenceAttachments = ({ attachments }: Props) => {
  // Get attachment data via useAttachments hook
  const { attachmentList, isLoading, showVideos, setShowVideos } =
    useAttachments({ attachments });

  if (isLoading) {
    return <CircleLoading />;
  }

  // Handle different media types
  // Wrap attachments in Content-Type-specific component
  const handleBlob = (attachment: AttachmentListProps) => {
    if (
      attachment.attachmentType !== AttachmentTypes.error &&
      attachment.blob
    ) {
      const blobUrl = URL.createObjectURL(attachment.blob);
      if (attachment.attachmentType === AttachmentTypes.image) {
        return (
          <img src={blobUrl} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        );
      } else if (attachment.attachmentType === AttachmentTypes.video) {
        return <CardMedia component="video" controls src={blobUrl} />;
      } else if (attachment.attachmentType === AttachmentTypes.audio) {
        return <CardMedia component="audio" controls src={blobUrl} />;
      } else if (attachment.attachmentType === AttachmentTypes.file) {
        return <AttachmentIcon url={blobUrl} />;
      }
    } else return <AttachmentMissing />;
  };

  return (
    <>
      {/* Return a mapped array of attachments */}
      {attachmentList &&
        attachmentList.map((attachment, index) => (
          <Box key={index} my={1}>
            {handleBlob(attachment)}
          </Box>
        ))}
      {/* If attachments include videos, lazy load behind a Play icon */}
      {!showVideos && attachmentList && attachmentList?.length === 0 && (
        <Box display="flex" justifyContent={"center"}>
          <PlayCircleIcon
            sx={{ fontSize: "3.5rem" }}
            onClick={() => setShowVideos(true)}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};
export default EvidenceAttachments;
