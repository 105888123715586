// Global
import { useContext } from "react";

// MUI
import { Box } from "@mui/material";

// Local
import { ThemesContext } from "../../contexts/InitContexts";
import EditButton from "../buttons/EditButton";
import useEditEvidence from "../../hooks/useEditEvidence";
import ThemeListItem from "./ThemeListItem";
import { labeledObject } from "../../lib/util/interfaces";
import { idLookups } from "../../lib/util/idLookups";

interface Props {
  ids: string[] | undefined;
  isQA?: boolean;
  evidenceId?: string;
}

// Format theme list for evidence card display
const ThemeList = ({ ids, isQA, evidenceId }: Props) => {
  const { values, isEditting, isLoading, handleEdit, editView } = useEditEvidence({
    type: "themeIds",
    ids,
    evidenceId: evidenceId || "",
  });

  // Local hooks
  const themeData = useContext(ThemesContext);
  const themes: labeledObject[] | null = values?.length
    ? idLookups({ ids: values, data: themeData })
    : null;

  // If textList exists, map it
  // Add DotSpacer between themes
  return isLoading ? (
    isLoading
  ) : isEditting ? (
    editView
  ) : (
    <Box alignItems="center">
      {/* Default view */}
      {themes &&
        !isEditting &&
        themes.map((theme, index) => (
          <ThemeListItem key={index} theme={theme} index={index} length={themes.length} />
        ))}

      {isQA && <EditButton handleEdit={handleEdit} type="Themes" />}
    </Box>
  );
};

export default ThemeList;
