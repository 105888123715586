// Global
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  clickExport: () => void;
}

const ReportViewControls = ({ clickExport }: Props) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const icons = (
    <Stack direction={"row"} spacing={2}>
      <Tooltip title={t("generatedReport.tooltip.PreviewReport")}>
        <PrintIcon
          color="primary"
          fontSize={isSmallScreen ? "medium" : "large"}
          onClick={clickExport}
        />
      </Tooltip>
      <Tooltip title={t("generatedReport.tooltip.CloseReport")}>
        <CloseIcon
          color="primary"
          fontSize={isSmallScreen ? "medium" : "large"}
          onClick={() => {
            navigate(RouteOptions.report);
          }}
        />
      </Tooltip>
    </Stack>
  );

  return (
    <>
      {/* Mobile only: DateSort up top */}
      {isSmallScreen && (
        <Grid xs={5} container display="flex" justifyContent="flex-end">
          {icons}
        </Grid>
      )}
      <Grid xs={12} md={8} display="flex">
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Box my={1}>
            <Typography variant="body1">{t("generateReport.InstructionsPart1")}</Typography>
          </Box>

          {!isSmallScreen && icons}
        </Stack>
      </Grid>
    </>
  );
};

export default ReportViewControls;
