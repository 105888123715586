// Globals
import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

// Import MUI
import { Box, Stack } from "@mui/material";

// Import formik and formik-mui
import { Formik, Form } from "formik";
import TextFieldInput from "../addEvidence/TextFieldInput";
// import * as Yup from "yup";

// Locals
import { ApiTokenContext } from "../../contexts/InitContexts";
import { ContentLanguages } from "../../models/Content";
import ChipInput from "../addEvidence/ChipInput";
import { appMobileWidth, appMaxWidth, mainStageMax } from "../../lib/theme/default";
import AmpButton from "../buttons/AmpButton";
import AutocompleteMultiple from "../addEvidence/AutocompleteMultiple";
import cleanAddContent from "../../lib/util/cleanAddContent";
import useContentForm from "../../hooks/useContentForm";
import LinearLoader from "../navigation/LinearLoading";
import AlertDialogSlide from "../AlertSlideDialog";
import submitData from "../../lib/util/submitData";
import { AmpDataSubmitTypes } from "../../lib/util/enums";

const AddContent = () => {
  const { user } = useAuth0();
  const apiToken = useContext(ApiTokenContext);

  // Manage form data and submission
  const { formData, updateFormData } = useContentForm();
  const mutation = useMutation(submitData);

  // Finish the submission, triggered when binary uploads complete
  const submitNow = () => {
    const name = user?.name;
    const data = cleanAddContent({ formData, name });
    mutation.mutate({ submitType: AmpDataSubmitTypes.Content, data, apiToken });
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      maxWidth={mainStageMax} 
      width={appMobileWidth}
    >
      <Formik
        initialValues={{
          contentName: formData.contentName || "",
          code: formData.code || "",
          contentDescription: formData.contentDescription || "",
        }}
        // validationSchema={Yup.object({
        //   contentBinaryPath: Yup.string().url(),
        // })}
        onSubmit={(values, { setSubmitting }) => {
          updateFormData(values);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box>
              {/* Add url path */}
              <TextFieldInput field="contentBinaryPath" required />

              {/* Content name, language, description */}
              <TextFieldInput field="contentName" required />

              <ChipInput
                label="contentLanguage"
                values={Object.keys(ContentLanguages)}
                selectedValues={formData.contentLanguage?.toString()}
                exclusive={true}
                required={true}
                showTitle
                updateFormData={updateFormData}
              />

              <TextFieldInput field="contentCode" />

              <TextFieldInput
                field="contentDescription"
                rows={3}
                rowsMobile={4}
              />

              {/* Add issues and themes */}
              <AutocompleteMultiple
                field="issueIds"
                selectedValues={formData.issueIds}
                updateFormData={updateFormData}
              />

              <AutocompleteMultiple
                field="themeIds"
                selectedValues={formData.themeIds}
                updateFormData={updateFormData}
              />

              <Box my={2}>
                {/* Submit button */}
                {!mutation.isLoading && (
                  <AmpButton
                    text="Submit"
                    type="accent"
                    symbol="check"
                    disabled={
                      !formData.contentName ||
                      !formData.contentLanguage ||
                      !formData.contentBinaryPath
                    }
                    onClick={() => submitNow()}
                  />
                )}

                {/* Post-submit dialogues */}
                {mutation.isLoading && <LinearLoader />}
                <AlertDialogSlide
                  title={
                    mutation.isSuccess
                      ? "addContent.Success"
                      : "addContent.Error"
                  }
                  message={
                    mutation.isSuccess
                      ? "addContent.Message"
                      : "addContent.ErrorMessage"
                  }
                  confirm="Confirm"
                  isOpen={!!(mutation.isSuccess || mutation.isError)}
                  // Reload window on dialogue close
                  handleUserChoice={() => window.location.reload()}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default AddContent;
