// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Link, Typography, useTheme, useMediaQuery} from "@mui/material";

const Footer = () => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex" justifyContent={"center"} mb={isSmallScreen ? 2 : 0} textAlign="center">
      <Typography variant="body1" fontWeight={400}>
        {t("footer.1")}
         {"  "}
        <Link href="https://www.afro.who.int/aira" target="_blank" rel="noreferrer"> The Africa Infodemic Response Alliance</Link>
        {t("footer.2")}
        <Link href="https://www.rootwise.co" target="_blank" rel="noreferrer">RootWise</Link>
        {t("footer.3")} 
        <Link href="https://www.rockefellerfoundation.org" target="_blank" rel="noreferrer"> The Rockefeller Foundation</Link>
      </Typography>
    </Box>
  );
};

export default Footer;
