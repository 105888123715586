// Global
import { useTranslation } from "react-i18next";

// MUI & Formik
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TextField } from "formik-mui";
import { Field, useFormikContext } from "formik";

// Local
import RequiredIcon from "./RequiredIcon";
import { ChangeEvent } from "react";

interface Props {
  field: string;
  required?: boolean;
  width?: string;
  rows?: number;
  rowsMobile?: number;
  noTitle?: boolean;
  inline?: boolean;
}

// Build a text field component
const TextFieldInput = ({
  field,
  required,
  width,
  rows,
  rowsMobile,
  noTitle,
  inline,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { submitForm } = useFormikContext();

  return (
    <Box display={inline ? "inline" : ""} paddingRight={inline ? 1 : 0}>
      {!noTitle && (
        <Typography variant="h3" mt={2} mb={0.5}>
          {t("forms." + field + "Label")}
          {required && <RequiredIcon />}
        </Typography>
      )}
      <Field
        component={TextField}
        name={field}
        type={field}
        placeholder={t("forms." + field + "Placeholder")}
        multiline={true}
        minRows={rows ? (isSmallScreen ? rowsMobile : rows) : 1}
        maxRows={isSmallScreen ? 5 : 4}
        sx={{ width: width ? width : "100%", marginBottom: 1 }}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          // Update the form on blur
          submitForm();
        }}
      />
    </Box>
  );
};

export default TextFieldInput;
