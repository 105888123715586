import { useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { Box, Typography } from "@mui/material";
import RequiredIcon from "./RequiredIcon";

type Props = {
  field: string;
  date: string | undefined | null;
  updateFormData: (values: object) => void; // Give form data to parent
};

const AmpDatePicker = ({ field, date, updateFormData }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<Dayjs | null>(date ? dayjs(date) : null);

  const updateDate = (newValue: dayjs.Dayjs | null) => {
    setValue(newValue);
    const data = { date: dayjs(newValue).format("YYYY-MM-DD") };
    updateFormData(data);
  };

  return (
    <Box>
      <Typography variant="h3">
        {t("forms." + field + "Label")}
        <RequiredIcon />
      </Typography>

      <DatePicker
        value={value}
        disableFuture
        onChange={(newValue) => updateDate(newValue)}
        slotProps={{
          textField: {
            required: true,
          },
        }}
      />
    </Box>
  );
};

export default AmpDatePicker;
