// Global
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

// Local
import { EvidenceStatus, RouteOptions, SortableFields } from "../lib/util/enums";
import { getNDaysAgo } from "../lib/util/dateFormatters";
import { EvidenceProps } from "../hooks/useEvidenceFilters";
import { qaStatuses } from "../lib/configs";
import { DateRangeProps } from "../lib/util/interfaces";

// Construct evidence filter and search params
export class EvidenceFilters {
  view: RouteOptions;
  status?: EvidenceStatus[];
  sortField: SortableFields;
  sortDesc: boolean;
  dateRange: DateRangeProps | undefined;
  searchText?: string;
  countries?: string[];
  issueIds?: string[];
  isMisinformation?: boolean;
  themeIds?: string[];
  languages?: string[];
  platforms?: string[];
  sentiment?: string[];
  interactions?: number;
  attachments?: boolean;
  ids?: string[];

  constructor({ view, urlParams, isDateFilter, countries }: EvidenceProps) {
    this.view = view;
    // Sort DESC (newest first) on date (if QA, on createdAt)
    this.sortField = view === RouteOptions.QA ? SortableFields.CreatedAt : SortableFields.Date;
    // Leave status undefined unless in QA View
    this.status = view === RouteOptions.QA ? qaStatuses : undefined;
    this.sortDesc = true;
    this.countries = countries;

    let days = undefined;

    // Iterate over urlParams to build filters
    if (urlParams) {
      for (let [key, value] of urlParams.entries()) {
        switch (key) {
          case "id":
            this.ids = value.split(",").map((term) => term.trim());
            break;
          case "issueIds":
          case "themeIds":
          case "countries":
            this[key] = value.split(",").map((term) => term.trim());
            break;
          case "days":
            days = Number(value);
            break;
          case "isMisinfo":
            this.isMisinformation = value === "true";
            break;
          // Add more cases as needed
          default:
            console.log(`Unknown key: ${key}`);
        }
      }
    }

    // Date filter: Last 30 days unless isDateFilter = false, then none
    this.dateRange = isDateFilter
      ? {
          gte: getNDaysAgo(days),
          lte: dayjs().utc().endOf("day"),
        }
      : undefined;
  }
}

// Evidence filters: optional properties
// Used to check if optional filters are active or not
// And for the Clear Filters button
export class ClearedEvidenceFilters {
  ids: undefined;
  countries: undefined;
  issueIds: undefined;
  isMisinformation: undefined;
  themeIds: undefined;
  languages: undefined;
  platforms: undefined;
  interactions: undefined;
  attachments: undefined;
  sentiment: undefined;
}
