// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Box, Tooltip } from "@mui/material";

// Local
import { SentimentValues } from "../../lib/util/enums";
import useEditEvidence from "../../hooks/useEditEvidence";

interface Props {
  id: string;
  sentiment?: SentimentValues;
  isQA?: boolean;
}

// This component displays the sentiment icon based on the sentiment value
const SentimentIcon = ({ id, sentiment, isQA }: Props) => {
  // Globals
  const { t } = useTranslation();

  const displayedSentiment = sentiment ? sentiment : isQA ? SentimentValues.Undefined : undefined;

  const { values, submitEdits } = useEditEvidence({
    evidenceId: id,
    ids: [displayedSentiment || ""],
  });

  if (!displayedSentiment || !values) {
    return null;
  }

  // Track value and click-timeout in state
  const [value, setValue] = useState(values?.[0] || "");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  // If in QA Mode, edit sentiment on click
  const handleClick = () => {
    const newValue = (() => {
      switch (value) {
        case SentimentValues.Positive:
          return SentimentValues.Neutral;
        case SentimentValues.Neutral:
          return SentimentValues.Negative;
        default:
          return SentimentValues.Positive;
      }
    })();

    setValue(newValue);

    // 3 second delay on edit to allow multiple toggles
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(() => {
        submitEdits("sentiment", newValue);
      }, 3000)
    );
  };

  const getIcon = (value: string) => {
    switch (value) {
      case SentimentValues.Positive:
        return (
          <SentimentSatisfiedAltIcon color="success" className={isQA ? undefined : "isNotButton"} />
        );
      case SentimentValues.Neutral:
        return (
          <SentimentNeutralIcon color="primary" className={isQA ? undefined : "isNotButton"} />
        );
      case SentimentValues.Negative:
        return (
          <SentimentVeryDissatisfiedIcon
            color="error"
            className={isQA ? undefined : "isNotButton"}
          />
        );
      case SentimentValues.Undefined:
        return <SentimentNeutralIcon color="info" className={isQA ? undefined : "isNotButton"} />;
      default:
        return null;
    }
  };

  return (
    <Tooltip title={t("forms.radioGroups.sentiment." + value)}>
      <Box
        display="inline-flex"
        alignItems={"start"}
        ml={1}
        onClick={isQA ? handleClick : undefined}
      >
        {getIcon(value)}
      </Box>
    </Tooltip>
  );
};

export default SentimentIcon;
