// MUI
import { Box, Link, Stack, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Local
import { Content } from "../../../models/Content";
import { COLORS, responseCardSize } from "../../../lib/theme/default";

interface Props {
  content: Content;
}

// Display a logo and link to WHO-sponsored content
export const WHOContent = ({ content }: Props) => {
  return (
    <Box display="flex" justifyContent={"center"}>
      <Stack display="flex" alignItems={"center"} spacing={2} mb={2} width={responseCardSize.WHO}>
        <img src={"/icons/WHO.svg"} alt={"WHO Logo"} style={{padding: '5px'}} />
        <Typography variant="h2">
          {content.nameTranslated?.en || content.nameTranslated?.fr}
        </Typography>
      </Stack>
    </Box>
  );
};

// Display a logo and link to TOGO Check
export const TogoCheck = ({ content }: Props) => {
  return (
    <Box display="flex" justifyContent={"center"}>
      <Stack display="flex" alignItems={"center"} spacing={2} mb={2} width={responseCardSize.WHO}>
        <img src={"/icons/TogoCheck.png"} alt={"TogoCheck Logo"} style={{padding: '5px', width: '248px', backgroundColor: COLORS.nearBlack}} />
        <Typography variant="h3" fontSize={'large'}>
          {content.nameTranslated?.en || content.nameTranslated?.fr}
        </Typography>
      </Stack>
    </Box>
  );
};

export const DefaultContent = ({ content }: Props) => {
  return (
    <Box display="flex" justifyContent={"center"}>
       <Link href={content.binaryPath} target="_blank" rel="noreferrer" width="100%" underline="none">
      <Stack display="flex" alignItems={"center"} spacing={2} mb={2} width={responseCardSize.WHO}>
       
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor="white"
            borderRadius="borderRadius"
            width="100%"
            minHeight={responseCardSize.DefaultContentHeight}
          >
            <OpenInNewIcon fontSize="large" />
          </Box>
       
        <Typography variant="h2">
          {content.nameTranslated?.en || content.nameTranslated?.fr}
        </Typography>
      </Stack>
      </Link>
    </Box>
  );
};
