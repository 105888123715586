// Global
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Import MUI
import { Box, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Import Local
import { CountriesContext } from "../../contexts/InitContexts";
import RequiredIcon from "./RequiredIcon";
import { COLORS } from "../../lib/theme/default";
import { labeledObject } from "../../lib/util/interfaces";
import { Theme } from "../../models/Theme";
import { formatLocationsChanges } from "../../lib/util/formatters";
import useChipData from "../../hooks/useChipData";

interface Props {
  field: string;
  selectedValues: string[] | undefined | null;
  required?: boolean;
  isDisabled?: boolean;
  isFilter?: boolean;
  isHiddenTitle?: boolean;
  country?: string;
  locations?: string[];
  placeholderText?: string;
  width?: number;
  isSmall?: boolean;
  updateFormData: (values: Record<string, string[]>) => void;
}

// AutocompleteMultiple is a reusable component that provides a multi-select dropdown functionality.
// It is used in various parts of the application where a user needs to select multiple options from a list.
// The component is highly customizable and can be used with different types of data.
// The 'field' prop determines the type of data the component will work with.
// The 'selectedValues' prop is an array of currently selected values.
// The 'required' prop indicates whether the field is required or not.
// The 'isFilter' prop indicates whether the component is used as a filter or not.
// The 'country' and 'locations' props are used when the component is working with location data.
// The 'updateFormData' function is called when the selected values change.
const AutocompleteMultiple = ({
  field,
  selectedValues,
  required,
  isFilter,
  isDisabled,
  isHiddenTitle,
  country,
  locations,
  placeholderText,
  width,
  isSmall,
  updateFormData,
}: Props) => {
  const { t } = useTranslation();
  const countries = useContext(CountriesContext);

  // Get formatted field data from useChipData
  const { dataList } = useChipData({ field, country });

  // Set current state of values from props (selectedValues)
  const getValues = () => {
    if (selectedValues) {
      const currentData = [];
      const myOptions = dataList;
      for (const value of selectedValues) {
        const index = myOptions.findIndex((obj: labeledObject | Theme) =>
          field === "cities" ? obj.label === value : obj.id === value
        );
        if (index !== -1) currentData.push(myOptions[index]);
      }
      return currentData;
    } else return [];
  };

  // Memoize repeat calcs
  const values = useMemo(() => getValues(), [selectedValues, dataList]);

  // Push changes to formData
  const handleChange = (event: object, value: labeledObject[]) => {
    const data: Record<string, string[]> = {};

    if (field === "locations" || field === "cities") {
      data.locations = formatLocationsChanges(value, country, locations);
    } else {
      data[field] = [];
      for (const row of value) {
        data[field].push(row.id);
      }
    }
    updateFormData(data);
  };

  // // Implement grouping, if required by themeIds
  // const handleGrouping = useCallback((option: Theme | labeledObject): string => {
  //   if (option instanceof Theme) {
  //     return option.group;
  //   } else {
  //     return "";
  //   }
  // }, []);

  // Set form label: Forms (Add Signals) or Filters
  const labelType = isFilter ? "drawers.filters." : "forms.";

  //  Display field name, unless specific list of cities in a country
  const labelText =
    field === "cities" && !!country
      ? t(labelType + field + "Of") +
        " " +
        countries.find((item) => item.id === country)?.label +
        "?"
      : t(labelType + field + "Label");

  return (
    // <Box mt={1} mb={2}>
    <Box mb={2}>
      {!isHiddenTitle && (
        <Typography variant="h3" mb={0.5}>
          {labelText}
          {required && <RequiredIcon />}
        </Typography>
      )}
      <Autocomplete
        multiple
        id={field + "Autocomplete"}
        options={dataList}
        // Option to group list items by category. Currently not needed on any data type.
        // groupBy={(option) => handleGrouping(option)}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={values}
        filterSelectedOptions
        onChange={handleChange}
        disabled={isDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholderText ? t(placeholderText).toString() : undefined}
            size={isSmall ? "small" : undefined}
            sx={{
              backgroundColor: isFilter ? COLORS.backgroundGray : "auto",
              color: "black",
              width: width || undefined,
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteMultiple;
