// Globals
import { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

// Locals
import useAxios from "./useAxios";
import { ApiTokenContext } from "../contexts/InitContexts";
import { getUserRequestsQuery } from "../lib/api/keyConstants";

export interface UserRequest {
    id: string;
    lastUpdated: Date;
    status: string;
  
    email: string;
    role?: string;
    country?: string;
    organization?: string;
    reason?: string;
  }

interface QueryProps {
    queryKey: [
      string,
      { token: string; status?: string[] }
    ];
  }


const getUserRequests = async ({ queryKey }: QueryProps) => {
    const [_key, { token, status }] = queryKey;
    const params = {
        status: JSON.stringify(status),
        pageSize: 20,
      }
    
    const { data } = await useAxios({
        endpoint: "getUserRequests",
        token: token,
        params: params,
      });

      return data;
}


// Get new user requests for approval/rejection
const useNewUserRequests = () => {
    const token = useContext(ApiTokenContext);

        // Static status filter: pending only
    const status = ["pending", "approved", "denied"];
  
    const { isLoading, error, data, isFetching} = useQuery({
      queryKey: [getUserRequestsQuery, { token, status }],
      queryFn: getUserRequests,
      keepPreviousData: true,
    //   select: (data) => parseResults(data),
      staleTime: Infinity,
      enabled: !!token,
    });
  
    // Show user a spinner or handle error
    if (isLoading || isFetching ) {
      return { isLoading };
    }
  
    if (error instanceof Error) {
      return { errorMessage: "NoContent" + error.message };
    }

    const response = data.response.body.hits.hits;
    const userRequests: UserRequest[] = response.map((user: any) => {
      return {
        id: user._id,
        lastUpdated: user._source.lastUpdated,
        status: user._source.status,
        email: user._source.email,
        role: user._source.role,
        country: user._source.country,
        organization: user._source.organization,
        reason: user._source.reason,
      }
    })

    return { userRequests };
  };

  export default useNewUserRequests;

