// Global
import { useTranslation } from "react-i18next";

// MUI
import { useTheme, useMediaQuery, Stack, Box, SelectChangeEvent, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

// Local
import { trendingPeriodOptions } from "../../lib/configs";
import AutocompleteMultiple from "../addEvidence/AutocompleteMultiple";
import AmpButton from "../buttons/AmpButton";
import AmpDropdown from "../buttons/AmpDropdown";
import { EvidenceFilters } from "../../models/EvidenceFilters";
import { AmpDrawerState, AmpTrendTypes } from "../../lib/util/enums";
import AmpDrawer from "../drawers/AmpDrawer";
import useAmpDrawer from "../../hooks/useAmpDrawer";

interface Props {
  evidenceFilters: EvidenceFilters;
  updateEvidenceFilters: (values: object) => void; // Give form data to parent
  handleClick: (value: string) => void;
  trendingPeriod: number;
  handlePeriodChange: (event: SelectChangeEvent) => void;
  openTab: AmpTrendTypes;
  isReadyForGenerate: boolean;
}

export const TrendsMenu = (props: Props) => {
  // Global
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  // Local
  const { drawerState, setDrawerState } = useAmpDrawer();

  const filters = (
    <>
      {props.openTab !== AmpTrendTypes.Content && (
        <AutocompleteMultiple
          field="countries"
          isHiddenTitle
          placeholderText={
            (props.evidenceFilters.countries?.length ?? 0) > 0
              ? undefined
              : "chooseView.ChooseCountry"
          }
          width={isMediumScreen || isXSmallScreen ? undefined : 300}
          isSmall
          selectedValues={props.evidenceFilters.countries}
          updateFormData={props.updateEvidenceFilters}
        />
      )}
      <AutocompleteMultiple
        field="issueIds"
        isHiddenTitle
        placeholderText={
          (props.evidenceFilters.issueIds?.length ?? 0) > 0
            ? undefined
            : "chooseView.ChooseIssueIds"
        }
        width={isMediumScreen || isXSmallScreen ? undefined : 300}
        isSmall
        selectedValues={props.evidenceFilters.issueIds}
        updateFormData={props.updateEvidenceFilters}
      />
      {props.openTab !== AmpTrendTypes.Content && (
        <AmpDropdown
          name="trending"
          values={trendingPeriodOptions}
          currentValue={props.trendingPeriod}
          hideLabel
          isWhite
          handleChange={props.handlePeriodChange}
        />
      )}
    </>
  );

  const actions = (
    <>
      <Box display="flex" justifyContent={"center"}>
        <AmpButton
          type="secondary"
          text={"Analyze"}
          symbol={"analyze"}
          onClick={() => props.handleClick("analyze")}
        />
      </Box>
      <Box display="flex" justifyContent={"center"}>
        <AmpButton
          type="accent"
          text={"GenerateReport"}
          symbol={"insight"}
          disabled={props.isReadyForGenerate}
          onClick={() => props.handleClick("generate")}
        />
      </Box>
    </>
  );

  const filterIcon = (
    <FilterAltIcon
      onClick={() => setDrawerState(AmpDrawerState.LandingPage)}
      color="secondary"
      fontSize="large"
    />
  );

  return (
    <Stack direction={"column"} spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={2}
      >
        <Typography variant="h2">{t("Trends")}</Typography>

        {/* Full Size Layout */}
        {!isMediumScreen && !isXSmallScreen && (
          <Stack direction="row" spacing={1} display="flex" alignItems={"center"}>
            {filters}
            {actions}
          </Stack>
        )}

        {/* Medium size layout */}
        {isMediumScreen && (
          <Stack direction="row" spacing={1} display="flex" alignItems={"center"}>
            {filterIcon}
            {actions}
          </Stack>
        )}

        {/* Small Size layout */}
        {isXSmallScreen && filterIcon}
      </Stack>
      {isXSmallScreen && actions}
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <Box m={2}>
            <Typography variant="h2" mb={1}>
              {t("drawers.filters.Title")}
            </Typography>
            {filters}
          </Box>
        </AmpDrawer>
      )}
    </Stack>
  );
};
