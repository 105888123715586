import { labeledObject } from "./interfaces";
import { sortByLabel } from "./sorters";

interface Props {
  data: labeledObject[];
  ids: string[];
  isAlphabetized?: boolean;
}

// Given Id, return the labels of idLabelDescription
export const idLookups = ({ data, ids, isAlphabetized }: Props) => {
  let lookupList: labeledObject[] = data
    .filter((object) => ids.includes(object.id))
    .map((object) => ({
      id: object.id,
      label: object.label,
      description: object.description,
    }));

  if (!isAlphabetized) {
    // Alphabetize
    lookupList = sortByLabel(lookupList);
  }
  return lookupList;
};

interface IdLabelLookUpProps {
  id: string;
  data: { id: string; label: string }[];
}

// Given Id, return the label of idLabel
export const idLabelLookup = ({ id, data }: IdLabelLookUpProps): string => {
  if (id) {
    const index = data.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      return data[index].label;
    }
  }
  return "";
};
