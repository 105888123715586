// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Local
import { Content } from "../../models/Content";
import { idLabelLookup } from "../../lib/util/idLookups";
import { africanLanguages } from "../../models/Languages";
import { appMaxWidth, appMobileWidth, mainStageMax } from "../../lib/theme/default";
import CircleLoading from "../navigation/CircleLoading";

interface Props {
  contentList?: Content[];
  setSelectedRows?: (ids: number[]) => void;
}

const ContentGrid = ({ contentList, setSelectedRows }: Props) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  if (!contentList || !setSelectedRows) {
    return <CircleLoading />;
  }

  // Define grid columns
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("contentManager.contentGrid.Name") || "",
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "desc",
      headerName: t("contentManager.contentGrid.Description") || "",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "code",
      headerName: t("contentManager.contentGrid.Code") || "",
      width: 125,
    },
    {
      field: "active",
      headerName: t("contentManager.contentGrid.Active") || "",
      minWidth: 50,
    },
    {
      field: "lang",
      headerName: t("contentManager.contentGrid.Language") || "",
    },
    {
      field: "date",
      headerName: t("contentManager.contentGrid.CreatedDate") || "",
    },
    {
      field: "url",
      headerName: t("contentManager.contentGrid.URL") || "",
      width: 50,
      renderCell: (params) => (
        <a
          href={params.value as string}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <OpenInNewIcon />
        </a>
      ),
    },

    {
      field: "issues",
      headerName: t("contentManager.contentGrid.Issues") || "",
      minWidth: 150,
    },
    {
      field: "themes",
      headerName: t("contentManager.contentGrid.Themes") || "",
      minWidth: 150,
    },
  ];

  // Map grid rows to contentList
  const rows: GridRowsProp = contentList
    ? contentList.map((content, index) => {
        return {
          id: index,
          name: content.nameTranslated?.en || content.nameTranslated?.fr,
          code: content.code,
          lang: idLabelLookup({
            id: content.language || "",
            data: africanLanguages,
          }),
          desc:
            content.descriptionTranslated?.en ||
            content.descriptionTranslated?.fr,
          date: content.createdAt.toLocaleDateString(),
          url: content.binaryPath,
          active: content.active ? "Active" : "Archived",
          issues: content.issueIds,
          themes: content.themeIds,
        };
      })
    : [];

  return (
    <Box
      maxWidth={isSmallScreen ? appMobileWidth : mainStageMax}
      width={appMobileWidth}
    >
      <DataGridPro
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection.map(Number));
        }}
      />
    </Box>
  );
};

export default ContentGrid;
