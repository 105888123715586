// Global
import { useTranslation } from "react-i18next";

// MUI
import { Link, Tooltip } from "@mui/material";

// Local
import LaunchIcon from "@mui/icons-material/Launch";

interface Props {
  url: string | undefined;
}
const LinkIcon = ({ url }: Props) => {
  // Hide link if missing
  if (!url) {
    return null;
  }
  const { t, i18n } = useTranslation();

  // Open link in new browser tab
  return (
    <Link href={url} target="_blank" rel="noopener" display={'flex'}>
      <Tooltip title={t("cards.tooltip.EvidenceLink")}>
        <LaunchIcon color="primary" />
      </Tooltip>
    </Link>
  );
};

export default LinkIcon;
