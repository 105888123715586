// Global
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// MUI
import { Box, Stack, Link } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ArticleIcon from "@mui/icons-material/Article";

// Local
import SearchHelpDrawer from "../SearchHelpDrawer";
import ContactAmp from "./ContactAmp";

const HelpDrawer = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const isAdmin = "admin" === user?.["https://amp.rootwise.co/role"];

  return (
    <Box m={2}>
      <Typography variant="h1" my={2}>
        {t("drawers.help.Title")}
      </Typography>

      {/* Download Help Guide */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-help-content"
          id="panel-help-header"
        >
          {" "}
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <ArticleIcon color="primary" />
            <Typography variant="h2">{t("drawers.help.UserManualTitle")}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box>{t("drawers.help.UserManualText")}</Box>
          <Box mt={1}>
            <Link
              href="https://rootwise-amp-resources.s3.amazonaws.com/amp_help.pdf"
              download
              target="_blank"
            >
              {t("drawers.help.UserManualDownload")}
            </Link>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Download Poweruser Guide */}
      {isAdmin && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-help-content"
            id="panel-help-header"
          >
            {" "}
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <ArticleIcon color="primary" />
              <Typography variant="h2">{t("drawers.help.PoweruserManualTitle")}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Box>{t("drawers.help.PoweruserManualText")}</Box>
            <Box mt={1}>
              <Link
                href="https://rootwise-amp-resources.s3.amazonaws.com/amp_help_poweruser.pdf"
                download
                target="_blank"
              >
                {t("drawers.help.PoweruserManualDownload")}
              </Link>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <ContactSupportIcon color="primary" />
            <Typography variant="h2">{t("drawers.help.ContactAMP")}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <ContactAmp />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <SearchIcon color="primary" />
            <Typography variant="h2"> {t("drawers.searchHelp.Guide")}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <SearchHelpDrawer isAccordion />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HelpDrawer;
