// MUI
import { Stack, Typography } from "@mui/material"
import UploadFileIcon from "@mui/icons-material/UploadFile";

// Local
import { COLORS } from "../../lib/theme/default";

// This constant provides the UI Design for the
// Attachments component
const DragDropDesign = (innerLabel: string) => {
    return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            border: 3,
            borderColor: COLORS.secondaryGray,
            borderStyle: "dashed",
            borderRadius: 1,
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <UploadFileIcon
            sx={{ fontSize: "3rem", color: COLORS.secondaryGray }}
          />
          <Typography variant="h4" mt={1} pl={2} pr={2} textAlign={'center'}>
            {innerLabel}
          </Typography>
        </Stack>
    );
  };

  export default DragDropDesign;