// Global
import { Link, useRouteError } from "react-router-dom";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import { COLORS, appMaxWidth, appMobileWidth } from "../lib/theme/default";
import AmpLogo from "../components/landingPage/AmpLogo";
import { RouteOptions } from "../lib/util/enums";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Box
    className='App'  
    sx={{
        backgroundColor: COLORS.white,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        width={1}
        sx={{
          maxWidth: appMaxWidth,
          width: appMobileWidth,
        }}
      > <AmpLogo />
        <Typography variant="h1" mt={2}>Oops! An error occurred.</Typography>
        <Typography variant="h4" mt={2}>Error: {error.statusText || error.message}</Typography>
        <Typography variant="h3" mt={2}>Click your browser's back button or <Link to={RouteOptions.landing}>click here</Link> to return home.</Typography>
        <Typography variant="h3" mt={2}>If the problem persists, contact <a href="mailto:amp@rootwise.co">amp@rootwise.co</a> for support.</Typography>

        {error.stack && (
          <Box mt={2} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
            <Typography variant="h3" mb={1}>Stack Trace:</Typography>
            <Typography variant="body1" component="pre" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {error.stack}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
