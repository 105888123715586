// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import useContent from "../../../hooks/useContent";
import ContentItem from "./ContentItem";
import CircleLoading from "../../navigation/CircleLoading";
import { Evidence } from "../../../models/Evidence";
import AmpButton from "../../buttons/AmpButton";
import FlagForReview from "../../cards/FlagForReview";
import { AmpCardTypes } from "../../../lib/util/enums";

interface Props {
  evidence: Evidence;
}
// This component displays the Show Responses drawer
const ShowResponses = ({ evidence }: Props) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local Hooks
  const { contentList, isLoading, errorMessage, isNextButtonDisabled, page, setPage } = useContent({
    evidence,
  });

  if (isLoading) {
    return <CircleLoading />;
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={isSmallScreen ? 0 : 2} justifyContent={"center"}>
      <Grid xs={12} mt={2}>
        {/* Tab header */}
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h2" display={"inline-flex"}>
            {t("drawers.responses.Header")}
          </Typography>
          <FlagForReview evidence={evidence} type={AmpCardTypes.content} />
        </Stack>
      </Grid>

      {/* Display individual response options */}
      {contentList?.map((content) => (
        <Grid xs={12} md={"auto"} key={content.id}>
          <ContentItem content={content} />
        </Grid>
      ))}
      <Grid xs={12}>
        <Stack
          direction="row"
          display="flex"
          justifyContent={page === 0 ? "flex-end" : "space-between"}
        >
          {/* Pagination buttons */}

          {!!page && page > 0 && (
            <Box>
              <AmpButton text="Previous" type="secondary" onClick={() => setPage(page - 1)} />
            </Box>
          )}
          {page !== undefined && (
            <Box>
              <AmpButton
                text="Next"
                disabled={isNextButtonDisabled}
                onClick={() => setPage(page + 1)}
              />
            </Box>
          )}
        </Stack>
      </Grid>
      {errorMessage && <Grid xs={12}>{t("drawers." + errorMessage)}</Grid>}
    </Grid>
  );
};

export default ShowResponses;
