// Global
import { useTranslation } from "react-i18next";

// MUI
import { Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    id: string;
    archiveItem: (id: string) => void;
}
const HideListItem = ({id, archiveItem}: Props) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      mt={isSmallScreen ? 2 : 0}
      ml={isSmallScreen ? "auto" : 4}
      justifyContent={"center"}
      direction="row"
      spacing={2}
    >
      <Tooltip title={t("cards.tooltip.SetArchived")}>
        <DeleteIcon
          color={"primary"}
          fontSize="large"
          onClick={() => archiveItem(id)}
        />
      </Tooltip>
    </Stack>
  );
};

export default HideListItem