// MUI
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import { AmpCardTypes } from "../../lib/util/enums";
import AmpCard from "../cards/AmpCard";
import EvidenceMap from "../visualizations/EvidenceMap";
import LineChart from "../visualizations/LineChart";
import SentimentChart from "../visualizations/SentimentChart";
import { ChartDataProps, DateRangeProps } from "../../lib/util/interfaces";

interface Props {
  data?: {
    chartData?: ChartDataProps;
    dateRange?: DateRangeProps;
    LineChartAnalysis: string;
    MapAnalysis: string;
  };
}

const ReportCharts = (props: Props) => {
  if (!props.data) {
    return null;
  }

  return (
    <Box>
      <Grid display="flex" justifyContent={"center"} mt={2}>
        <AmpCard type={AmpCardTypes.chart}>
          <LineChart
            lineChartData={props.data.chartData?.lineChartData}
            dateRange={props.data.dateRange}
          />
        </AmpCard>
      </Grid>
      <Grid display="flex" justifyContent={"center"}>
        <Typography variant="body1">{props.data.LineChartAnalysis}</Typography>
      </Grid>
      <Grid display="flex" justifyContent={"center"} mt={2}>
        <AmpCard type={AmpCardTypes.chart}>
          <EvidenceMap data={props.data.chartData?.mapData} />
        </AmpCard>
      </Grid>
      <Grid display="flex" justifyContent={"center"}>
        <Typography variant="body1">{props.data.MapAnalysis}</Typography>
      </Grid>
      <Grid display="flex" justifyContent={"center"} mt={2}>
        {" "}
        <AmpCard type={AmpCardTypes.chart}>
          <SentimentChart sentimentList={props.data.chartData?.sentimentData} />
        </AmpCard>
      </Grid>
      {/* <Grid display="flex" justifyContent={"center"}>
        <Typography variant="body1">{props.data.chartsAnalysis.SentimentBreakdown}</Typography>
      </Grid> */}
    </Box>
  );
};

export default ReportCharts;
