// Global
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Typography, Stack, useMediaQuery, useTheme } from "@mui/material";

// Local
import AmpButton from "../buttons/AmpButton";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  header: string;
  subheader: string;
  buttonText: string;
}

// Controlled: Display view options on landing page
const ChooseView = ({ header, subheader, buttonText }: Props) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouteOptions.submit);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "primary.main", borderRadius: 1 }}
      mt={2}
      mb={4}
    >
      <Stack display="flex" justifyContent="center" alignItems="center" textAlign={"center"} p={2}>
        <Typography variant="h5">{t("chooseView." + header)}</Typography>
        <Typography variant="h4">{t("chooseView." + subheader)}</Typography>

        <Box display="flex" justifyContent={"center"} pt={isSmallScreen ? 1 : 0} my={1}>
          <AmpButton
            type="accent"
            text={buttonText}
            symbol={header === "Contribute" ? "plus" : "analyze"}
            onClick={handleClick}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ChooseView;
