import { Theme } from "../../models/Theme";
import { labeledObject } from "./interfaces";

// Sort list of objects by "label" property
export const sortByLabel = (list: labeledObject[]) => {
  // Sort object lists
  list?.sort((a, b) => a.label.localeCompare(b.label));
  return list;
};

// Sort list of objects by "Group" property
export const sortThemeByGroup = (list: Theme[]) => {
  // Sort object lists
  list?.sort((a, b) => a.group.localeCompare(b.group));
  return list;
};

// Fisher-Yates (Knuth) shuffle algorithm
export function shuffleArray<T>(array: T[] | undefined): T[] {
  if (!array) {
    return [];
  }
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
