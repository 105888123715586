// Global
import { useState, useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Local
import { ApiTokenContext } from "../contexts/InitContexts";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";
import { Report, ReportData } from "../models/GeneratedReport";
import { generatedReportList } from "../lib/api/keyConstants";
import { dateRangeStringToDayJs } from "../lib/util/dateFormatters";

interface Props {
  report: Report | undefined;
}
const useMutateGeneratedReport = (props: Props) => {
  // Global hooks
  const apiToken = useContext(ApiTokenContext);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting update to database!");
    },
    onSuccess: (data) => {
      console.log("Update successful.");

      // Get data from the API response, set the dates to correct format
      const parsedData = JSON.parse(data.data.response.body.data);
      const reportData = new ReportData(parsedData);

      // Get the current data in the cache for the generatedReportList query
      const currentData: any = queryClient.getQueryData([
        generatedReportList,
        0,
        { token: apiToken, ids: [props.report?.id] },
      ]);

      // Update the data with the mutated report data
      const updatedData = {
        ...currentData,
        reportList: currentData.reportList.map((report: Report) => {
          return report.id === data.data.response.body.id
            ? { ...report, data: reportData }
            : report;
        }),
      };

      // Update the cache with the new data
      queryClient.setQueryData(
        [generatedReportList, 0, { token: apiToken, ids: [props.report?.id] }],
        updatedData
      );
    },
  });

  // Externally-callable function to serve individual updates to a report
  const updateReport = (key: string, value: string, index?: number) => {
    if (!props.report?.id) {
      console.log("Error! No ID");
    }

    let stringData;
    if (index !== undefined) {
      // Update nested item in dataGroups
      stringData = JSON.stringify({
        ...props.report?.data,
        dataGroups: props.report?.data.dataGroups?.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        ),
      });
    } else {
      // Update top-level analysis
      stringData = JSON.stringify({ ...props.report?.data, [key]: value });
    }

    const updatedData = {
      data: stringData,
      id: props.report?.id,
      updatedAt: new Date(),
    };

    mutation.mutate({
      submitType: AmpDataSubmitTypes.GeneratedReport,
      data: updatedData,
      apiToken,
    });
  };

  return { updateReport, isMutating: mutation.isLoading };
};

export default useMutateGeneratedReport;
