import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack, CircularProgress, Alert, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import createPresignedUrl from "../lib/api/createPresignedUrl";
import { ApiTokenContext } from "../contexts/InitContexts";

function BulkUpload() {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const token = useContext(ApiTokenContext);

  // Mutation hook for file upload
  const uploadMutation = useMutation({
    mutationFn: async (files: File[]) => {
      const uploads = files.map(async (file) => {
        const { data } = await createPresignedUrl({
          queryKey: ["createPresignedUrl", { token, key: file.name, isBulkUpload: true }],
        });
        const { presignedUrl, path } = data;

        return axios.put(presignedUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total ?? progressEvent.loaded)
            );
            setUploadProgress((prev) => ({ ...prev, [path]: percentCompleted }));
          },
        });
      });

      await Promise.all(uploads);
      return "Upload successful";
    },
    onSuccess: () => {
      // Clear selected files and reset upload progress after successful upload
      setFiles([]);
      setUploadProgress({});
    },
  });

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);
    setUploadProgress({});
  };

  // Reset selected files
  const handleReset = () => {
    setFiles([]);
    setUploadProgress({});
  };

  // Trigger file upload
  const handleUpload = () => {
    if (files.length > 0) uploadMutation.mutate(files);
  };

  return (
    <Box m={2} display="flex" justifyContent="center" alignItems="center">
      <Stack direction="column" spacing={2} alignItems="center" width="100%">
        <Typography variant="h2">{t("bulkUpload.title", "Bulk Upload")}</Typography>

        <Typography variant="body1" textAlign="center">
          {t(
            "bulkUpload.description",
            "Upload CSV files from NewsWhip and Meltwater. Other formats can be added upon request."
          )}
        </Typography>

        {/* File selection and reset buttons */}
        <Box>
          <input
            accept=".csv"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span">
              {t("bulkUpload.selectFiles", "Select CSV Files")}
            </Button>
          </label>
          {files.length > 0 && (
            <Button
              variant="text"
              color="secondary"
              onClick={handleReset}
              startIcon={<ClearIcon />}
              sx={{ ml: 2 }}
            >
              {t("bulkUpload.reset", "Reset")}
            </Button>
          )}
        </Box>

        {/* Display selected files and upload progress */}
        {files.length > 0 && (
          <Box width="100%" textAlign="center">
            <Typography variant="subtitle1">
              {t("bulkUpload.selectedFiles", "Selected Files:")}
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {files.map((file, index) => (
                <li key={index}>
                  <Typography variant="body2">
                    {file.name}
                    {uploadProgress[`uploads/${file.name}`] !== undefined &&
                      ` - ${uploadProgress[`uploads/${file.name}`]}%`}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        )}

        {/* Upload button */}
        <Box>
          <Tooltip title={t("bulkUpload.uploadTooltip", "Upload files to S3")}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={files.length === 0 || uploadMutation.isLoading}
                startIcon={
                  uploadMutation.isLoading ? <CircularProgress size={20} /> : <CloudUploadIcon />
                }
              >
                {uploadMutation.isLoading
                  ? t("bulkUpload.uploading", "Uploading...")
                  : t("bulkUpload.upload", "Upload")}
              </Button>
            </span>
          </Tooltip>
        </Box>

        {/* Success and error messages */}
        {uploadMutation.isSuccess && (
          <Alert severity="success">
            {t("bulkUpload.success", "Upload successful!")}
          </Alert>
        )}
        {uploadMutation.isError && (
          <Alert severity="error">
            {t("bulkUpload.error", "Upload failed. Please try again.")}
          </Alert>
        )}
      </Stack>
    </Box>
  );
}

export default BulkUpload;
