// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  Stack,
  Link,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import { COLORS } from "../../lib/theme/default";
import { contentSubviews } from "../../lib/util/enums";

interface Props {
  subview: contentSubviews;
  setSubview: (view: contentSubviews) => void;
}

const ContentFilterControls = ({ subview, setSubview }: Props) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Sub-view options as an array
  const contentSubviewsArray = Object.keys(contentSubviews).filter((key) =>
    isNaN(Number(key))
  );

  // Submenu component
  const subviews = () => {
    return (
      <>
        {contentSubviewsArray.map((item, index) => (
          <Stack
            direction="row"
            key={index}
            display="flex-inline"
            alignItems={"center"}
          >
            <Typography
              variant="h4"
              sx={{
                color: item === subview ? COLORS.primaryBlue : "auto",
                cursor: "pointer",
              }}
              onClick={() => setSubview(item as contentSubviews)}
            >
              {t(
                isSmallScreen
                  ? "contentManager.menu.mobile." + item
                  : "contentManager.menu." + item
              )}
            </Typography>
            {index < contentSubviewsArray.length - 1 ? (
              <Typography
                ml={2}
                mr={2}
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 400,
                  color: COLORS.secondaryGray,
                  opacity: "40%",
                }}
              >
                |
              </Typography>
            ) : null}
          </Stack>
        ))}
      </>
    );
  };

  return (
    <>
      {/* Mobile */}
      {isSmallScreen && (
        <Grid xs={5} container display="flex" justifyContent="flex-end">
          {subviews()}
        </Grid>
      )}

      {/* Desktop */}
      {!isSmallScreen && (
        <Grid md={8} display="flex" justifyContent={"end"}>
          {subviews()}
        </Grid>
      )}
    </>
  );
};

export default ContentFilterControls;
