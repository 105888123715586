import { Box, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "../../lib/theme/default";

interface CustomTypographyProps extends TypographyProps {
  children: React.ReactNode;
}

const CodeTypography: React.FC<CustomTypographyProps> = ({ children, ...props }) => (
  <Box my={1}>
    <Typography
      variant="caption"
      mt={1}
      sx={{ backgroundColor: COLORS.backgroundGray }}
      p={0.5}
      {...props}
    >
      {children}
    </Typography>
  </Box>
);

export default CodeTypography;
