// Globals
import { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

// Locals
import useAxios from "./useAxios";
import { generatedReportList } from "../lib/api/keyConstants";
import { ApiTokenContext } from "../contexts/InitContexts";
import { generatedReportsPerPage, shortStaleTime } from "../lib/configs";
import { Report } from "../models/GeneratedReport";

interface QueryProps {
  queryKey: [string, number, { token: string; ids?: string[] }];
}

// Business logic for getContent from server
const getGeneratedReport = async ({ queryKey }: QueryProps) => {
  const [_key, page, { token, ids }] = queryKey;

  // Use evidence attributes as params for /getContent endpoint
  let params = {
    page: page,
    pageSize: generatedReportsPerPage,
    ids: JSON.stringify(ids),
  };

  const response = await useAxios({
    endpoint: "getReport",
    token: token,
    params: params,
  });

  const hits = response?.data.response.body.hits.hits;
  const total: number = response?.data.response.body.hits.total.value;
  const reportList: Report[] = hits.map((hit: any) => new Report(hit));
  return { reportList, total };
};

// Handle report in response workflow
const useGetReport = () => {
  // Global
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id"); // Check if id is present

  // Local
  const [page, setPage] = useState<number>(0);
  const token = useContext(ApiTokenContext);

  // Query
  const { isLoading, error, data } = useQuery({
    queryKey: [generatedReportList, page, { token, ids: id?.split(",") }],
    queryFn: getGeneratedReport,
    keepPreviousData: true,
    // staleTime: shortStaleTime,
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading) {
    return { isLoading };
  }

  if (error instanceof Error) {
    // TODO: Update this to handle missing report gracefully
    return { errorMessage: "NoContent" };
  }

  // If multiple reports match, return list
  if (data?.reportList && data.reportList.length === 1 && id) {
    // If only one report matches, return it
    return { report: data?.reportList[0] };
  }
  return { reportList: data?.reportList };
};

export default useGetReport;
