// MUI
import { useTheme, useMediaQuery, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import useAggregateThemes from "../../hooks/useAggregateThemes";
import CircleLoading from "../navigation/CircleLoading";
import { UseAggregateThemesProps, labeledObject } from "../../lib/util/interfaces";
import AmpCard from "../cards/AmpCard";
import { AmpCardTypes } from "../../lib/util/enums";
import ThemeOrIssuesCard from "../cards/ThemeOrIssuesCard";

interface Props extends UseAggregateThemesProps {
  trendingPeriod: number;
}

const TrendingThemes = (props: Props) => {
  // Global
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const { themeList, isLoading } = useAggregateThemes({
    evidenceFilters: props.evidenceFilters,
    isIssuesAggregation: props.isIssuesAggregation,
  });

  return isLoading ? (
    <CircleLoading />
  ) : (
    <Grid container width={'100%'}> 
      {themeList &&
        themeList.map((theme: labeledObject) => (
          <Grid key={theme.id} xs={12} md={6} lg={4}>
            <Box mx={isSmallScreen ? 0 : 1}>
              <AmpCard type={AmpCardTypes.theme}>
                <ThemeOrIssuesCard
                  data={theme}
                  baseFilters={props.evidenceFilters}
                  isIssuesAggregation={props.isIssuesAggregation}
                  trendingPeriod={props.trendingPeriod}
                />
              </AmpCard>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default TrendingThemes;
