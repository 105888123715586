// Global
import { ReactNode, forwardRef } from "react";

// MUI
import { Box, Card, useMediaQuery, useTheme } from "@mui/material";

// Local
import { COLORS, cardMaxWidth, chartWidth, stripeWidth } from "../../lib/theme/default";
import { AmpCardTypes } from "../../lib/util/enums";

export interface AmpCardProps {
  type: AmpCardTypes;

  children?: ReactNode;
}

// Container for cards on the AMP main stage
const AmpCard = forwardRef(({ type, children }: AmpCardProps, ref) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Color coded by data type
  const marginColor = () => {
    if (type === AmpCardTypes.evidence) {
      return COLORS.primaryBlue;
    } else if (type === AmpCardTypes.theme) {
      return COLORS.airaPink;
    } else {
      return COLORS.nearBlack;
    }
  };

  const maxContentWidth: string =
    type !== AmpCardTypes.chart ? `calc(100% - ${stripeWidth}px - ${theme.spacing(2)})` : `100%`;

  return (
    <Card
      sx={{
        // Three sizes: Mobile, Full, Chart
        width: isSmallScreen ? "100%" : type === AmpCardTypes.chart ? chartWidth : cardMaxWidth,
        maxWidth: isSmallScreen ? "100%" : type === AmpCardTypes.chart ? chartWidth : "100%",
        mb: 2,
        pageBreakInside: "avoid",
      }}
    >
      <Box display="flex" ref={ref}>
        {/* Color-coded stripe by type */}
        {type !== AmpCardTypes.chart && (
          <Box
            flexShrink={0}
            width={stripeWidth.toString() + "px"}
            sx={{ backgroundColor: marginColor }}
          />
        )}
        <Box flexGrow={1} p={1} sx={{ maxWidth: maxContentWidth }}>
          {children}
        </Box>
      </Box>
    </Card>
  );
});

export default AmpCard;
