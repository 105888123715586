import { EvidenceAggregatedByLocation } from "../../models/Evidence";
import { DataIntensity } from "./enums";

// Calculate standard deviation for data objects
// Return intensity high, low or undefined on each object
const calcMarkerIntensity = (data: EvidenceAggregatedByLocation[]) => {
  // Calculate the AVERAGE value
  const sum = data.reduce((accumulator, obj) => accumulator + obj.count, 0);
  const average = sum / data.length;

  // Get the STND DEVIATION
  const squaredDifferences = data.map((obj) =>
    Math.pow(obj.count - average, 2)
  );
  const sumOfSquaredDifferences = squaredDifferences.reduce(
    (accumulator, value) => accumulator + value,
    0
  );
  const variance = sumOfSquaredDifferences / data.length;
  const standardDeviation = Math.sqrt(variance);

  // Data higher/lower than standard deviation marked
  // Using "intensity" property
  const deviatedObjects = data.map((obj) => {
    const isHigh = obj.count > average + standardDeviation;
    const isLow = obj.count < average - standardDeviation;
    return {
      ...obj,
      intensity: isHigh
        ? DataIntensity.High
        : isLow
        ? DataIntensity.Low
        : undefined,
    };
  });

  return deviatedObjects;
};

export default calcMarkerIntensity;
