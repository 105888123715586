// Global
import { useContext } from "react";

// MUI
import { Box, Chip } from "@mui/material";

// Local
import { idLookups } from "../../lib/util/idLookups";
import { COLORS } from "../../lib/theme/default";
import { IssuesContext } from "../../contexts/InitContexts";
import useEditEvidence from "../../hooks/useEditEvidence";
import EditButton from "../buttons/EditButton";
import { getChipStyles } from "../../lib/theme/customStyles";

interface Props {
  ids: string[] | undefined;
  isQA?: boolean;
  evidenceId?: string;
}

// Return a pill-list of Issues
const IssuePills = ({ ids, isQA, evidenceId }: Props) => {
  // Local hooks
  const { values, isEditting, handleEdit, editView, isLoading } = useEditEvidence({
    type: "issueIds",
    ids,
    evidenceId: evidenceId || "",
  });

  const issuesData = useContext(IssuesContext);
  const issues = ids
    ? idLookups({
        data: issuesData,
        ids: values || [],
      })
    : null;

  return isLoading ? (
    isLoading
  ) : isEditting ? (
    editView
  ) : (
    <Box>
      {issues &&
        issues.map((issue, index) => (
          <Box key={issue.id} display="inline" mr={0.5}>
            <Chip variant="outlined" label={issue.label} sx={getChipStyles("grayStatic")} />
          </Box>
        ))}

      {isQA && <EditButton handleEdit={handleEdit} type="Issues" />}
    </Box>
  );
};

export default IssuePills;
