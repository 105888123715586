// MUI
import { Stack, Box, Link, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// Local
import { smallFontSize } from "../../lib/theme/default";

interface Props {
  attachment: File;
  attachmentIndex: number;
  handleRemove: (index: number) => void;
}

const AttachedFile = ({
  attachment,
  attachmentIndex,
  handleRemove,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Box>
        <UploadFileIcon sx={{ marginRight: 0.5 }} />
      </Box>
      <Box>
        <Typography fontSize={smallFontSize}>
          {attachment.name} [
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => handleRemove(attachmentIndex)}
            display="inline"
          >
            x
          </Link>
          ]
        </Typography>
      </Box>
    </Stack>
  );
};

export default AttachedFile;
