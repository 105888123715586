// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Stack, Typography, TypographyProps, TypographyVariant } from "@mui/material";

// Local
import EditButton from "../buttons/EditButton";
import DotSpacer from "../cards/DotSpacer";
import useEditReport from "../../hooks/useEditReport";
import { getBodyFontSize } from "../../lib/util/formatters";
import { BodyFontSize } from "../../lib/util/enums";
import { COLORS } from "../../lib/theme/default";

interface Props {
  text: string;
  typographyVariant?: TypographyVariant;
  isTranslationKey?: boolean;
  bodyFontSize?: BodyFontSize;
  isEditable?: boolean;
  hasBulletPoint?: boolean;
  name?: string;
  index?: number;
  updateText: (name: string, text: string, index?: number) => void;
}

const EditableText = ({
  text,
  typographyVariant = "body1",
  bodyFontSize = BodyFontSize.Auto,
  isTranslationKey,
  isEditable = true,
  hasBulletPoint,
  name = "",
  index,
  updateText,
}: Props) => {
  if (!text) {
    return null;
  }

  // Global
  const { t } = useTranslation();

  // Local
  const handleUpdateText = (updatedText: string) => {
    updateText(name, updatedText, index);
  };

  // Hook for editing data
  const { editBar, handleEditor } = useEditReport({ text, handleUpdateText });
  const actualFontSize = getBodyFontSize(bodyFontSize);

  // Determine if text string or translation key
  const formattedText =
    isTranslationKey && text ? (
      <Typography variant={typographyVariant} fontSize={actualFontSize} color={COLORS.primaryBlue}>
        {t(text)}
      </Typography>
    ) : (
      <Typography variant={typographyVariant} fontSize={actualFontSize}>
        {text}
      </Typography>
    );

  return (
    editBar || (
      <Stack direction="row" alignItems={"center"}>
        {hasBulletPoint && <DotSpacer wide isBullet />}
        {formattedText}
        {isEditable && <EditButton handleEdit={handleEditor} type="Text" />}
      </Stack>
    )
  );
};

export default EditableText;
