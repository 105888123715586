// Return the subdomain
// e.g., "example" in https://example.rootwise.co
function getApiEnv(url: string): string {
  if (url.includes("localhost")) {
    // Matched on localhost
    return "dev";
  }

  const subdomainRegex = /^(?:https?:\/\/)?([^\/]+)/i;
  const matches = url.match(subdomainRegex);

  if (matches && matches.length > 1) {
    const subdomain = matches[1].split(".")[0];

    // This should just be set an environment variable
    // Always default to dev
    switch (subdomain) {
      case "test":
        return "staging";
      case "amp":
        return "prod";
      default:
        return "dev";
    }
  } else throw new Error("No subdomain found!");
}

export default getApiEnv;
