// Global
import { useTranslation } from "react-i18next";

// MUI
import AmpButton from "../buttons/AmpButton";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import { Box } from "@mui/material";

const DateSort = ({
  evidenceFilters,
  updateEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global
  const { t } = useTranslation();

  const toggleSort = () => {
    updateEvidenceFilters({ sortDesc: !evidenceFilters.sortDesc });
  };

  return (
    <Box width="135px" display={"flex"} justifyContent={"flex-end"}>
      <AmpButton
        text={
          evidenceFilters.sortDesc ? t("sortBy.Newest") : t("sortBy.Oldest")
        }
        type="text"
        symbol="arrowDown"
        onClick={toggleSort}
      />
    </Box>
  );
};

export default DateSort;
