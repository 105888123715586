// Global
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";

// MUI Formik
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { Box, Stack, Typography } from "@mui/material";

// Local
import AmpLogo from "../components/landingPage/AmpLogo";
import AmpButton from "../components/buttons/AmpButton";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";
import { ApiTokenContext } from "../contexts/InitContexts";

const UserRegistration = () => {
  const { t } = useTranslation();


  const [hasSubmitted, setHasSubmitted] = useState(false);

  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting update to database!");
    },
    onSuccess: () => {
      console.log("Submit successful.");
    },
  });

  return (
    <Box width={"100vw"} display="flex" justifyContent="center">
      <Box>
        <Box display="flex" justifyContent="center" my={4}>
          <AmpLogo />
        </Box>
        <Typography variant="h4" gutterBottom>
          {t("registration.title")}
        </Typography>

        {hasSubmitted ? (
          <SuccessMessage />
        ) : (
          <NeedsRegistration mutation={mutation} setHasSubmitted={setHasSubmitted} />
        )}
      </Box>
    </Box>
  );
};

export default UserRegistration;

const SuccessMessage = () => {
  const { t } = useTranslation();

  return (
    <Box my={2}>
      <Typography variant="h4" gutterBottom>
        {t("registration.success.title")}
      </Typography>
    </Box>
  );
};

interface Props {
  setHasSubmitted: (value: boolean) => void;
  mutation: any;
}

const NeedsRegistration = (props: Props) => {
  const { t } = useTranslation();
  const apiToken = useContext(ApiTokenContext);

  const [allowRegistration, setAllowRegistration] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email("invalidEmail").required("required"),
    role: Yup.string(),
    organization: Yup.string(),
    country: Yup.string(),
    reason: Yup.string().required("required"),
  });

  const handleFormSubmit = (values: any) => {
    console.log("Form Data:", values);
    props.mutation.mutate({
      submitType: AmpDataSubmitTypes.UserRegistration,
      data: values,
      apiToken,
    });
    props.setHasSubmitted(true);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        organization: "",
        reason: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, dirty, submitForm }) => (
        <Form>
          <Stack direction="column" spacing={2}>
            <Field
              component={TextField}
              name="email"
              type="email"
              label={t("registration.email")}
              fullWidth
              required
            />
            <Field
              component={TextField}
              name="role"
              label={t("registration.role")}
              fullWidth
            />
            <Field
              component={TextField}
              name="organization"
              label={t("registration.organization")}
              fullWidth
            />
            <Field
              component={TextField}
              name="country"
              label={t("registration.country")}
              fullWidth
            />

            <Field
              component={TextField}
              name="reason"
              label={t("registration.why")}
              multiline
              rows={2}
              fullWidth
              required
            />
            <ReCAPTCHA
              sitekey={import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) =>
                value ? setAllowRegistration(true) : setAllowRegistration(false)
              }
            />
            <AmpButton
              text={t("requestAccount")}
              disabled={!(isValid && dirty) || !allowRegistration}
              onClick={submitForm}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
