// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

// Local
import useQueryInvalidation from "../../hooks/useQueryInvalidation";
import { COLORS } from "../../lib/theme/default";
import { content, evidence } from "../../lib/api/keyConstants";
import { AmpDataSubmitTypes } from "../../lib/util/enums";

interface Props {
  count: string | undefined;
  dataType?: AmpDataSubmitTypes;
}

const DisplayResultCount = ({ count, dataType }: Props) => {
  // Global
  const { t } = useTranslation();

  // Refresh data
  const { invalidate } = useQueryInvalidation(
    dataType === AmpDataSubmitTypes.Content ? content : evidence
  );

  return !count && count !== "0" ? null : (
    <Box>
      <Stack
        mb={1}
        direction="row"
        display="flex"
        alignItems="stretch"
        justifyContent={"space-between"}
      >
        <Box display="flex" alignItems={"end"}>
          <Typography variant="h4">
            {t("ResultCount")}
            {count}
          </Typography>
        </Box>
        <Box display="flex" alignItems={"baseline"}>
          <Tooltip title={t("Refresh")}>
            <RefreshIcon style={{ color: COLORS.secondaryGray }} onClick={invalidate} />
          </Tooltip>
        </Box>
      </Stack>
      {count === "0" && (
        <Box>
          <Typography variant="h2" my={2}>
            {t("NoResults1")}
          </Typography>
          <Typography variant="h3" my={2}>
            {t("NoResults2")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DisplayResultCount;
