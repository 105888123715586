import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

// MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import AmpButton from "./buttons/AmpButton";
import GenerateReportPrompt from "./report/GenerateReportPrompt";
import CircleLoading from "./navigation/CircleLoading";
import { Box } from "@mui/material";
import ActiveFiltersView from "./filters/ActiveFiltersView";
import { EvidenceFilters } from "../models/EvidenceFilters";

// Local imports

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  children?: ReactNode;
  title: string;
  message: string;
  message2?: string;
  confirm?: string;
  isConfirmDisabled?: boolean;
  isConfirmHidden?: boolean;
  cancel?: string;
  showCancel?: boolean;
  isMultipleChoice?: boolean;
  isOpen: boolean;
  selected?: string;
  isDisableBackgroundClick?: boolean;
  isLoading?: boolean;
  evidenceFilters?: EvidenceFilters;
  handleUserChoice: (userChoice: string) => void;
  setSelected?: (selected: string) => void;
};

// A prop to display an alert dialog
// Must pass title and messsage of alert
// Defaults to Yes/No buttons but Props can override
export default function AlertDialogSlide({
  children,
  title,
  message,
  message2,
  confirm,
  isConfirmDisabled,
  isConfirmHidden,
  cancel,
  showCancel,
  isOpen,
  isMultipleChoice,
  selected,
  isDisableBackgroundClick,
  isLoading,
  evidenceFilters,
  handleUserChoice,
  setSelected,
}: Props) {
  const { t } = useTranslation();
  // Translation key builder
  const alertTitle = "alertSlideDialog." + title;
  const alertMessage = "alertSlideDialog." + message;
  const alertMessage2 = message2 ? "alertSlideDialog." + message2 : "";

  // Button defaults
  const confirmButton = confirm || "Yes";
  const cancelButton = cancel || "No";

  const handleCloseEvent = (_: any, reason: string) => {
    if (reason === "backdropClick" && isDisableBackgroundClick) {
      return;
    }
    handleUserChoice("cancel");
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseEvent}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle variant="h2">{t(alertTitle)}</DialogTitle>
      <DialogContent>
        {isLoading && (
          <Box my={4} display="flex" alignItems={"center"}>
            <CircleLoading />
          </Box>
        )}

        <DialogContentText id="alert-dialog-slide-description">{t(alertMessage)}</DialogContentText>
        <DialogContentText id="alert-dialog-slide-description" mt={1}>
          {alertMessage2 && t(alertMessage2)}
        </DialogContentText>

        {isMultipleChoice && setSelected && (
          <GenerateReportPrompt selected={selected} setSelected={setSelected} />
        )}

        {children}
        {evidenceFilters && (
          <ActiveFiltersView
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={() => {}}
            isLandingPage
          />
        )}
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <AmpButton
            type="secondary"
            onClick={() => handleUserChoice("cancel")}
            text={cancelButton}
          />
        )}
        {!isConfirmHidden && (
          <AmpButton
            onClick={() => handleUserChoice("confirm")}
            text={confirmButton}
            disabled={isConfirmDisabled}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
