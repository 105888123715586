// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Local
import AmpButton from "../buttons/AmpButton";
import { Evidence } from "../../models/Evidence";
import { getUtcDateLocalized } from "../../lib/util/dateFormatters";
import DotSpacer from "./DotSpacer";
import ThemeList from "./ThemeList";
import IssuePills from "./IssuePills";
import EvidenceAttachments from "./EvidenceAttachments";
import TranslateButton from "./TranslateButton";
import LocationsList from "./SourceCountry";
import useEvidenceText from "../../hooks/useEvidenceText";
import LinkIcon from "./LinkIcon";
import { COLORS, smallFontSize } from "../../lib/theme/default";
import AmpDrawer from "../drawers/AmpDrawer";
import EvidenceDrawer from "../drawers/evidence/EvidenceDrawer";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import { AmpCardTypes, AmpDrawerState } from "../../lib/util/enums";
import FlagForReview from "./FlagForReview";
// import SaveToCollection from "./SaveToCollection";
import DisplayInteractions from "./DisplayInteractions";
import SentimentIcon from "./SentimentIcon";
import { truncateText } from "../../lib/util/formatters";
import { Platforms } from "../../models/Platform";
import MisinformationIcon from "./MisinformationIcon";

interface Props {
  evidence: Evidence;
  isDetailView?: boolean;
  isQA?: boolean;
  isExample?: boolean;
}

const EvidenceCard = ({ evidence, isDetailView, isQA, isExample }: Props) => {
  // Global Hooks
  const { t, i18n } = useTranslation();
  const userLang = i18n.language;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")) || isDetailView;

  // Local hooks
  const { text, translationState, handleTranslationToggle } = useEvidenceText({
    evidence,
    userLang,
    isDetailView,
    isExample,
  });
  const { drawerState, setDrawerState } = useAmpDrawer();

  // Format values

  // Get parsed platformName, if not, undefined
  const platformName = Platforms[evidence.platform as keyof typeof Platforms]?.name
    ? Platforms[evidence.platform as keyof typeof Platforms].name
    : undefined;

  // Pull platformIcon from parsed name. If not, just display raw name (user defined /error)
  const platformIcon = (
    <Tooltip title={platformName ? t("platforms." + platformName) : evidence.platform}>
      <Stack display="flex" alignItems="center" direction="row">
        {Platforms[evidence.platform as keyof typeof Platforms]?.icon && (
          <img
            src={"/icons/" + Platforms[evidence.platform as keyof typeof Platforms].icon}
            style={{ maxWidth: "30px", maxHeight: "30px", marginRight: "8px" }}
          />
        )}
        <Typography variant="h3" color={COLORS.primaryBlue}>
          {platformName ? t("platforms." + platformName) : evidence.platform}
        </Typography>
        {!isSmallScreen ? <DotSpacer wide /> : null}
      </Stack>
    </Tooltip>
  );

  const localDate = getUtcDateLocalized({ date: evidence.date, userLang });
  const originalPost = truncateText({
    isNotTruncated: isDetailView,
    isExtraTruncated: isExample,
    text: evidence.originalPost,
  });

  // Calculate hasAttachments boolean
  const hasAttachments = evidence?.attachments && evidence.attachments.length > 0;

  return (
    <Box>
      <Stack alignItems="stretch">
        {/* Card Header */}
        <Grid container alignItems={"center"}>
          <Grid md="auto">
            {/* Platform */}
            <Stack direction="row" display="flex" alignItems={"center"}>
              {platformIcon}
              {!isSmallScreen && (
                <>
                  {/* Date and Interactions (desktop only) */}
                  <Typography variant="h4">{localDate}</Typography>
                  <MisinformationIcon value={evidence.isMisinformation} />
                  <SentimentIcon id={evidence.id} sentiment={evidence.sentiment} isQA={isQA} />
                  <DisplayInteractions count={evidence.interactionCount} />
                  <DisplayInteractions isViewCount count={evidence.viewCount} />
                </>
              )}
            </Stack>
          </Grid>
          <Grid md="auto" xsOffset={"auto"}>
            <Stack direction="row" spacing={0.5}>
              {/* Upper-right Icon Array */}
              <TranslateButton
                translationState={translationState}
                handleTranslationToggle={handleTranslationToggle}
              />
              <LinkIcon url={evidence.url} />
              {!isExample && <FlagForReview type={AmpCardTypes.evidence} evidence={evidence} />}
              {/* {!isExample && <SaveToCollection type={AmpCardTypes.evidence} id={evidence.id} />} */}
            </Stack>
          </Grid>
          {isSmallScreen && (
            <Grid xs={12} mt={1}>
              {/* Date and Interactions (mobile only) */}
              <Stack direction="row" display="flex" alignItems={"center"}>
                <Typography variant="h4" display="inline">
                  {localDate}
                </Typography>
                <MisinformationIcon value={evidence.isMisinformation} />
                <SentimentIcon sentiment={evidence.sentiment} id={evidence.id} isQA={isQA} />
                <DisplayInteractions count={evidence.interactionCount} />
                <DisplayInteractions isViewCount count={evidence.viewCount} />
              </Stack>
            </Grid>
          )}
          {/* Themes */}
          {!isExample && (
            <Grid xs={12} mt={1}>
              <ThemeList ids={evidence.themeIds} isQA={isQA} evidenceId={evidence.id} />
            </Grid>
          )}
        </Grid>

        {/* Card Body */}
        <Grid container mt={1}>
          {hasAttachments && (
            <Grid
              xs={isExample ? 4 : 12}
              md={isDetailView ? 12 : 4}
              pb={isSmallScreen ? 1 : "auto"}
            >
              <EvidenceAttachments attachments={evidence.attachments} />
            </Grid>
          )}
          {/* Evidence text, original post, and source country */}
          <Grid
            xs={hasAttachments && isExample ? 8 : 12}
            md={hasAttachments && !isDetailView ? 8 : 12}
            pl={isSmallScreen ? "auto" : 3}
            pr={isSmallScreen ? "auto" : 3}
          >
            <Box sx={{ overflowWrap: "break-word", wordWrap: "break-word" }}>
              {evidence?.originalPost && (
                <Box sx={{ backgroundColor: COLORS.backgroundGray }} p={1} mb={1}>
                  <Typography variant={"h4"} fontSize={smallFontSize}>
                    {t("cards.OriginalPost")}
                  </Typography>

                  <Typography variant="body2" mb={0.5}>
                    {originalPost}
                  </Typography>
                </Box>
              )}
              <Typography variant="body1">{text}</Typography>
            </Box>
            <Grid container xs={12} mt={1} direction="row" justifyContent={"space-between"}>
              <Grid xs={12}>
                <LocationsList
                  locations={evidence.locations}
                  isQA={isQA}
                  evidenceId={evidence.id}
                />
              </Grid>
              {isExample && (
                <Grid xs={6}>
                  <IssuePills ids={evidence.issueIds} isQA={isQA} evidenceId={evidence.id} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Card Footer */}
        {/* Issues */}
        <Grid container alignItems="end" mt={1}>
          {!isExample && (
            <Grid xs={12} md={isDetailView ? 12 : 7}>
              <IssuePills ids={evidence.issueIds} isQA={isQA} evidenceId={evidence.id} />
            </Grid>
          )}

          {/* Buttons for See Details, See Responses */}
          {!isDetailView && !isExample && (
            <Grid xs={12} md={5} mt={1}>
              <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                <AmpButton
                  text={t("Evidence.Details")}
                  type="secondary"
                  onClick={() => setDrawerState(AmpDrawerState.EvidenceDetails)}
                />
                <AmpButton
                  text={t("Evidence.Response")}
                  onClick={() => setDrawerState(AmpDrawerState.Responses)}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>

      {/* Toggle components */}
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <EvidenceDrawer initialOpenTab={drawerState} evidence={evidence} isQA={isQA} />
        </AmpDrawer>
      )}
    </Box>
  );
};

export default EvidenceCard;
