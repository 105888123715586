// Global
import { useState } from "react";

// Store custom error messages in state to trigger user alerts
const useCustomError = () => {
  const [customError, setCustomError] = useState<{ message: string; statusCode?: number }>();
  return { customError, setCustomError };
};

export default useCustomError;
