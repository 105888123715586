// Global
import { useState } from "react";

// MUI
import { Box, Popover, Typography } from "@mui/material";
import { labeledObject } from "../../lib/util/interfaces";
import DotSpacer from "./DotSpacer";

interface Props {
  theme: labeledObject;
  index: number;
  length: number;
}

// ThemeListItem is a functional component that displays a theme item in a list.
// It uses a popover to show more details about the theme when clicked.
const ThemeListItem = ({ theme, index, length }: Props) => {
  // Local hooks
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <Box key={index} display="inline">
      <Typography
        variant={"h6"}
        display="inline"
        key={theme.id}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        {theme.label}
      </Typography>
      {index === length - 1 ? null : <DotSpacer />}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box m={1} maxWidth={450}>
          <Typography variant={"h3"} mb={1}>
            {theme.label}
          </Typography>
          <Typography>{theme.description}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default ThemeListItem;
