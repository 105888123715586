// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import { ampLogoSize } from "../../lib/theme/default";

export default function AmpLogo() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const objectSize = isSmallScreen ? ampLogoSize.sm : ampLogoSize.md;
  return (
    <Box >
        <img src="/icons/AmpLogo.svg" alt="AMP" height={objectSize} />
    </Box>
  );
}
 