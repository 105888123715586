// Global
import { User } from "@auth0/auth0-react";

// Local
import { getUtcDateLocalized } from "../lib/util/dateFormatters";
import { generateUniqueId } from "../lib/util/generators";
import { Evidence } from "./Evidence";
import { AddEvidenceForm } from "../lib/addEvidenceForms/AddEvidenceForm";

export enum EmailTypes {
  FlagForReview = "flagForReview",
  NeedsDetail = "needsDetail",
  ReviewComplete = "reviewComplete",
}

// Create a data object which holds metadata and user feedback
// This data is passed to /sendEmail endpoint on user Submit
export class FlagForReviewData {
  emailType?: string;
  case?: string;
  dataType: string;
  opensearchId?: string;
  data: string;
  date: string;
  link?: string;
  userUrl: string;
  description?: string;
  user?: string;
  authorId?: string;

  constructor(
    dataType: string,
    evidence?: Evidence | AddEvidenceForm,
    userLang?: string,
    user?: User
  ) {
    (this.case = evidence && !(evidence instanceof Evidence) ? undefined : generateUniqueId(4)),
      (this.dataType = dataType),
      (this.opensearchId = evidence?.id),
      (this.data = JSON.stringify(evidence)),
      (this.date = getUtcDateLocalized({
        date: new Date(),
        userLang: userLang || "en",
      }).toString()),
      (this.userUrl = window.location.href),
      (this.user = user?.name),
      (this.authorId = evidence?.authorId);
  }
}
