import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";

// Local
import { logoMaxHeight, logoMaxWidth } from "../../lib/theme/default";
import useAxios from "../../hooks/useAxios";
import { ApiTokenContext } from "../../contexts/InitContexts";
import CircleLoading from "../navigation/CircleLoading";

interface Props {}

// This function displays the menu to handle (future) user account settings and logout
export default function LogoMenu(props: Props) {
  // Global
  const { t } = useTranslation();
  const token = useContext(ApiTokenContext);

  // Logo management
  const defaultLogoPath = "/icons/AmpLogo.svg";
  const [logoOptions, setLogoOptions] = useState<string[]>([]);
  const [currentLogoPath, setCurrentLogoPath] = useState<string>(defaultLogoPath);
  const [isFetching, setIsFetching] = useState(false);

  // Visual depiction of the logo
  const currentLogo = (
    <img
      src={currentLogoPath}
      alt="Logo"
      style={{ maxWidth: logoMaxWidth, maxHeight: logoMaxHeight }}
    />
  );

  // Get logo options
  useEffect(() => {
    const getLogoOptions = async () => {
      const results = await useAxios({ token, endpoint: "/getReportLogos" });
      if ((results.status = 200)) {
        const list = results.data.response;
        list.push("AMP Logo"); // Always include AMP default logo
        list.sort(); // Alphabetize
        setLogoOptions(list);
      }
    };
    getLogoOptions();
  }, []);

  // Change logo on user click
  const chooseLogo = async (logoName: string) => {
    setIsFetching(true);
    const cleanedName = logoName.replace(/ /g, "+");
    const logoPath = "https://amp-report-logos.s3.amazonaws.com/" + cleanedName;
    if (logoName !== "AMP Logo") {
      try {
        const response = await fetch(logoPath);
        if (response.ok) {
          setCurrentLogoPath(logoPath);
          return;
        }
      } catch (error: any) {
        console.log(`Error fetching logo: ${JSON.stringify(error.message)}`);
      } finally {
        setIsFetching(false); // Set loading status to false when fetch completes or fails
        setIsFetching(false);
      }
    }
    // Fallback to default logo
    setCurrentLogoPath(defaultLogoPath);
    setIsFetching(false);
  };

  // Menu anchor state management
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return isFetching ? (
    <Box height={logoMaxHeight}>
      {" "}
      <CircleLoading />
    </Box>
  ) : (
    <>
      <Tooltip title={t("generatedReport.tooltip.ChooseLogo")}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleClick}
          aria-controls={open ? "user-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            cursor: "pointer", // Change the cursor to indicate a clickable area
            "&:hover": {
              borderColor: "primary.main",
              borderWidth: 1.5,
              borderStyle: "dashed",
              opacity: "20%",
            },
          }}
        >
          {currentLogo}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* Menu Items */}

        {logoOptions.map((logo) => (
          <MenuItem
            key={logo}
            onClick={() => {
              chooseLogo(logo);
              handleClose();
            }}
          >
            {/* Display File name without extension */}
            {logo.split(".")[0]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
