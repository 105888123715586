// Content language options
export enum ContentLanguages {
  English = "en",
  French = "fr",
}

// This is the base data provided to each input step
// Master form data object
export class AddContentFormData {
  id: string;
  authorId?: string;
  createdAt?: Date;
  updatedAt?: Date;

  // Field names for input
  contentName?: string;
  contentCode?: string;
  contentDescription?: string;
  contentBinaryPath?: string;
  contentLanguage?: string;

  // Field names for opensearch
  code?: string;
  language?: string;
  issueIds?: string[];
  themeIds?: string[];
  nameTranslated?: {
    en?: string;
    fr?: string;
  };
  descriptionTranslated?: {
    en?: string;
    fr?: string;
  };

  binaryPath?: string;
  binaryType?: string;

  constructor(id: string) {
    this.id = id;
  }
}

// This is the Content model for useContent
export class Content {
  readonly id: string;
  readonly active: boolean;
  readonly authorId?: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly descriptionTranslated?: {
    en?: string;
    fr?: string;
  };
  readonly nameTranslated?: {
    en?: string;
    fr?: string;
  };
  readonly code?: string;
  readonly language?: string;
  issueIds?: string[];
  themeIds?: string[];
  readonly binaryPath?: string;

  constructor(data: any) {
    const source = data._source;
    this.id = data._id;
    this.active = source.active === false ? false : true;
    this.createdAt = new Date(source.createdAt);
    this.updatedAt = new Date(source.updatedAt);

    if (source) {
      this.authorId = source.authorId;
      this.nameTranslated = source.nameTranslated;
      this.descriptionTranslated = source.descriptionTranslated;
      this.code = source.code;
      this.language = source.language;
      this.issueIds = source.issueIds;
      this.themeIds = source.themeIds;
      this.binaryPath = source.binaryPath;
    }
  }
}
