// MUI
import { Stack, useMediaQuery, useTheme } from "@mui/material";

// Local
import ChooseView from "../components/navigation/ChooseView";
import Trends from "../components/landingPage/Trends";
import Footer from "../components/landingPage/Footer";
import MainStage from "../components/navigation/MainStage";
import { appMaxWidth, appMobileWidth } from "../lib/theme/default";

// This view shows the post-auth landing page
// Landing page layout: Add Signals, Trends, Footer
const LandingPage = () => {
  // Global
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <MainStage>
      <Stack height={isSmallScreen ? "auto" : "100%"} maxWidth={appMaxWidth} width={appMobileWidth}>
        <ChooseView header="Contribute" subheader="SubmitSignals" buttonText="AddSignals" />
        <Trends />
        <Footer />
      </Stack>
    </MainStage>
  );
};

export default LandingPage;
