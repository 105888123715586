// Global
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import {
  COLORS,
  appMobileWidth,
  appMaxWidth,
  subNavHeight,
} from "../../lib/theme/default";
import { RouteOptions } from "../../lib/util/enums";

interface SubNavProps {
  view: RouteOptions;
  children: React.ReactNode;
}

// Component displays the SubNavigation
// Space for children with search and filter components

const SubNav = React.forwardRef<HTMLDivElement, SubNavProps>((props, ref) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isQA = props.view === RouteOptions.QA;

  return (
    <Box
      sx={{
        backgroundColor: isSmallScreen ? COLORS.backgroundGray : COLORS.white,
        display: "flex",
        justifyContent: "center",
        marginTop: "1px",
        minHeight: isSmallScreen ? subNavHeight.sm : subNavHeight.md,
      }}
      ref={ref}
    >
      <Grid
        container
        mt={isSmallScreen ? 1 : 0.5}
        mb={isSmallScreen ? 0 : 0.5}
        alignItems={"center"}
        sx={{
          maxWidth: appMaxWidth,
          width: appMobileWidth,
        }}
      >
        {/* Mobile & Desktop: View Name */}
        {/* If no children, full width */}
        <Grid
          xs={!props.children ? 12 : 7}
          md={!props.children ? 12 : isQA ? 3 : 4}
        >
          <Typography variant="h1">{t("viewName." + props.view)}</Typography>
        </Grid>

        {props.children}
      </Grid>
    </Box>
  );
});

export default SubNav;
