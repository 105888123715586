// Globals
import { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

// Locals
import useAxios from "./useAxios";
import { getContentQuery } from "../lib/api/keyConstants";
import { ApiTokenContext } from "../contexts/InitContexts";
import { AxiosResponse } from "axios";
import { Content } from "../models/Content";
import { Evidence } from "../models/Evidence";
import { contentPerPage, shortStaleTime } from "../lib/configs";

interface QueryProps {
  queryKey: [
    string,
    number,
    { token: string; evidence?: Evidence; isGetRecent?: boolean; issueIds?: string[] }
  ];
}

// Business logic for getContent from server
const getContent = async ({ queryKey }: QueryProps) => {
  const [_key, page, { token, evidence, isGetRecent, issueIds }] = queryKey;

  const isRecentOnly = !evidence || isGetRecent ? true : false;

  // Use evidence attributes as params for /getContent endpoint
  let params = {
    page: page,
    pageSize: contentPerPage,
    issueIds: JSON.stringify(evidence?.issueIds || issueIds),
    themeIds: JSON.stringify(evidence?.themeIds),
    // TODO: Remove split. See trello card #186.
    languages: JSON.stringify(evidence?.detectedLanguage?.split(" ")),
    isRecentOnly: isRecentOnly && !issueIds,
  };

  return await useAxios({
    endpoint: "getContent",
    token: token,
    params: params,
  });
};

// Post-succesful fetch: data processing
// Convert hits to Content[] and return it
const parseResults = (data: AxiosResponse) => {
  const hits = data?.data.response.body.hits.hits;
  const total: number = data?.data.response.body.hits.total.value;

  const contentList: Content[] = [];
  for (const hit of hits) {
    contentList.push(new Content(hit));
  }

  return { contentList, total };
};

interface Props {
  evidence?: Evidence;
  isGetRecent?: boolean;
  issueIds?: string[];
}

// Handle content in response workflow
const useContent = ({ evidence, isGetRecent, issueIds }: Props) => {
  const [page, setPage] = useState<number>(0);
  const token = useContext(ApiTokenContext);

  const { isLoading, error, data } = useQuery({
    queryKey: [getContentQuery, page, { token, evidence, isGetRecent, issueIds }],
    queryFn: getContent,
    keepPreviousData: true,
    select: (data) => parseResults(data),
    staleTime: shortStaleTime,
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading) {
    return { isLoading };
  }

  if (error instanceof Error) {
    // TODO: Update this to handle missing content gracefully
    return { errorMessage: "NoContent" };
  }

  if (data) {
    // Calculate if page is final page (compare to total)
    const isNextButtonDisabled = (page + 1) * contentPerPage >= data.total;

    return { contentList: data.contentList, isNextButtonDisabled, page, setPage };
  }
  return {};
};

export default useContent;
