// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Chip, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import { ClearedEvidenceFilters } from "../../models/EvidenceFilters";
import useChipData from "../../hooks/useChipData";
import { getChipStyles } from "../../lib/theme/customStyles";
import { COLORS } from "../../lib/theme/default";
import AmpDrawer from "../drawers/AmpDrawer";
import { AmpDrawerState } from "../../lib/util/enums";
import FiltersDrawer from "../drawers/evidence/FiltersDrawer";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import AmpButton from "../buttons/AmpButton";

const clearedFilterKeys = Object.keys(new ClearedEvidenceFilters());

interface Props extends EvidenceFiltersProps {
  isLandingPage?: boolean;
}

// Show active filters in subnav
const ActiveFiltersView = (props: Props) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const { getSpecificObject } = useChipData({});
  const { drawerState, setDrawerState } = useAmpDrawer();

  const chipStack = Object.entries(props.evidenceFilters)
    .filter(([key, value]) => value !== undefined && clearedFilterKeys.includes(key))
    .flatMap(
      (
        [key, values] // create a chip for each value
      ) =>
        Array.isArray(values) ? (
          values.map((value) => (
            <Chip
              key={`${key}-${value}`}
              label={getSpecificObject(value, key)}
              variant="outlined"
              sx={getChipStyles(isSmallScreen ? "whiteBacked" : "grayStatic")}
            />
          ))
        ) : key === "isMisinformation" ? (
          <Chip
            key={key}
            label={"Misinfo"}
            variant="outlined"
            sx={getChipStyles(isSmallScreen ? "whiteBacked" : "grayStatic")}
          />
        ) : (
          [
            <Chip
              key={key}
              label={getSpecificObject(values, key)}
              variant="outlined"
              sx={getChipStyles(isSmallScreen ? "whiteBacked" : "grayStatic")}
            />,
          ]
        )
    );

  return (
    <Grid
      container
      xs={12}
      my={0.5}
      spacing={isSmallScreen ? 0.5 : 1}
      display="flex"
      alignItems={"center"}
      justifyContent={isSmallScreen ? "start" : "center"}
      width={1}
    >
      <Stack
        direction="row"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => setDrawerState(AmpDrawerState.Filters)}
        width={1}
        flexWrap="wrap"
      >
        {chipStack.length > 0 && (
          // Title
          <Box mr={1}>
            <Typography variant="h3" color={COLORS.airaPink}>
              {t("subNav.Filters")}
            </Typography>
          </Box>
        )}

        {/* Individual chips */}
        {chipStack.map((chip, index) => (
          <Grid key={index}>{chip}</Grid>
        ))}

        {/* Clear filters */}
        {chipStack.length > 0 && !props.isLandingPage && (
          <Box>
            <AmpButton type="text" text="Clear" onClick={props.clearEvidenceFilters} />
          </Box>
        )}
      </Stack>

      {/* Toggle components */}
      {!!drawerState && !props.isLandingPage && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <FiltersDrawer
            evidenceFilters={props.evidenceFilters}
            isQA={props.isQA}
            updateEvidenceFilters={props.updateEvidenceFilters}
            clearEvidenceFilters={props.clearEvidenceFilters}
          />
        </AmpDrawer>
      )}
    </Grid>
  );
};

export default ActiveFiltersView;
