// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface Props {
  label: string;
  values: string[];
  selectedValue?: string;
  showTitle?: boolean;
  isNotRows?: boolean;
  updateFormData: (values: Record<string, string>) => void;
}

const AmpRadioGroup = ({
  label,
  values,
  showTitle,
  isNotRows,
  selectedValue,
  updateFormData,
}: Props) => {
  // Globals
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box my={2}>
      {showTitle && (
        <Typography variant="h3">{t("forms.radioGroups." + label + ".label")}</Typography>
      )}
      <RadioGroup
        row={isSmallScreen || isNotRows ? false : true}
        aria-label={label}
        name={label}
        value={selectedValue || ""}
        onChange={(event) => updateFormData({ [label]: event.target.value })}
      >
        {values.map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={t("forms.radioGroups." + label + "." + value)}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default AmpRadioGroup;
