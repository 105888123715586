// Global
import { useState } from "react";

// MUI
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";

// Import local
import CancelAddEvidence from "../addEvidence/CancelAddEvidence";

interface Props {
  isReviewSubmit?: boolean;
  submitAgain: () => void;
}

const MobileX = ({ isReviewSubmit, submitAgain }: Props) => {
  const [isCancelDialog, setIsCancelDialog] = useState(false);

  return (
    <Box>
      {/* Button */}
      <ClearIcon onClick={() => setIsCancelDialog(true)} />
      {/* Confirm dialogue */}
      {isCancelDialog && (
        <CancelAddEvidence submitAgain={submitAgain} setIsCancelDialog={setIsCancelDialog} />
      )}
    </Box>
  );
};

export default MobileX;
