// Global
import { useState } from "react";

// Local
import { generateUniqueId } from "../lib/util/generators";
import { AddContentFormData } from "../models/Content";

// This hook manages the formData for adding Content
const useContentForm = () => {
  const [formData, setFormData] = useState({
    id: generateUniqueId(),
  } as AddContentFormData);

  // Update formData
  const updateFormData = (values: object) => {
    setFormData({ ...formData, ...values });
    // Log current state of form
    console.log({ ...formData, ...values });
  };

  return {formData, updateFormData}
};

export default useContentForm;
