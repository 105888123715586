// Global
import { ReactNode, forwardRef } from "react";

// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  appMaxWidth,
  appMobileWidth,
  cardMaxWidth,
  mainStageMinHeight,
} from "../../lib/theme/default";

// Local

interface Props {
  children?: ReactNode;
  subNavHeight?: number;
}

const MainStage = forwardRef<HTMLDivElement, Props>((props, ref) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex" justifyContent={"center"} minHeight={mainStageMinHeight}>
      <Box
        ref={ref}
        minWidth={isSmallScreen ? appMobileWidth : cardMaxWidth}
        maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
        justifyContent={"center"}
        mt={1}
        sx={{
          overflowY: "auto",
          height: `calc(100vh - 105px - ${props.subNavHeight || 0}px)`,
          paddingRight: isSmallScreen ? "8px" : "10px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
});

MainStage.displayName = 'MainStage';

export default MainStage;
