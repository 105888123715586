// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
  InputLabel,
} from "@mui/material";

// Local
import { SortableFields } from "../../lib/util/enums";
import { COLORS } from "../../lib/theme/default";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";

const SortableFieldsControl = ({
  evidenceFilters,
  updateEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const field = evidenceFilters.sortField;
  const sortableFieldsArray = Object.values(SortableFields);

  const handleChange = (event: SelectChangeEvent) => {
    updateEvidenceFilters({ sortField: event.target.value})
  };

  return (
    <FormControl>
      <InputLabel>{t("buttons.sortField.GuideText")}</InputLabel>
      <Select
        value={field}
        label={t("buttons.sortField.GuideText")}
        onChange={handleChange}
        size="small"
        displayEmpty
        sx={{
          backgroundColor: isSmallScreen ? COLORS.white : COLORS.backgroundGray,
        }}
      >
        {sortableFieldsArray.map((field, index) => (
          <MenuItem value={field} key={index}>
            {t("buttons.sortField." + field)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortableFieldsControl;
