// Global
import { useNavigate } from "react-router-dom";

// Local
import AlertDialogSlide from "../AlertSlideDialog";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  isReviewSubmit?: boolean;
  submitAgain: () => void;
  setIsCancelDialog: (x: boolean) => void;
}

const CancelAddEvidence = ({ isReviewSubmit, submitAgain, setIsCancelDialog }: Props) => {
  const navigate = useNavigate();

  // Confirm dialogue
  const handleUserChoice = (userChoice: string) => {
    if (userChoice === "confirm") {
      submitAgain();
      isReviewSubmit ? navigate(RouteOptions.QA) : navigate(RouteOptions.landing);
    }
    setIsCancelDialog(false);
  };

  return (
    <AlertDialogSlide
      title={isReviewSubmit ? "cancelReviewTitle" : "cancelInputTitle"}
      message={isReviewSubmit ? "cancelReviewMessage" : "cancelInputMessage"}
      isOpen={true}
      showCancel
      handleUserChoice={handleUserChoice}
    />
  );
};

export default CancelAddEvidence;
