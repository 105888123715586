// Global
import { useEffect } from "react";

// Local
import { RouteOptions } from "../lib/util/enums";
import useEvidence from "./useEvidence";
import useEvidenceFilters from "./useEvidenceFilters";

interface Props {
  isReviewSubmit?: boolean;
}

// Hook handles acquiring evidence data for one id (for review)
const useReviewSubmit = ({ isReviewSubmit = false }: Props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id"); // Check if id is present
  if (!id || !isReviewSubmit) {
    return null;
  }

  urlParams.append("ids", id);

  const view = RouteOptions.reviewSubmit;
  const { evidenceFilters } = useEvidenceFilters({
    view,
    urlParams,
  });

  //   const { evidenceList, isLoading, error } = useEvidence({
  const { evidenceList } = useEvidence({
    evidenceFilters,
  });

  if (evidenceList?.[0]) {
    return evidenceList[0];
  }
};

export default useReviewSubmit;
