import {
  ClearedEvidenceFilters,
  EvidenceFilters,
} from "../../models/EvidenceFilters";
import { SocialMediaPostType } from "./enums";
import { generateQuickAddAttachmentPrefix } from "./dateFormatters";
import { formatUniqueS3Filename } from "./formatters";

// This function sets empty arrays as undefined
// Needed for correct results from /getEvidence endpoint
export function setEmptyArraysToUndefined(obj: any): void {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      obj[key] = undefined;
    }
  }
}

// Check if filter object has values for optional properties
// Function is used to toggle: filter button color, and clear filter button
export function hasAllUndefinedProperties(obj: EvidenceFilters): boolean {
  const clearedFilters = new ClearedEvidenceFilters();
  const clearedProperties = Object.getOwnPropertyNames(clearedFilters);

  for (const property of clearedProperties) {
    if (obj[property as keyof typeof obj] !== undefined) {
      return false;
    }
  }

  return true;
}

// Remove duplicates function
export function cleanDuplicatesFromObjectArrays(obj: any): void {
  for (const prop in obj) {
    if (Array.isArray(obj[prop])) {
      obj[prop] = [...new Set(obj[prop])];
    }
  }
}

// Format QuickAdd data for formData
export function parseQuickAddDataForFormData(quickAddData: any) {
  return {
    id: quickAddData.id,
    date: quickAddData.date,
    text: quickAddData.text,
    socialMediaPostType: SocialMediaPostType.Video,
    likeCount: Math.round(quickAddData.likeCount),
    commentCount: Math.round(quickAddData.commentCount),
    shareCount: Math.round(quickAddData.shareCount),
    isQuickAdd: true,
    // Get attachment name by date, UUID, and known .JPEG
    attachments: [
      generateQuickAddAttachmentPrefix() +
        formatUniqueS3Filename({
          uuid: quickAddData.id,
          fileExtension: ".jpeg",
        }),
    ],
  };
}

// Clean and trim search term
// Convert to Opensearch format from natural Boolean
export const searchTermCleaning = (term: string): string => {
  // Remove excess spaces
  let cleanedTerm = term.replace(/\s\s+/g, " ");
  // Remove OR
  cleanedTerm = cleanedTerm.replace(/ OR /g, " ");
  // Convert AND NOT to +-
  cleanedTerm = cleanedTerm.replace(/ AND NOT /g, " +-");
  // Convert AND to +
  cleanedTerm = cleanedTerm.replace(/ AND /g, " +");
  cleanedTerm = cleanedTerm.trim();
  return cleanedTerm;
};
