// Global
import { useEffect } from "react";

// Import MUI and Formik
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Formik } from "formik";

// Import local
import PlatformCard from "./PlatformCard";
import { AddEvidenceForm } from "../../lib/addEvidenceForms/AddEvidenceForm";
import { InputStepProps } from "../../lib/addEvidenceForms/InputStepProps";
import { Platforms, PlatformTypes, Platform } from "../../models/Platform";
import TextFieldInput from "./TextFieldInput";

interface Props extends InputStepProps {
  stepDirection: (direction: string) => void;
}

const AddEvidenceSource = ({ handleAllowNext, updateFormData, formData, stepDirection }: Props) => {
  // Create an iterable list of Platforms

  const platformList: Platform[] = Object.keys(Platforms).map(
    (key) => Platforms[key as keyof typeof Platforms]
  );

  // Update form data with user selection
  const handleClick = (platformObject: Platform) => {
    // Ignore clicks on social media that miss the chips
    if (
      !(platformObject instanceof Platform) ||
      platformObject.type === PlatformTypes.SocialMedia
    ) {
      return;
    }

    updateFormData({ platformObject: platformObject });
    if (platformObject.type !== PlatformTypes.UserDefined) {
      // Step forward, unless user defined
      stepDirection("f");
    }
  };

  // Update form data with user's social media selection
  const handleSocialMediaClick = (values: AddEvidenceForm) => {
    updateFormData({ ...values });
    stepDirection("f");
  };

  const handleUserDefined = (userDefined: string) => {
    if (formData.platformObject) {
      const tempObject = formData.platformObject;
      tempObject.userDefined = userDefined;
      updateFormData({ platformObject: tempObject });
    }
  };

  // handle Allow Next Button
  useEffect(() => {
    if (formData.platformObject?.userDefined) {
      handleAllowNext(true);
    } else {
      handleAllowNext(false);
    }
  }, [formData]);

  return (
    <Box>
      <Grid container spacing={2} marginTop={2}>
        {/* Map the Platforms cards */}
        {platformList.map((platform, index) => (
          <Grid xs={4} md={3} key={index}>
            <PlatformCard
              platform={platform}
              onClick={handleClick}
              handleSocialMediaClick={handleSocialMediaClick}
            />
          </Grid>
        ))}
        {formData.platformObject?.type === PlatformTypes.UserDefined && (
          <Grid xs={12}>
            <Formik
              initialValues={{
                userDefined: formData.platformObject.userDefined
                  ? formData.platformObject.userDefined
                  : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                handleUserDefined(values.userDefined);
                setSubmitting(false);
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <TextFieldInput field="userDefined" required={true} />
              )}
            </Formik>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddEvidenceSource;
