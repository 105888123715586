// Global
import i18n from "i18next";
import { useContext } from "react";

// Local
import { IssuesContext, ThemesContext, CountriesContext } from "../contexts/InitContexts";
import { labeledObject } from "../lib/util/interfaces";
import { sortByLabel, sortThemeByGroup } from "../lib/util/sorters";
import { Theme } from "../models/Theme";
import { africanLanguages } from "../models/Languages";
import { Platforms } from "../models/Platform";
import { LocationQueryTypes, SentimentValues } from "../lib/util/enums";
import { Country } from "../models/Locations";
import useLocations from "./useLocations";

interface HookProps {
  field?: string;
  country?: string;
  value?: string;
}

interface GetDataProps extends HookProps {
  themes: labeledObject[];
  issues: labeledObject[];
  countries: Country[];
}

// Use FIELD to get correct dataset for current instance of component
export const getChipData = (props: GetDataProps) => {
  // Call Use Location to get cities

  switch (props.field) {
    case "issueIds":
      return sortByLabel(props.issues);
    case "themeIds":
      // Sort themes by new Theme array
      const data = sortByLabel(props.themes);
      const themeList = data.map(
        (item) => new Theme(item.id, item.label, item.group, item.description)
      );
      return sortThemeByGroup(themeList);

    case "languages":
      return africanLanguages;

    case "platforms":
      // Parse platform object into labeledObject format
      const platforms: labeledObject[] = Object.keys(Platforms).map((platform) => ({
        id: platform,
        label: Platforms[platform as keyof typeof Platforms].name,
      }));
      return platforms;
    case "sentiment":
      return Object.values(SentimentValues).map((value) => ({
        id: value,
        label: i18n.t("forms.radioGroups.sentiment." + value),
        // label: value,
      }));
    default:
      // Default is Countries
      return props.countries;
  }
};

// Two methods to fetch and format data based on the provided field type
// Classic hook: Provide a "field" prop for dataList values response
// Hook function: Use the getSpecificObject function to get a single result
const useChipData = (props: HookProps) => {
  const themes = useContext(ThemesContext);
  const issues = useContext(IssuesContext);
  const countries = useContext(CountriesContext);

  // If ChipData is cities, call hook. Otherwise hook is idled.
  const { cities, setLocationType } = useLocations({
    type: props.field === "cities" ? LocationQueryTypes.Cities : undefined,
    countryCodes: [props.country || ""],
  });

  const getSpecificObject = (value: string, field: string, country?: string) => {
    if (field === "ids") {
      return i18n.t("subNav.ID");
    }

    const dataList = getChipData({ field, country, themes, issues, countries });
    const simpleDataList = dataList.map(({ id, label }) => ({ id, label }));
    const dataObj = simpleDataList.find((item) => item.id === value)?.label;
    return dataObj;
  };

  // If ChipData is cities, return cities. Otherwise use switch statement.
  const dataList =
    props.field === "cities"
      ? cities
      : getChipData({
          field: props.field,
          country: props.country,
          themes,
          issues,
          countries,
        });
  return { dataList, getSpecificObject };
};

export default useChipData;
