// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

// MUI
import { Alert, Stack, Snackbar, Tooltip } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

// Local
import { Content } from "../models/Content";

// Constants
const iconSize = 32;

// This function displays icons and handles clicks on the
// Response Sharing functionality
const useSocialShare = (content: Content) => {
  if (!content.binaryPath) {
    return null;
  }

  const [isOpenSnackBar, setIsOpenSnackbar] = useState(false);
  const { t } = useTranslation();

  // Manual share: copy to clipboard and notify user
  const handleShare = () => {
    navigator.clipboard.writeText(content.binaryPath || "").then(() => {
      setIsOpenSnackbar(true);
    });
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
  };

  return (
    <Stack direction="row" spacing={1} display="flex" justifyContent={"space-evenly"}>
      {/* Manual share MUI icon */}
      <Tooltip title={t("buttons.socialShare.Generic")}>
        <ShareIcon onClick={handleShare} sx={{ fontSize: iconSize }} color="primary" />
      </Tooltip>

      {/* Array of Icons */}
      <Tooltip title={t("buttons.socialShare.WhatsApp")}>
        <WhatsappShareButton url={content.binaryPath}>
          <WhatsappIcon round={true} size={iconSize} />
        </WhatsappShareButton>
      </Tooltip>
      <Tooltip title={t("buttons.socialShare.Facebook")}>
        <FacebookShareButton url={content.binaryPath}>
          <FacebookIcon round={true} size={iconSize} />
        </FacebookShareButton>
      </Tooltip>
      <Tooltip title={t("buttons.socialShare.Twitter")}>
        <TwitterShareButton url={content.binaryPath}>
          <TwitterIcon round={true} size={iconSize} />
        </TwitterShareButton>
      </Tooltip>
      <Tooltip title={t("buttons.socialShare.Reddit")}>
        <RedditShareButton url={content.binaryPath}>
          <RedditIcon round={true} size={iconSize} />
        </RedditShareButton>
      </Tooltip>{" "}
      <Tooltip title={t("buttons.socialShare.Viber")}>
        <ViberShareButton url={content.binaryPath}>
          <ViberIcon round={true} size={iconSize} />
        </ViberShareButton>
      </Tooltip>{" "}
      <Tooltip title={t("buttons.socialShare.Telegram")}>
        <TelegramShareButton url={content.binaryPath}>
          <TelegramIcon round={true} size={iconSize} />
        </TelegramShareButton>
      </Tooltip>
      
      <Snackbar open={isOpenSnackBar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          {t("drawers.ShareUrl")}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default useSocialShare;
