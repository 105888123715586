import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Menu, MenuItem, ListItemIcon, Typography, Divider, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import InsightsIcon from "@mui/icons-material/Insights";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

// Local
import Logout from "@mui/icons-material/Logout";
import UserLanguage from "./UserLanguage";
import { AmpDrawerState, RouteOptions } from "../../lib/util/enums";
import { Help } from "@mui/icons-material";

interface Props {
  isMobile?: boolean;
  setDrawerState: (v: AmpDrawerState) => void;
  handleLogout: () => void;
}

// This function displays the menu to handle (future) user account settings and logout
export default function UserMenu({ isMobile, setDrawerState, handleLogout }: Props) {
  const { user } = useAuth0();
  const isAdmin = "admin" === user?.["https://amp.rootwise.co/role"];
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Menu anchor state management
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("navbar.tooltip.Menu")}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          onClick={handleClick}
          aria-controls={open ? "user-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {!isMobile && <AccountCircleIcon color="primary" fontSize="large" />}
          {isMobile && <MenuIcon />}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* Menu Items */}

        {/* Settings */}
        {/* Display username */}
        <Typography variant="h4" ml={2}>
          {user?.name}
        </Typography>
        {/* Toggle user language */}
        <MenuItem onClick={() => handleClose()}>
          <UserLanguage />
        </MenuItem>
        {/* Help (mobile only) */}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              setDrawerState(AmpDrawerState.Help);
            }}
          >
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            {t("userMenu.Help")}
          </MenuItem>
        )}
        {/* Logout */}
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("userMenu.Logout")}
        </MenuItem>
        {(isAdmin || isMobile) && (
          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>
        )}

        {/* Navigation options (mobile only) */}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.submit);
            }}
          >
            {" "}
            <ListItemIcon>
              <AddIcon />{" "}
            </ListItemIcon>
            {t("viewName./submit")}
          </MenuItem>
        )}

        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.collection);
            }}
          >
            {" "}
            <ListItemIcon>
              <VisibilityIcon />{" "}
            </ListItemIcon>
            {t("viewName./collection")}
          </MenuItem>
        )}

        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.analyze);
            }}
          >
            {" "}
            <ListItemIcon>
              <TroubleshootIcon />{" "}
            </ListItemIcon>
            {t("viewName./analyze")}
          </MenuItem>
        )}

        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.report);
            }}
          >
            <ListItemIcon>
              <InsightsIcon />{" "}
            </ListItemIcon>
            {t("viewName./report")}
          </MenuItem>
        )}

        {/* Access content manager */}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.content);
            }}
          >
            <ListItemIcon>
              <AutoAwesomeMotionIcon />{" "}
            </ListItemIcon>
            {t("userMenu.ContentManager")}
          </MenuItem>
        )}
        {/* Access QA Page */}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.QA);
            }}
          >
            {" "}
            <ListItemIcon>
              <EditNoteIcon />{" "}
            </ListItemIcon>
            {t("userMenu.QA")}
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.admin);
            }}
          >
            {" "}
            <ListItemIcon>
              <EditNoteIcon />{" "}
            </ListItemIcon>
            {t("userMenu.Admin")}
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.bulkUpload);
            }}
          >
            {" "}
            <ListItemIcon>
              <EditNoteIcon />{" "}
            </ListItemIcon>
            {t("userMenu.BulkUpload")}
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}
