// Global
import { useRef, useEffect, useState, useCallback } from "react";

// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import SubNav from "../components/navigation/SubNav";
import MainStage from "../components/navigation/MainStage";
import { AmpCardTypes, RouteOptions } from "../lib/util/enums";
import AmpCard from "../components/cards/AmpCard";
import { useEvidence } from "../hooks/useEvidence";
import CircleLoading from "../components/navigation/CircleLoading";
import { Evidence } from "../models/Evidence";
import EvidenceCard from "../components/cards/EvidenceCard";
import useEvidenceFilters from "../hooks/useEvidenceFilters";
import DisplayResultCount from "../components/cards/DisplayResultCount";
import EvidenceFilterControls from "../components/filters/EvidenceFilterControls";
import ReviewControl from "../components/cards/ReviewControl";

interface Props {
  isQA?: boolean;
}

const MyCollection = ({ isQA }: Props) => {
  // Global
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const urlParams = new URLSearchParams(window.location.search);
  const subNavRef = useRef<HTMLDivElement | null>(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  // Local
  const view = isQA ? RouteOptions.QA : RouteOptions.collection;
  const { evidenceFilters, updateEvidenceFilters, clearEvidenceFilters } = useEvidenceFilters({
    view,
    urlParams,
  });

  const { evidenceList, resultCount, isLoading, error, manageNextPage } = useEvidence({
    evidenceFilters,
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Infinite scroll
  const lastCardRef = useCallback(
    (node: HTMLElement | null) => {
      if (
        node !== null &&
        manageNextPage &&
        manageNextPage.hasNextPage &&
        !manageNextPage.isFetchingNextPage
      ) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              // Save current scroll position before fetching
              if (scrollContainerRef.current) {
                setScrollPosition(scrollContainerRef.current.scrollTop);
              }
              manageNextPage.fetchNextPage();
            }
          },
          { threshold: 0.1 }
        );
        observer.observe(node);
        return () => observer.disconnect();
      }
    },
    [manageNextPage]
  );

  // Restore scroll position after new content is loaded
  useEffect(() => {
    if (scrollContainerRef.current && scrollPosition > 0) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [evidenceList, scrollPosition]);

  useEffect(() => {
    if (subNavRef.current) {
      setSubNavHeight(subNavRef.current.offsetHeight);
    }
  }, [evidenceFilters]);

  return (
    <Box>
      {/* SubNav Container */}
      <SubNav view={view} ref={subNavRef}>
        <EvidenceFilterControls
          view={view}
          evidenceFilters={evidenceFilters}
          updateEvidenceFilters={updateEvidenceFilters}
          clearEvidenceFilters={clearEvidenceFilters}
        />
      </SubNav>

      <MainStage subNavHeight={subNavHeight} ref={scrollContainerRef}>
        {isLoading && <CircleLoading />}
        {!!error && error.toString()}

        {/* Display number of results to user  */}
        <DisplayResultCount count={resultCount?.toString()} />

        {evidenceList &&
          // Map each evidence card
          evidenceList.map((evidence: Evidence, i: number) =>
            // Card with infinite scroll ref trigger: 3rd to last card
            i === evidenceList.length - 1 ? (
              <Box display="flex" alignItems={"center"} key={evidence.id} width={1}>
                <AmpCard type={AmpCardTypes.evidence} ref={lastCardRef}>
                  <EvidenceCard evidence={evidence} isQA={isQA} />
                  {isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
                </AmpCard>
                {!isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
              </Box>
            ) : (
              <Box display="flex" alignItems={"center"} key={evidence.id} width={1}>
                <AmpCard type={AmpCardTypes.evidence}>
                  <EvidenceCard evidence={evidence} isQA={isQA} />
                  {isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
                </AmpCard>
                {!isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
              </Box>
            )
          )}
      </MainStage>
    </Box>
  );
};

export default MyCollection;
