// Global
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

// Local
import { ApiTokenContext, ThemesContext, CountriesContext } from "../contexts/InitContexts";
import useAxios from "./useAxios";
import {
  Evidence,
  EvidenceAggregatedByDay,
  EvidenceAggregatedByLocation,
  ValueCountPair,
} from "../models/Evidence";
import { evidence } from "../lib/api/keyConstants";
import { EvidenceFilters } from "../models/EvidenceFilters";
import {
  cardsPerPage,
  generatedReportEvidenceLimit,
  idsOnlyLimit,
  shortStaleTime,
} from "../lib/configs";
import { cleanDuplicatesFromObjectArrays, searchTermCleaning } from "../lib/util/dataCleaning";
import { RouteOptions } from "../lib/util/enums";
import { shuffleArray } from "../lib/util/sorters";
import { labeledObject } from "../lib/util/interfaces";
import { Country } from "../models/Locations";
import { formatLocationsForMap } from "../lib/util/formatters";

interface QueryProps {
  queryKey: [
    string,
    {
      token: string;
      evidenceFilters: EvidenceFilters;
      isAggregationsOnly?: boolean;
      isMapsOnly?: boolean;
      isIdsOnly?: boolean;
    }
  ];
  pageParam?: number | undefined;
}

// getEvidence business logic
const getEvidence = async ({ queryKey, pageParam = 0 }: QueryProps) => {
  const [_key, { token, evidenceFilters, isAggregationsOnly, isMapsOnly, isIdsOnly }] = queryKey;

  // Parse evidenceFilters to build /getEvidence endpoint params
  let params: any = {
    ids: JSON.stringify(evidenceFilters.ids),
    page: pageParam || 0,
    pageSize: isIdsOnly ? idsOnlyLimit : isAggregationsOnly || isMapsOnly ? 0 : cardsPerPage,
    sortField: evidenceFilters.sortField,
    sortOrder: evidenceFilters.sortDesc === true ? "desc" : "asc",

    startDate: evidenceFilters.dateRange?.gte.valueOf(),
    endDate: evidenceFilters.dateRange?.lte.valueOf(),
    searchText: evidenceFilters?.searchText
      ? searchTermCleaning(evidenceFilters?.searchText)
      : undefined,
    status: JSON.stringify(evidenceFilters.status),
    countries: JSON.stringify(evidenceFilters.countries),
    issueIds: JSON.stringify(evidenceFilters.issueIds),
    themeIds: JSON.stringify(evidenceFilters.themeIds),
    isMisinformation: evidenceFilters.isMisinformation ? true : undefined,
    languages: JSON.stringify(evidenceFilters.languages),
    platforms: JSON.stringify(evidenceFilters.platforms),
    sentiment: JSON.stringify(evidenceFilters.sentiment),
    // If isIdsOnly, just fetch IDs, not full evidence
    fields: isIdsOnly ? JSON.stringify(["ids"]) : undefined,
    // Hide other authors as proxy for "My Collection"
    hideOtherAuthors: evidenceFilters.view === RouteOptions.collection,
    // Hide Aggregations on Collection and QA view
    hideAggregations:
      isMapsOnly ||
      evidenceFilters.view === RouteOptions.collection ||
      evidenceFilters.view === RouteOptions.QA ||
      evidenceFilters.view === RouteOptions.report ||
      isIdsOnly,
    isMapsOnly: isMapsOnly,
  };

  const response = await useAxios({
    token: token,
    endpoint: "getEvidence",
    params: params,
  });

  return response;
};

interface ParseProps {
  data: { pages: AxiosResponse[] };
  themeData: labeledObject[];
  countryData: Country[];
  isMapsOnly?: boolean;
}

// Parsing successfully fetch results:
// Process hits and evidence into structured data elements
const parseResults = ({ data, themeData, countryData, isMapsOnly }: ParseProps) => {
  const evidenceList: Evidence[] = [];
  const cityData = data.pages[0].data.response.body.cityData;

  // Loop through "pages" of data (Infinite Query array)
  for (const page of data.pages) {
    const hits = page.data.response.body.hits.hits;

    for (const hit of hits) {
      const evidenceCard = new Evidence(hit);
      // Remove duplicates in arrays (e.g., themes, issues)
      cleanDuplicatesFromObjectArrays(evidenceCard);
      evidenceList.push(evidenceCard);
    }
  }

  // Parse Aggregations
  if (data.pages[0].data.response.body.aggregations) {
    const currentAggs = data.pages[0].data.response.body.aggregations;

    // Get evidenceByLocation aggregation and create object
    const evidenceByLocation: EvidenceAggregatedByLocation[] =
      currentAggs.evidenceByLocation.buckets;
    const mapData: EvidenceAggregatedByLocation[] = evidenceByLocation.map((location: any) => {
      const formattedLocation = formatLocationsForMap(location.key, countryData, cityData);

      return new EvidenceAggregatedByLocation(location, formattedLocation);
    });

    // Return just maps aggregations if its just isMapsOnly
    if (isMapsOnly) {
      return {
        evidenceList,
        chartData: { mapData, lineChartData: [], evidenceByTheme: [], sentimentData: [] },
      };
    }

    // Get evidenceByDate
    const evidenceByDate = currentAggs.evidenceByDate.buckets;

    const lineChartData: EvidenceAggregatedByDay[] = evidenceByDate.map(
      (day: any) => new EvidenceAggregatedByDay(day)
    );

    // Get evidenceByTheme aggregation and create object
    const evidenceByTheme: ValueCountPair[] = currentAggs.evidenceByTheme.buckets.map(
      (themeIdObj: any) => {
        const themeObj = themeData.find((theme) => theme.id === themeIdObj.key);
        // Limit full name 23 characters to fit on chart
        return new ValueCountPair({
          doc_count: themeIdObj.doc_count,
          key: themeObj?.label,
        });
      }
    );

    // Get evidenceBySentiment aggregation and create object
    const sentiment: { key: string; doc_count: number }[] = currentAggs.sentiment.buckets;
    // Combine items with the same key (case-insensitive) and sum their doc_count
    const combinedSentiment = sentiment.reduce(
      (acc: { key: string; doc_count: number }[], curr) => {
        const foundIndex = acc.findIndex(
          (item) => item.key.toLowerCase() === curr.key.toLowerCase()
        );
        if (foundIndex !== -1) {
          acc[foundIndex].doc_count += curr.doc_count;
        } else {
          acc.push({ key: curr.key.toLowerCase(), doc_count: curr.doc_count });
        }
        return acc;
      },
      []
    );
    const sentimentData: ValueCountPair[] = combinedSentiment.map(
      (sentiment: any) => new ValueCountPair(sentiment)
    );

    // Return with aggregations
    return {
      evidenceList,
      chartData: { lineChartData, mapData, evidenceByTheme, sentimentData },
    };
  }

  // Return w/o aggergations (for My Collection)
  return { evidenceList };
};

interface Props {
  evidenceFilters: EvidenceFilters;
  isAggregationsOnly?: boolean;
  isMapsOnly?: boolean;
  isStaleOk?: boolean;
  isIdsOnly?: boolean;
  isReady?: boolean;
}

// State management for Evidence
// Infinite scroll UX
export const useEvidence = ({
  evidenceFilters,
  isAggregationsOnly,
  isMapsOnly,
  isStaleOk,
  isIdsOnly,
}: Props) => {
  // Global
  const token = useContext(ApiTokenContext);
  const themeData = useContext(ThemesContext);
  const countryData = useContext(CountriesContext);
  const navigate = useNavigate();

  const { isLoading, isFetching, data, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [evidence, { token, evidenceFilters, isAggregationsOnly, isMapsOnly, isIdsOnly }],
      queryFn: getEvidence,
      // Calculate pageParam or returned undefined to disable LoadMore
      getNextPageParam: (lastPage, allPages) => {
        const totalHits = lastPage.data.response.body.hits.total.value;
        const currentItemCount = allPages.length * cardsPerPage;
        return totalHits > currentItemCount ? allPages.length : undefined;
      },
      staleTime: isStaleOk ? shortStaleTime : undefined,
      enabled: !!token,
    });

  // Show user a spinner or handle error

  // TODO: Update this to handle infinite scroll
  if (isLoading || isFetching) {
    return { isLoading };
  }

  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      navigate(RouteOptions.unauthorized, { state: { status: "401" } });
    }
    return { error };
  }

  // Data for GeneratedReport
  if (data && isIdsOnly) {
    // Create a list of Ids
    const evidenceIdList: string[] = data.pages[0].data.response.body.hits.hits.map(
      (hit: { _id: string }) => hit._id
    );

    // Randomize and take a sample
    const shuffledEvidenceIds = shuffleArray(evidenceIdList);
    const sampledIds = shuffledEvidenceIds.slice(0, generatedReportEvidenceLimit);

    return { reportIds: { sampledIds } };
  }

  // Main path: Data for all other use cases
  if (data) {
    const results = parseResults({ data, themeData, countryData, isMapsOnly });
    const resultCount: number | undefined =
      data?.pages[0].data.response.body.hits.total.value.toString();

    // Set the state variable instead of returning the data
    return {
      evidenceList: results.evidenceList,
      resultCount: resultCount,
      chartData: results.chartData,
      manageNextPage: { hasNextPage, isFetchingNextPage, fetchNextPage },
    };
  }

  return {};
};

export default useEvidence;
