import { EvidenceStatus } from "./util/enums";

// Maximum Upload size (in MBs)
export const maxAttachmentSize = 10;

// Maximum text characters on evidence cards
export const maxTextLength = 750;
export const extraTruncated = 250;

// Maximum data returned per page
export const cardsPerPage = 20;
export const contentPerPage = 6;
export const generatedReportsPerPage = 10;
export const idsOnlyLimit = 5000;
export const generatedReportEvidenceLimit = 100;

// Truncate theme name length
export const truncThemeLabelLength = 23;


// Query stale time
export const shortStaleTime = 120000 // 2 min stale

// Trending period options (in days)
export const trendingPeriodOptions = [7, 14, 30, 90, 365];

// Default Statuses that load in QA view
export const qaStatuses = [
  EvidenceStatus.NeedsDetail,
  EvidenceStatus.NeedsReview,
  EvidenceStatus.Active,
];

// URL Length on Content Cards
export const urlLengthCard = {
  xs: 35,
  md: 65,
};

// Allowable countries in AMP
export const ampCountries = [
  "AO",
  "BF",
  "BI",
  "BJ",
  "BW",
  "CD",
  "CF",
  "CG",
  "CI",
  "CM",
  "CV",
  "DJ",
  "DZ",
  "ER",
  "ET",
  "GA",
  "GH",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GW",
  "KE",
  "KM",
  "LR",
  "LS",
  "MG",
  "ML",
  "MR",
  "MU",
  "MW",
  "MZ",
  "NA",
  "NE",
  "NG",
  "RW",
  "SC",
  "SL",
  "SN",
  "SS",
  "ST",
  "SZ",
  "TD",
  "TG",
  "TZ",
  "UG",
  "ZA",
  "ZM",
  "ZW",
];
