export enum AttachmentTypes {
  image = "image",
  video = "video",
  audio = "audio",
  file = "file",
  error = "error",
}

export const FileTypes = [
  { ext: "JPG", type: AttachmentTypes.image },
  { ext: "JPEG", type: AttachmentTypes.image },
  { ext: "PNG", type: AttachmentTypes.image },
  { ext: "GIF", type: AttachmentTypes.image },
  { ext: "AVI", type: AttachmentTypes.video },
  { ext: "MOV", type: AttachmentTypes.video },
  { ext: "MP4", type: AttachmentTypes.video },
  { ext: "MP3", type: AttachmentTypes.audio },
  { ext: "AAC", type: AttachmentTypes.audio },
  { ext: "WAV", type: AttachmentTypes.audio },
  { ext: "FLAC", type: AttachmentTypes.audio },
  { ext: "M4A", type: AttachmentTypes.audio },
  { ext: "WMA", type: AttachmentTypes.audio },
  { ext: "OPUS", type: AttachmentTypes.audio },
  { ext: "PDF", type: AttachmentTypes.file },
  { ext: "DOC", type: AttachmentTypes.file },
  { ext: "DOCX", type: AttachmentTypes.file },
  { ext: "XLS", type: AttachmentTypes.file },
  { ext: "XLSX", type: AttachmentTypes.file },
  { ext: "TXT", type: AttachmentTypes.file },
  { ext: "CSV", type: AttachmentTypes.file },
  { ext: "PPT", type: AttachmentTypes.file },
  { ext: "PPTX", type: AttachmentTypes.file },
  { ext: "ODT", type: AttachmentTypes.file },
  { ext: "ODS", type: AttachmentTypes.file },
];
