// Global
import { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

// Local
import EditEvidenceProperty from "../components/drawers/evidence/EditEvidenceProperty";
import { Evidence } from "../models/Evidence";
import { AddEvidenceForm } from "../lib/addEvidenceForms/AddEvidenceForm";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";
import { ApiTokenContext } from "../contexts/InitContexts";
import LinearLoader from "../components/navigation/LinearLoading";

interface Props {
  evidenceId: string;
  type?: keyof Evidence;
  ids?: string[];
}

// This hook enables editing of evidence
// If user requests an edit in details view,
// Serves an editing component instead of the static property value
// Submits edited data to Opensearch
const useEditEvidence = ({ type, evidenceId, ids }: Props) => {
  // Global hooks
  const apiToken = useContext(ApiTokenContext);

  // Local hooks and state
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [values, setValues] = useState(ids);
  const originalValues = ids;
  const loader = <LinearLoader />;
  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting update to database!");
    },
    onSuccess: () => {
      // Close edit component
      console.log("Submit successful.");
      setIsEditting(false);
    },
  });

  // Toggle views between VIEW and EDIT data
  const handleEdit = () => {
    setValues(originalValues);
    setIsEditting(!isEditting);
  };

  // Update the values based on user changes
  const updateFormData = (data: object) => {
    setValues(Object.values(data)[0]);
  };

  // Submit Edits to Opensearch
  const submitEdits = (type: keyof Evidence, newValues: string | string[] | boolean) => {
    const updatedData: AddEvidenceForm = {
      id: evidenceId,
      updatedAt: new Date(),
      [type.toString()]: newValues,
    };
    mutation.mutate({
      submitType: AmpDataSubmitTypes.Evidence,
      data: updatedData,
      apiToken,
    });
    if (typeof newValues === "string") {
      setValues([newValues]);
    }
    console.log("Submitting edited data:", updatedData);
  };

  // Define edit, loading components for user
  const editView = (
    <EditEvidenceProperty
      updateFormData={updateFormData}
      handleEdit={handleEdit}
      submitEdits={submitEdits}
      type={type}
      ids={values}
    />
  );

  if (isEditting) {
    return {
      values,
      editView,
      isEditting,
      handleEdit,
      submitEdits,
      isLoading: !!mutation.isLoading ? loader : null,
    };
  }

  return {
    values,
    isEditting,
    handleEdit,
    submitEdits,
    isLoading: !!mutation.isLoading ? loader : null,
  };
};

export default useEditEvidence;
