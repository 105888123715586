// Global
import { useRef, useEffect, useState } from "react";

// MUI
import { Box } from "@mui/material";

// Local
import SubNav from "../components/navigation/SubNav";
import { RouteOptions } from "../lib/util/enums";
import AddContent from "../components/content/AddContent";
import ContentFilterControls from "../components/filters/ContentFilterControls";
import { contentSubviews } from "../lib/util/enums";
import ViewContentCards from "../components/content/ViewContentCards";
import MainStage from "../components/navigation/MainStage";

const ContentManager = () => {
  // Global hooks
    const subNavRef = useRef<HTMLDivElement | null>(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  // Local hooks and State
  const [subview, setSubview] = useState<contentSubviews>(contentSubviews.ViewContent);

  useEffect(() => {
    if (subNavRef.current) {
      setSubNavHeight(subNavRef.current.offsetHeight);
    }
  }, []);

  return (
    <Box>
      {/* Subnav for Content manager */}
      <SubNav view={RouteOptions.content} ref={subNavRef}>
        <ContentFilterControls subview={subview} setSubview={setSubview} />
      </SubNav>

      <MainStage subNavHeight={subNavHeight}>
        {/* Display Add or View page */}
        {subview === "AddContent" && <AddContent />}
        {subview === "ViewContent" && <ViewContentCards />}
      </MainStage>
    </Box>
  );
};
export default ContentManager;
