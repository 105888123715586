// Global
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


// MUI
import { SelectChangeEvent, Stack, Tabs, Tab, Box } from "@mui/material";

// Local
import TrendingThemes from "./TrendingThemes";
import { AmpTrendTypes, RouteOptions } from "../../lib/util/enums";
import TrendingContent from "./TrendingContent";
import { TrendsMenu } from "./TrendsMenu";
import useEvidenceFilters from "../../hooks/useEvidenceFilters";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import { appendUrlParamsFromFilters } from "../../lib/util/formatters";
import GenerateReportControl from "../cards/GenerateReportControl";
import useEvidence from "../../hooks/useEvidence";
dayjs.extend(utcPlugin);

// This component displays trending signals
const Trends = () => {
  // Global

  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);

  // Local
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const [openTab, setOpenTab] = useState<AmpTrendTypes>(AmpTrendTypes.Themes);
  const [trendingPeriod, setTrendingPeriod] = useState<number>(30);
  const [generateReportCounter, setGenerateReportCounter] = useState<number>(0);

  const { evidenceFilters, updateEvidenceFilters } = useEvidenceFilters({
    view: RouteOptions.analyze,
    urlParams,
  });

  // For report generation only
  const { chartData } = useEvidence({
    evidenceFilters,
  });

  // Set trending period
  const handlePeriodChange = (event: SelectChangeEvent) => {
    const days =
      typeof event.target.value === "string" ? Number(event.target.value) : event.target.value;
    const dateValue = dayjs().utc().subtract(days, "day").startOf("day");
    updateEvidenceFilters({ dateRange: { lte: dayjs(), gte: dateValue } });
    setTrendingPeriod(days);
  };

  const handleClick = (buttonName: string) => {
    if (buttonName === "analyze") {
      const urlParams = appendUrlParamsFromFilters({
        baseFilters: evidenceFilters,
        trendingPeriod,
      });
      navigate({
        pathname: RouteOptions.analyze,
        search: urlParams.toString(),
      });
    }
    if (buttonName === "generate") {
      setGenerateReportCounter(generateReportCounter + 1);
    }
  };

  return (
    <Stack display="flex" overflow="hidden" flexGrow={1}>
      {/* Trends Control Bar */}
      <TrendsMenu
        evidenceFilters={evidenceFilters}
        updateEvidenceFilters={updateEvidenceFilters}
        handleClick={handleClick}
        trendingPeriod={trendingPeriod}
        handlePeriodChange={handlePeriodChange}
        openTab={openTab}
        isReadyForGenerate={!chartData}
      />

      {/* Tabs for specific trend views */}
      <Tabs
        value={openTab}
        onChange={(_, newValue) => setOpenTab(newValue)}
        aria-label="Choose Tab"
        // centered
        sx={{ mb: 2, ml: -1 }}
      >
        {/* Trends tab options */}
        {Object.keys(AmpTrendTypes).map((trendType) => (
          <Tab
            label={t("drawers.trends." + trendType)}
            value={AmpTrendTypes[trendType as keyof typeof AmpTrendTypes]}
            key={trendType}
          />
        ))}
      </Tabs>

      {/* Display trends grid corresponding to selected tab */}
      <Box display="flex" overflow="auto">
        {openTab === AmpTrendTypes.Content && (
          <TrendingContent issueIds={evidenceFilters.issueIds} />
        )}
        {openTab !== AmpTrendTypes.Content && (
          <TrendingThemes
            evidenceFilters={evidenceFilters}
            isIssuesAggregation={openTab === AmpTrendTypes.Issues}
            trendingPeriod={trendingPeriod}
          />
        )}
      </Box>
      {evidenceFilters && chartData && (
        <GenerateReportControl
          data={{ evidenceFilters, evidenceList: [], chartData }}
          setFadeOut={setFadeOut}
          openDialogNow={generateReportCounter}
          setGenerateReportCounter={setGenerateReportCounter}
          isLandingPage
        />
      )}
    </Stack>
  );
};

export default Trends;
