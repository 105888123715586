// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";

const UserLanguage = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  // Toggle between En and Fr in i18n
  const toggleLanguage = async () => {
    const newLang = currentLang === "en" ? "fr" : "en";

    i18n.changeLanguage(newLang, (err, t) => {
      if (err) return console.log("changeLanguage error", err);
    });
  };

  return (
    <Box onClick={toggleLanguage}>
      {/* Language toggle, false override keeps component controlled */}
      English <Switch checked={currentLang === "fr" || false} /> Français
    </Box>
  );
};

export default UserLanguage;
