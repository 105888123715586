import getFileExtension from "./getFileExtension";

export function isVideoAttachment(attachments: string[]): boolean {
  try {
    return attachments.some((attachment) => {
      const fileType = getFileExtension(attachment);
      return fileType === "video";
    });
  } catch (error) {
    console.log("Error with file extension:", error);
    return false
  }
}
