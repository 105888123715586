// Global
import { useState } from "react";

// Local
import { AmpDrawerState } from "../lib/util/enums";

// This hook manages drawer state:
// Whether drawer is open, closed, and specific tab
const useAmpDrawer = () => {
  const [drawerState, setDrawerState] = useState<AmpDrawerState>(
    AmpDrawerState.Closed
  );

  return { drawerState, setDrawerState };
};

export default useAmpDrawer;
