// Global
import { useTranslation } from "react-i18next";
import React from "react";

// MUI
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  value?: string;
}

const MisinformationIcon: React.FC<Props> = ({ value }) => {
    const { t } = useTranslation();


  const lowercaseValue = value?.toLowerCase();

  if (lowercaseValue === "agree" || lowercaseValue === "strongly agree") {
    return (
      <Tooltip title={t("cards.tooltip.IsMisinformation")} sx={{ ml: 1 }}>
        <WarningAmberIcon className="isNotButton" color="secondary" />
      </Tooltip>
    );
  }

  // If no value or not matching string, return null
  return null;
};

export default MisinformationIcon;
