// Global
import { useTranslation } from "react-i18next";

// Mui
import { Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  type: string;
  handleEdit: () => void;
}
const EditButton = ({ type, handleEdit }: Props) => {
  const { t } = useTranslation();

  return (
    <Box ml={0.5} display="inline" onClick={handleEdit}>
      <Tooltip title={t("cards.tooltip.EditData." + type)}>
        <EditIcon color="primary" fontSize="small" />
      </Tooltip>
    </Box>
  );
};

export default EditButton;
