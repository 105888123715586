// Global
import { ReactNode } from "react";

// MUI
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { AmpDrawerState } from "../../lib/util/enums";

interface Props {
  drawerState: AmpDrawerState;
  children: ReactNode;
  handleCloseDrawer: () => void;
}
const AmpDrawer = ({ drawerState, children, handleCloseDrawer }: Props) => {
  if (drawerState === AmpDrawerState.Closed) {
    return null;
  }

  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and constants
  // Desktop only: Size AmpDrawer wide/skinny based on drawerstate
  let drawerWidth: string;
  switch (drawerState) {
    case AmpDrawerState.Responses:
    case AmpDrawerState.EvidenceDetails:
      drawerWidth = "500px";
      break;
    case AmpDrawerState.ExportReport:
      drawerWidth = "850px";
      break;
    default:
      drawerWidth = "30vw";
  }

  return (
    <Drawer
      anchor={isSmallScreen ? "bottom" : "right"}
      open={true}
      onClose={handleCloseDrawer}
      // SlideProps={{
      //   // direction: isSmallScreen ? "up" : "right",
      //   in: true,
      //   timeout: {
      //     enter: 5000, // Enter transition duration in milliseconds
      //     exit: 5000,  // Exit transition duration in milliseconds
      //   },
      // }}
      PaperProps={{
        sx: {
          width: isSmallScreen ? "auto" : drawerWidth,
          height: isSmallScreen ? "80vh" : 1,
        },
      }}
    >
      {/* Close button */}
      <Box
        onClick={handleCloseDrawer}
        zIndex={1}
        sx={{
          // position: "fixed",
          // top: isSmallScreen ? "calc(20vh + 16px)" : 16,
          position: "absolute",
          top:  16,
          right: 16,
        }}
      >
        <ClearIcon />{" "}
      </Box>
      {children}
    </Drawer>
  );
};

export default AmpDrawer;
