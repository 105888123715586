import { Typography } from "@mui/material";

interface Props {
  wide?: boolean;
  isBullet?: boolean;
}

export default function DotSpacer({ wide, isBullet }: Props) {
  const variant = isBullet ? 'h2' : 'h4'

  return (
    <Typography variant={variant} mx={wide ? 0.5 : 0.25} display={"inline"}>
      {" "}
      &#183;{" "}
    </Typography>
  );
}
