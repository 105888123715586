// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Tooltip, Typography } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface Props {
  count: number | undefined;
  isViewCount?: boolean;
}

// Display the evidence property interactions or views on Evidence card
const DisplayInteractions = ({ count, isViewCount }: Props) => {
  if (!count || count < 1) {
    return null;
  }

  const { t } = useTranslation();

  return (
    <Box display="inline-flex" alignItems={"center"} ml={1}>
      {!isViewCount && (
        <Tooltip title={t("cards.tooltip.InteractionCount")}>
          <AdsClickIcon
            color="primary"
            className="isNotButton"
          />
        </Tooltip>
      )}
      {isViewCount && (
        <Tooltip title={t("cards.tooltip.ViewCount")}>
          <VisibilityIcon
            color="primary"
            className="isNotButton"
          />
        </Tooltip>
      )}
      <Typography variant="h4" ml={0.5}>
        {Number(count).toLocaleString()}
      </Typography>
    </Box>
  );
};

export default DisplayInteractions;
