// Global
import { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

// Local
import { ApiTokenContext } from "../contexts/InitContexts";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";
import { EmailTypes, FlagForReviewData } from "../models/Email";
import { User } from "@auth0/auth0-react";
import { Evidence } from "../models/Evidence";
import { AddEvidenceForm } from "../lib/addEvidenceForms/AddEvidenceForm";
import { formatHyperlink } from "../lib/util/formatters";

interface Props {
  evidence?: Evidence | AddEvidenceForm;
  userLang?: string;
  dataType?: string;
  user?: User;
}

const useSendEmail = ({ dataType = "signal", evidence, userLang, user }: Props) => {
  // Local hooks and state
  const apiToken = useContext(ApiTokenContext);
  const mutation = useMutation(submitData);
  const [flagForReviewData, setFlagForReviewData] = useState(
    new FlagForReviewData(dataType, evidence, userLang, user)
  );

  // Update description with user text
  const setEmailDescription = (text: string, key: keyof FlagForReviewData = "description") => {
    setFlagForReviewData({ ...flagForReviewData, [key]: text });
  };

  // Submit and receive mutation response on user click
  const handleSendEmail = (emailType: EmailTypes = EmailTypes.FlagForReview, user?: string) => {
    mutation.mutate({
      submitType: AmpDataSubmitTypes.Email,
      data: {
        ...flagForReviewData,
        emailType: emailType,
        link: evidence ? formatHyperlink(evidence.id, emailType) : undefined,
        user: user ? user : undefined,
      },
      apiToken,
    });
  };

  return {
    flagForReviewData,
    setEmailDescription,
    handleSendEmail,
    status: mutation.status,
  };
};

export default useSendEmail;
