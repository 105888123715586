// Global
import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

// Local
import useAxios from "./useAxios";
import { ApiTokenContext } from "../contexts/InitContexts";
import { getEvidenceCountQuery } from "../lib/api/keyConstants";
import { AxiosResponse } from "axios";

interface QueryProps {
  queryKey: [string, { token: string }];
}

// Business logic for aggregateUserEvidence endpoint
const getEvidenceCount = async ({ queryKey }: QueryProps) => {
  const [_key, { token }] = queryKey;

  return await useAxios({
    endpoint: "aggregateUserEvidence",
    token: token,
    params: { startDate: dayjs().add(-30, "days").toDate() },
  });
};

// Parse results, return badge enum
const parseResults = (data: AxiosResponse) => {
  return data?.data.response.userEvidenceCount?.buckets[0]?.doc_count;
};

// Hook manages user badge based on number of signals submitted
const useBadges = () => {
  const token = useContext(ApiTokenContext);

  // Default to 0 to mitigate technical errors
  const [badgeCount, setBadgeCount] = useState<number>();

  // Get Badge count aggregations from DB
  const { status, error, data } = useQuery({
    queryKey: [getEvidenceCountQuery, { token }],
    queryFn: getEvidenceCount,
    select: (data) => parseResults(data),
    enabled: !!token,
  });

  if (error instanceof Error) {
    console.log("useBadges error:", error);
  }
  // When data returns, set initial badge count
  if (data && badgeCount === undefined) {
    setBadgeCount(data);
  }

  // Return loading or error status, or if success, data
  return { badgeCount, setBadgeCount };
};

export default useBadges;
