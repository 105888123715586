// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Switch, Tooltip } from "@mui/material";
import useEditContent from "../../hooks/useEditContent";
import { AmpDataSubmitTypes } from "../../lib/util/enums";

interface Props {
  isActive: boolean;
  id: string;
}

// Display a controlled switch component
// Toggles current card active / inactive
const AmpSwitch = ({ isActive, id }: Props) => {
  // Global
  const { t } = useTranslation();

  // Local
  const [value, setValue] = useState<boolean>(isActive);
  const { submitEdits, isLoading } = useEditContent({
    contentId: id,
  });

  const handleClick = () => {
    const newValue = !value;
    // Submit change to Opensearch
    submitEdits("active", newValue);
    // Reflect change locally
    setValue(newValue);
  };

  return isLoading ? (
    <Box height="24px" width="40px" display="flex" alignItems={"center"}>
      {isLoading}
    </Box>
  ) : (
    <Tooltip
      title={
        value === true
          ? t("cards.tooltip.SetArchived")
          : t("cards.tooltip.SetNotArchived")
      }
    >
      <Switch
        defaultChecked={value}
        size="small"
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default AmpSwitch;
