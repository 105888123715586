// Globals
import { useState, useEffect } from "react";

// Local
import { Evidence } from "../models/Evidence";
import { TranslationState } from "../lib/util/enums";
import { maxTextLength } from "../lib/configs";
import { truncateText } from "../lib/util/formatters";

interface Props {
  evidence: Evidence;
  userLang: string;
  isDetailView?: boolean;
  isExample?: boolean;
}

// Function returns evidence text in the correct language
// Based on user preferences and actions
const useEvidenceText = ({ evidence, userLang, isDetailView, isExample }: Props) => {
  const [translationState, setTranslationState] = useState<TranslationState>();

  // This effect refreshes state management for Translate button
  // Fires on load and on userLang changes
  useEffect(() => {
    if (
      !!evidence.textTranslated &&
      !!(userLang in evidence.textTranslated) &&
      !!(evidence.detectedLanguage !== userLang)
    ) {
      setTranslationState(TranslationState.translated);
    } else {
      setTranslationState(TranslationState.native);
    }
  }, [evidence, userLang]);

  // Toggle translation state
  const handleTranslationToggle = () => {
    const newState =
      translationState === TranslationState.translated
        ? TranslationState.untranslated
        : TranslationState.translated;
    setTranslationState(newState);
  };

  // Display text per translationState
  let text =
    translationState !== TranslationState.translated
      ? evidence.text
      : evidence?.textTranslated?.[userLang];

  text = truncateText({ isNotTruncated: isDetailView, isExtraTruncated: isExample, text });

  return { text, translationState, handleTranslationToggle };
};

export default useEvidenceText;
