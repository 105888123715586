import { Typography } from "@mui/material";

export default function RequiredIcon() {
  return (
    <Typography
      sx={{
        display: "inline",
        fontSize: "1.25rem",
        fontWeight: "700",
        color: "red",
      }}
    >
      *
    </Typography>
  );
}
