import { useTranslation } from "react-i18next";

// MUI Imports
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BoltIcon from "@mui/icons-material/Bolt";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import InsightsIcon from "@mui/icons-material/Insights";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HomeIcon from "@mui/icons-material/Home";
import EditNoteIcon from '@mui/icons-material/EditNote';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

type Props = {
  text: string;
  type?: string; // set to SECONDARY or ACCENT if desired
  symbol?: string; // add a MUI icon
  disabled?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  onClick?: (event: any) => void;
};

// Return a primary, secondary, or accent button with TEXT label
// A "symbol" prop returns a MUI icon symbol after the text (needs hardcoding)

const AmpButton = ({ text, type, symbol, disabled, fullWidth, autoFocus, onClick }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const finalFullWidth = isSmallScreen ? true : fullWidth;

  const leftIcons = {
    arrowBack: ArrowBackIcon,
    check: CheckIcon,
    plus: AddIcon,
    print: PrintIcon,
    analyze: TroubleshootIcon,
    insight: InsightsIcon,
    view: VisibilityIcon,
    home: HomeIcon,
    bolt: BoltIcon,
    edit: EditNoteIcon
  };

  const rightIcons = {
    arrowForward: ArrowForwardIcon,
    arrowDown: KeyboardArrowDownIcon,
  };

  const LeftIcons =
    symbol && leftIcons.hasOwnProperty(symbol) ? leftIcons[symbol as keyof typeof leftIcons] : null;
  const RightIcons =
    symbol && rightIcons.hasOwnProperty(symbol)
      ? rightIcons[symbol as keyof typeof rightIcons]
      : null;

  return (
    <Button
      variant={
        type === "secondary"
          ? "outlined"
          : type === "accent"
          ? "accent"
          : type === "text"
          ? "text"
          : "contained"
      }
      onClick={onClick}
      disabled={disabled}
      fullWidth={finalFullWidth}
      autoFocus={autoFocus}
    >
      {LeftIcons && <LeftIcons sx={{ marginRight: "0.25rem", fontSize: "1rem" }} />}

      {t("buttons." + text)}

      {RightIcons && <RightIcons sx={{ marginLeft: "0.25rem", fontSize: "1rem" }} />}
    </Button>
  );
};

export default AmpButton;
