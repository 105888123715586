// Global
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// MUI
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

// Local
import useAuthToken from "./hooks/useAuthToken";
import {
  ApiTokenContext,
  IssuesContext,
  ThemesContext,
  CountriesContext,
} from "./contexts/InitContexts";
import CircleLoading from "./components/navigation/CircleLoading";
import Navbar from "./components/navigation/Navbar";
import { LocationQueryTypes, RouteOptions } from "./lib/util/enums";
import useThemesIssues from "./hooks/useThemesIssues";
import { UnauthorizedRoute } from "./components/navigation/AuthZ";
import { getStage } from "./lib/util/formatters";
import useLocations from "./hooks/useLocations";

const isLoadingPage = (
  <div style={{ width: "100vw" }}>
    <CircleLoading />
  </div>
);

// Auth0 function
export default withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => isLoadingPage,
});

function App() {
  const location = useLocation();
  const apiToken = useAuthToken();

  // Get themes and issues, put into context
  const { themes, issues } = useThemesIssues(apiToken);
  const locations = useLocations({type: LocationQueryTypes.Countries});

  // AuthN access to this environment
  const { user, isLoading } = useAuth0();
  const stage = getStage();

  const environmentAuth = () => {
    if (stage !== "prod" && !user?.["https://amp.rootwise.co/environments"]?.includes(stage)) {
      return <UnauthorizedRoute environment />;
    } else {
      return <Outlet />;
    }
  };

  if (isLoading) {
    return isLoadingPage;
  }

  return (
    <ApiTokenContext.Provider value={apiToken}>
      <ThemesContext.Provider value={themes}>
        <IssuesContext.Provider value={issues}>
          <CountriesContext.Provider value={locations.countries}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="App">
                {/* Navbar (hide on Submit route) */}
                {location.pathname !== RouteOptions.submit &&
                  location.pathname !== RouteOptions.reviewSubmit && <Navbar />}
                {/* Display currently applicable view */}
                {environmentAuth()}
              </div>
            </LocalizationProvider>
          </CountriesContext.Provider>
        </IssuesContext.Provider>
      </ThemesContext.Provider>
    </ApiTokenContext.Provider>
  );
}
