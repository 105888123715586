// Globals
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { authToken } from "../lib/api/keyConstants";

// TODO: Adam mentioned storageLocal, token expiration
// Investigate and complete. apiToken state temp solution.

interface QueryProps {
  queryKey: [string, { getAccessTokenSilently: Function }];
}

// get Auth0 token business logic
const getToken = async ({ queryKey }: QueryProps) => {
  const [_key, { getAccessTokenSilently }] = queryKey;

  const apiToken = await getAccessTokenSilently({
    authorizationParams: {
      audience: "https://africamisinformationportal.us.auth0.com/api/v2/",
    },
  });
  return apiToken;
};

// get Auth0 state management
const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { error, data } = useQuery({
    queryKey: [authToken, { getAccessTokenSilently }],
    queryFn: getToken,
  });

  if (error instanceof Error) {
    throw error;
  }

  // Success:
  return data;
};

export default useAuthToken;
