// Global
import { useTranslation } from "react-i18next";

// MUI
import { Stack, Typography } from "@mui/material";
import { AmpBadges } from "../../lib/util/enums";

interface Props {
  badgeCount?: number;
}

interface BadgeStatus {
  tier: AmpBadges;
  isNewlyEarned: boolean;
}

// Calculate correct badge status
const calcBadgeStatus = ({ badgeCount }: Props) => {
  if (badgeCount === undefined) {
    return undefined;
  }
  const badgeStatus: BadgeStatus = {
    isNewlyEarned: false,
    tier:
      badgeCount > 9
        ? AmpBadges.Gold
        : badgeCount > 4
        ? AmpBadges.Silver
        : AmpBadges.Bronze,
  };

  const thresholds = [1, 5, 10];
  if (thresholds.includes(badgeCount)) {
    badgeStatus.isNewlyEarned = true;
  }
  return badgeStatus;
};

// Process and display user badges
const ShowBadge = ({ badgeCount }: Props) => {
  // Global hooks
  const { t } = useTranslation();

  // Calculate badges
  const badgeStatus = calcBadgeStatus({ badgeCount });

  return badgeStatus === undefined ? null : (
    <Stack alignItems={"center"}>
      <img
        src={"/icons/Check" + badgeStatus.tier + ".svg"}
        alt="Badge"
        height={100}
      />

      <Typography variant="h3" marginTop={1}>
        {t("badgeMessage.pre") + badgeCount + t("badgeMessage.post")}
      </Typography>
      {/* Add a notice if new badge earned */}
      {badgeStatus.isNewlyEarned && (
        <Typography variant="h3" mt={2}>
          {t("badgeMessage.newTier." + badgeStatus.tier)}
        </Typography>
      )}
    </Stack>
  );
};

export default ShowBadge;
