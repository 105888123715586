import { COLORS } from "./default";

export const getChipStyles = (style: string) => {
  if (style === "grayStatic") {
    return {
      backgroundColor: COLORS.backgroundGray,
      color: COLORS.primaryBlue,
      marginTop: 0.5,
      border: 0,
      "&:hover": {
        backgroundColor: COLORS.backgroundGray,
        color: COLORS.primaryBlue,
      },
    };
  }
  if (style === "whiteBacked") {
    return {
      backgroundColor: COLORS.white,
      color: COLORS.primaryBlue,
      marginTop: 0.5,
      "&:hover": {
        backgroundColor: COLORS.backgroundGray,
        color: COLORS.primaryBlue,
      },
    };
  }
};
