import { createTheme } from "@mui/material";

// Documentation:
// https://mui.com/material-ui/customization/palette/

// Constant widths and sizes for export
export const mainStageMinHeight = "300px";
export const appMaxWidth = "1440px";
export const appMobileWidth = "90vw";
export const ampLogoSize = { sm: "77px", md: "77px" };
export const subNavHeight = { sm: "auto", md: "62px" };
export const mainStageMax = "1400px"

// Forms
export const minFormWidth = "300px";
export const numberFormSize = "125px";
export const landingPageForms = "300px";

// Card design
export const cardMaxWidth = 650; // fixed pixels
export const cardMinWidth = 420; // fixed pixels
export const stripeWidth = 14; // fixed pixels
export const cardBodyWidth = cardMaxWidth - stripeWidth;
export const attachmentMaxHeight = 325;

// Map and chart visual configurations
export const chartWidth: number = 500;
export const lineChartHeight: number = 250;
export const mapHeight: number = 400;
export const minimapHeight: number = 200;
export const mapDefaultCoordinates: [number, number] = [-1, 18];
export const mapDefaultZoom: number = 3;
export const minimapDefaultZoom: number = 2;

// Generated Report
export const logoMaxHeight: string = "125px"
export const logoMaxWidth: string = "300px"

// Response cards
export const responseCardSize = {
  WHO: 300,
  Twitter: 325,
  Facebook: 398,
  DefaultContentHeight: 100
};

// Typography constants
export const avatarSize = "2rem";
export const smallFontSize = "0.9rem";
export const defaultFontSize = "1rem";
export const largeFontSize = "1.1rem";

// Breakpoints
const breakpoints = { md: '900px' };

// Color palette
export const COLORS = {
  backgroundGray: "#F3F3F6",
  primaryBlue: "#0D3B66",
  hoverBlue: "#1565C0",
  airaPink: "#DF4E83",
  secondaryGray: "#8F9BB3",
  borderGray: "rgba(0, 0, 0, 0.26)",
  nearBlack: "#08080A",
  white: "#fff",
  black: "#000",
  darkRed: "#B22222",
  darkGreen: "#006400", // A darker green that fits the palette

  success: "#4CAF50",
};

// Button style customization typing
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    accent: true;
  }
}

// AMP Custom Theme
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primaryBlue,
      light: COLORS.secondaryGray,
    },
    secondary: {
      main: COLORS.airaPink,
    },
    error: {
      main: COLORS.darkRed,
    },
    success: {
      main: COLORS.darkGreen, // A green shade that fits the palette
    },
    info: {
      main: COLORS.secondaryGray,
    },
  },
  typography: {
    fontFamily: ["Manrope", "Helvetica", "Arial"].join(","),
    h1: {
      // Main stage headers
      fontSize: "1.25rem",
      fontWeight: 500,
      color: COLORS.black,
      [`@media (min-width:${breakpoints.md})`]: {
        fontSize: "1.8rem",
      },
    },
    h2: {
      // Main stage headers
      fontSize: "1.15rem",
      fontWeight: 500,
      color: COLORS.black,
      [`@media (min-width:${breakpoints.md})`]: {
        fontSize: "1.5rem",
      },
    },
    // h3: Black subtitle
    h3: {
      fontSize: "1rem",
      fontWeight: 600,
      color: COLORS.nearBlack,
    },
    // h4: Gray subtitle
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
      color: COLORS.secondaryGray,
    },
    // h5: White subtitle
    h5: {
      fontSize: "1rem",
      fontWeight: 600,
      color: COLORS.white,
    },
    h6: {
      fontSize: smallFontSize,
      color: COLORS.airaPink,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 700,
      color: COLORS.airaPink,
    },
    subtitle2: {
      fontSize: "1.15rem",
      fontWeight: 600,
      color: COLORS.nearBlack,
    },
    body1: {
      fontSize: smallFontSize,
      fontWeight: 600,
      color: COLORS.nearBlack,
    },

    body2: {
      fontFamily: "Helvetica",
      fontSize: smallFontSize,
    },
    caption: {
      fontFamily: "Courier New, monospace",
      fontSize: smallFontSize,
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&:hover": {
            color: COLORS.hoverBlue,
          },
        },
      },
      variants: [
        {
          // New Variant: "isNotButton" (no clicking)
          props: { className: "isNotButton" },
          style: {
            cursor: "auto",
            "&:hover": {
              color: "inherit",
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        underlineNone: {
          color: COLORS.secondaryGray,
          cursor: "pointer",
          "&:hover": {
            color: COLORS.hoverBlue,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
          fontWeight: 700,
        },
        // Style override for "contained" primary button
        contained: {
          color: COLORS.white,
          backgroundColor: COLORS.primaryBlue,
          [`@media (min-width:${breakpoints.md})`]: {
            "&:hover": {
              backgroundColor: COLORS.hoverBlue,
            },
          },
        },
        // Style override for "outlined" secondary button
        outlined: {
          color: COLORS.primaryBlue,
          borderColor: COLORS.primaryBlue,
          [`@media (min-width:${breakpoints.md})`]: {
            "&:hover": {
              backgroundColor: COLORS.hoverBlue,
              color: COLORS.white,
            },
          },
        },
      },
      variants: [
        {
          // New Variant: "accent" (contained + pink)
          props: { variant: "accent" },
          style: {
            color: COLORS.white,
            backgroundColor: COLORS.airaPink,
            "&:hover": {
              backgroundColor: COLORS.hoverBlue,
            },
          },
        },
      ],
    },
    // Style override for textfield input
    MuiTextField: {
      styleOverrides: {
        root: {
          color: COLORS.white,
          backgroundColor: COLORS.white,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          fontWeight: 700,
        },
        deleteIcon: {
          color: COLORS.white,
          fontSize: "large",
        },
        // MUI Chip Filled
        filled: {
          color: COLORS.white,
          backgroundColor: COLORS.primaryBlue,
          "&:hover": {
            backgroundColor: COLORS.hoverBlue,
            color: COLORS.white,
          },
        },
        // MUI Chip Outlined
        outlined: {
          color: COLORS.primaryBlue,
          "&:hover": {
            backgroundColor: COLORS.primaryBlue,
            color: COLORS.hoverBlue,
          },
        },
      },
    },
    // Mobile Guide  (Stepper)
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: COLORS.airaPink,
          },
          "&.Mui-completed": {
            color: COLORS.airaPink,
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          // maxWidth: '100%',
          maxHeight: attachmentMaxHeight,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: largeFontSize,
          fontFamily:["Manrope", "Helvetica", "Arial"].join(","),
          "&.Mui-selected": {
            fontWeight: 700,
            color: COLORS.primaryBlue,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          fontFamily: ["Manrope", "Helvetica", "Arial"].join(","),
        },
      },
    },
  },
});

export default theme;
