export class Theme {
  readonly id: string;
  readonly label: string;
  readonly group: string;
  readonly description?: string;

  constructor(id: string, label: string, group?: string, description?: string) {
    this.id = id;
    this.label = label;
    this.group = group || "Other";
    this.description = description;
  }
}
