// Global
import { useTranslation } from "react-i18next";

// MUI
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Box, Tooltip } from "@mui/material";

interface Props {
  url: string;
}
const AttachmentIcon = ({ url }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent={"center"}>
      {" "}
      <a href={url} download>
        <Tooltip title={t("cards.tooltip.DownloadAttachment")}>
          <TextSnippetIcon sx={{ fontSize: "3.5rem" }} />
        </Tooltip>
      </a>
    </Box>
  );
};

export default AttachmentIcon;
