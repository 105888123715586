// Local
import useAxios from "../../hooks/useAxios";
import { AddContentFormData } from "../../models/Content";
import { FlagForReviewData } from "../../models/Email";
import { AddEvidenceForm } from "../addEvidenceForms/AddEvidenceForm";
import { AmpDataSubmitTypes } from "./enums";

interface Props {
  submitType: AmpDataSubmitTypes;
  data: AddContentFormData | FlagForReviewData | AddEvidenceForm | any;
  apiToken: string;
}

// Biz logic: Submit evidence to /addEvidence endpoint
const submitData = async ({ submitType, data, apiToken }: Props) => {
  const response = await useAxios({
    token: apiToken,
    method: "POST",
    endpoint:
      submitType === AmpDataSubmitTypes.Evidence
        ? "addEvidence"
        : submitType === AmpDataSubmitTypes.Content
        ? "addContent"
        : submitType === AmpDataSubmitTypes.Email
        ? "sendEmail"
        : submitType === AmpDataSubmitTypes.GeneratedReport
        ? "updateReport"
        : submitType === AmpDataSubmitTypes.UserRegistration
        ? "addUserRequest"
        : undefined,
    data: data,
  });

  if (response.status !== 200) {
    throw new Error(`Server error code: ${response.status}`);
  }

  return response;
};
export default submitData;
