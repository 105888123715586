// Globals
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

// Import MUI & MUI
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { Form, Formik } from "formik";
import * as Yup from "yup";

// Import Local
import AmpLogo from "../components/landingPage/AmpLogo";
import AddEvidenceGuide from "../components/addEvidence/AddEvidenceGuide";
import AddEvidenceGuideStepsMobile from "../components/addEvidence/AddEvidenceGuideStepsMobile";
import AddEvidenceNavButtons from "../components/addEvidence/AddEvidenceNavButtons";
import AddEvidenceSource from "../components/addEvidence/AddEvidenceSource";
import AddEvidenceDetail from "../components/addEvidence/AddEvidenceDetail";
import AddEvidenceSubmitted from "../components/addEvidence/AddEvidenceSubmitted";
import MobileX from "../components/buttons/MobileX";
import { AmpDataSubmitTypes, RouteOptions } from "../lib/util/enums";
import { ApiTokenContext } from "../contexts/InitContexts";
import { generateUniqueId } from "../lib/util/generators";
import {
  AddEvidenceForm,
  formikEvidenceFormValues,
  formikValidationSchema,
} from "../lib/addEvidenceForms/AddEvidenceForm";
import useBadges from "../hooks/useBadges";
import AlertDialogSlide from "../components/AlertSlideDialog";
import cleanFormData from "../lib/addEvidenceForms/cleanFormData";
import submitData from "../lib/util/submitData";
import useStepHandler from "../hooks/useStepHandler";
import useCustomError from "../hooks/useCustomError";
import AddEvidenceEssentials from "../components/addEvidence/AddEvidenceEssentials";
import useAddAttachments from "../hooks/useAddAttachments";
import { AttachmentHandlerProps } from "../lib/util/interfaces";
import useSendEmail from "../hooks/useSendEmail";
import { EmailTypes } from "../models/Email";
import useReviewSubmit from "../hooks/useReviewSubmit";
import { Evidence } from "../models/Evidence";
import { formatEvidenceForReviewSubmit } from "../lib/util/formatters";
import EvidenceAttachments from "../components/cards/EvidenceAttachments";
import CircleLoading from "../components/navigation/CircleLoading";
import MainStage from "../components/navigation/MainStage";
import useScrapeURL from "../hooks/useScrapeURL";
import TextFieldInput from "../components/addEvidence/TextFieldInput";

interface Props {
  isReviewSubmit?: boolean;
}

// This function manages the entire input evidence workflow
const AddEvidence = ({ isReviewSubmit }: Props) => {
  // Global hooks
  const { t } = useTranslation();
  const { user } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const apiToken = useContext(ApiTokenContext);
  const navigate = useNavigate();

  // Local hooks and state
  const { customError, setCustomError } = useCustomError();
  const [emailDialogue, setEmailDialogue] = useState<boolean>(false);
  const { badgeCount, setBadgeCount } = useBadges();
  const stepHandler = useStepHandler();

  // Set initial form data with user preferred country
  const defaultCountry =
    user && user["https://amp.rootwise.co/country"]
      ? [user["https://amp.rootwise.co/country"]]
      : undefined;
  const initialFormData = { id: generateUniqueId(), locations: defaultCountry } as AddEvidenceForm;
  const [formData, setFormData] = useState(initialFormData);
  const [resetFormikKey, setResetFormikKey] = useState(0);
  const [showingAllEssentialFields, setShowingAllEssentialFields] = useState<boolean>(false);
  const [isSubmitForLater, setIsSubmitForLater] = useState<boolean>(false);
  const [preventSubmitForLater, setPreventSubmitForLater] = useState(false);

  // If isReviewSubmit, get evidence by Id and trigger useEffect
  const evidenceUnderReview = useReviewSubmit({ isReviewSubmit });

  useEffect(() => {
    if (evidenceUnderReview instanceof Evidence) {
      if (formData.id === evidenceUnderReview.id) {
        return; // Prevent infinite loop
      }
      if (stepHandler.currentStep !== 2) {
        // Go to Step 2
        stepHandler.changeStep(2);
        stepHandler.setIsDetailAdd(true);
      }
      // Populate the form with the evidence under review
      setFormData(formatEvidenceForReviewSubmit(evidenceUnderReview));
    }
  }, [evidenceUnderReview]);

  // Send email for Needs Detail event
  const { setEmailDescription, handleSendEmail } = useSendEmail({
    evidence: formData,
    user,
  });

  /* Submit workflow:  
  1. User action triggers stepHandler.isSubmitting 
  2. useAddAttachments is triggered
  3. finishSubmit mutation triggered
  4. Mutation submits data to OpenSearch
  */
  const mutation = useMutation({
    mutationFn: submitData,
    onMutate: () => {
      setIsSubmitForLater(false);
    },
    onError: (error: Error) => {
      setCustomError({ message: error.message });
    },
    onSuccess: () => {
      // Update badge count, current step on mutation success or throw error
      if (isReviewSubmit) {
        // Alert Dialogue to send email
        setEmailDialogue(true);
      } else {
        stepHandler.setIsSubmitting(false);
        setBadgeCount(badgeCount !== undefined ? badgeCount + 1 : 1);
        stepHandler.changeStep(4);
      }
    },
  });

  // Update formData
  const updateFormData = (values: object) => {
    // Ensure locations don't overwrite existing locations from other countries
    setFormData({ ...formData, ...values });
    console.log("Form data:", { ...formData, ...values });
  };

  // Finish the submission, triggered when binary uploads complete
  const finishSubmit = (values: object) => {
    const currentFormData = formData;
    if (!currentFormData.authorId) {
      currentFormData.authorId = user?.name;
    }
    const finalFormData = { ...currentFormData, ...values };
    const cleanedData = cleanFormData({ formData: finalFormData, isReviewSubmit });
    // Prepare data for emails
    setEmailDescription(JSON.stringify(cleanedData), "data");
    // Submit the data to Opensearch
    mutation.mutate({
      submitType: AmpDataSubmitTypes.Evidence,
      data: cleanedData,
      apiToken,
    });
  };

  // Reset parameters for a new submission
  const submitAgain = (isCancel?: boolean) => {
    console.log("Submit Again");
    mutation.reset();
    setFormData(initialFormData);
    setResetFormikKey((prevKey) => prevKey + 1);
    setIsSubmitForLater(false);
    setPreventSubmitForLater(false);
    attachmentHandler.setFileObjList([]);
    setEmailDescription("");
    stepHandler.setIsSubmitting(false);
    stepHandler.setIsDetailAdd(false);
    isCancel ? null : stepHandler.changeStep(1);
    console.log("formData:", formData);
  };

  const attachmentHandler: AttachmentHandlerProps = useAddAttachments({
    formData,
    apiToken,
    finishSubmit,
  });

  // Display dialog if submission attempt is made with empty text field
  useEffect(() => {
    if (stepHandler.isSubmitting && !preventSubmitForLater) {
      if (!formData.platformObject?.isQuickAddType && formData.text === undefined) {
        stepHandler.setIsSubmitting(false);
        setIsSubmitForLater(true);
      } else {
        attachmentHandler.doUpload();
      }
    }
  }, [formData.platformObject?.isQuickAddType, stepHandler.isSubmitting, formData.text]);

  // Support for handleAddDetail: scraping URLs
  const { handleScrapeUrl } = useScrapeURL({
    apiToken,
    stepHandler,
    updateFormData,
    setCustomError,
  });

  const handleAddDetail = () => {
    // When user clicks "AddDetail" -->
    if (formData.platformObject?.isQuickAddType && formData.url) {
      // 1. If its a QuickAdd type, scrape the URL
      handleScrapeUrl(formData.url);
    } else {
      if (showingAllEssentialFields) {
        // 2. If all fields already showing, step forward
        stepHandler.changeStep("f");
      } else {
        // 3. Otherwise open up all form fields
        stepHandler.setIsDetailAdd(true);
      }
    }
  };

  return isReviewSubmit && !formData.date ? (
    <MainStage>
      <CircleLoading />
    </MainStage>
  ) : (
    <Formik
      key={resetFormikKey}
      initialValues={formikEvidenceFormValues(formData)}
      validationSchema={Yup.object(formikValidationSchema)}
      onSubmit={(values, { setSubmitting }) => {
        updateFormData(values);
        setSubmitting(false);
      }}
    >
      <Form style={{ height: "100%" }}>
        <Grid container minHeight={"100vh"}>
          <Grid md={0} xl={2} />
          <Grid xs={12} md={8} xl={6} justifyContent={"center"}>
            <Box paddingLeft={{ xs: 2, md: 5 }} paddingRight={{ xs: 2, md: 5 }}>
              <Stack>
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
                  <Grid xs={2} md={0}></Grid>
                  <Grid xs={8} md={12}>
                    <AmpLogo />
                  </Grid>

                  <Grid xs={2} md={0} marginTop={1} display="flex" justifyContent={"flex-end"}>
                    {/* Mobile cancel button */}
                    {isSmallScreen && (
                      <MobileX isReviewSubmit={isReviewSubmit} submitAgain={submitAgain} />
                    )}
                  </Grid>
                  <Grid xs={12} md={0}>
                    {/* Mobile Stepper */}
                    {isSmallScreen && (
                      <AddEvidenceGuideStepsMobile currentStep={stepHandler.currentStep} />
                    )}
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Typography variant="h1">
                    {/* Header */}
                    {stepHandler.currentStep < 4 && t("inputStepHeader." + stepHandler.currentStep)}
                  </Typography>
                </Box>

                {/* Lazy loading for each of the pages in the addEvidence wizard */}
                {stepHandler.currentStep === 1 && (
                  <AddEvidenceSource
                    handleAllowNext={stepHandler.setAllowNext}
                    updateFormData={updateFormData}
                    formData={formData}
                    stepDirection={stepHandler.changeStep}
                  />
                )}
                {stepHandler.currentStep === 2 && (
                  <AddEvidenceEssentials
                    setShowingAllEssentialFields={setShowingAllEssentialFields}
                    handleAllowNext={stepHandler.setAllowNext}
                    updateFormData={updateFormData}
                    formData={formData}
                    attachmentHandler={attachmentHandler}
                    stepHandler={stepHandler}
                  />
                )}
                {stepHandler.currentStep === 3 && (
                  <AddEvidenceDetail
                    handleAllowNext={stepHandler.setAllowNext}
                    updateFormData={updateFormData}
                    formData={formData}
                  />
                )}
                {stepHandler.currentStep === 4 && (
                  <AddEvidenceSubmitted
                    submitAgain={submitAgain}
                    badgeCount={badgeCount}
                    isReviewSubmit={isReviewSubmit}
                  />
                )}
                <Box marginTop={2} marginBottom={2}>
                  {stepHandler.currentStep < 4 && (
                    <AddEvidenceNavButtons
                      stepHandler={stepHandler}
                      isSubmitForLater={isSubmitForLater}
                      isReviewSubmit={isReviewSubmit}
                      submitAgain={() => submitAgain(true)}
                      handleAddDetail={handleAddDetail}
                    />
                  )}
                </Box>
                {(isReviewSubmit || stepHandler.isDetailAdd) &&
                  !stepHandler.isSubmitting &&
                  formData?.attachments &&
                  formData.attachments.length > 0 && (
                    <Box>
                      <Typography variant="h3">{t("forms.existingAttachments")}</Typography>
                      <Box maxHeight={200} maxWidth={200}>
                        {" "}
                        <EvidenceAttachments attachments={formData.attachments} />
                      </Box>
                    </Box>
                  )}
              </Stack>
            </Box>
          </Grid>
          <Grid xs={0} md={4} xl={4}>
            <AddEvidenceGuide currentStep={stepHandler.currentStep} />
          </Grid>
          {/* Dialogue Menu States */}
          {/* Guide User WRT Submitting W/O Text */}
          {isSubmitForLater && (
            <AlertDialogSlide
              title={"signalText.Title"}
              message={"signalText.Message"}
              message2={"signalText.Message2"}
              confirm={"Submit"}
              cancel="Cancel"
              showCancel
              // Opened conditional on user workflow
              isOpen={isSubmitForLater}
              // On confirm, close dialogue and step forward
              handleUserChoice={(userChoice: string) => {
                if (userChoice === "confirm") {
                  setIsSubmitForLater(false);
                  setPreventSubmitForLater(true);
                  attachmentHandler.doUpload();
                  stepHandler.setIsSubmitting(true);
                } else {
                  // updateFormData({ text: "" });
                  stepHandler.setIsSubmitting(false);
                  setIsSubmitForLater(false);
                }
              }}
            >
              <TextFieldInput field="text" rows={3} rowsMobile={4} required={false} />
            </AlertDialogSlide>
          )}
          {/* Error Submitting Message */}
          {!!customError &&
            customError.message.length > 0 &&
            !customError.message.includes("503") && (
              <AlertDialogSlide
                title={"errors.addEvidence.Title"}
                message={"errors.addEvidence.Message"}
                confirm="Confirm"
                // Open on error message
                isOpen={!!customError && customError.message.length > 0}
                // On confirm, close dialogue and step forward
                handleUserChoice={() => {
                  stepHandler.setIsSubmitting(false);
                  setCustomError(undefined);
                  mutation.reset();
                }}
              />
            )}
          {/* Error Scraping Message */}
          {!!customError && customError.message.includes("503") && (
            <AlertDialogSlide
              title={"errors.scrapeFail.Title"}
              message={"errors.scrapeFail.Message"}
              confirm="Confirm"
              // Open on error message
              isOpen={!!customError && customError.message.length > 0}
              // On confirm, close dialogue and step forward
              handleUserChoice={() => {
                stepHandler.setIsSubmitting(false);
                stepHandler.setIsDetailAdd(true);
                setCustomError(undefined);
                mutation.reset();
              }}
            />
          )}
          {emailDialogue && (
            <AlertDialogSlide
              title={"reviewSubmit.Title"}
              message={"reviewSubmit.Message"}
              showCancel
              // Open on error message
              isOpen={emailDialogue}
              // On confirm, close dialogue and step forward
              handleUserChoice={(userChoice: string) => {
                if (userChoice === "confirm") {
                  handleSendEmail(EmailTypes.ReviewComplete);
                }
                navigate(RouteOptions.QA);
              }}
            />
          )}{" "}
        </Grid>
      </Form>
    </Formik>
  );
};

export default AddEvidence;
