// Global
import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { Content } from "../models/Content";

// Local
import { ApiTokenContext } from "../contexts/InitContexts";
import LinearLoader from "../components/navigation/LinearLoading";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";

interface Props {
  contentId: string;
}

const useEditContent = ({ contentId }: Props) => {
  // Global hooks
  const apiToken = useContext(ApiTokenContext);
  const loader = <LinearLoader />;
  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting update to database!");
    },
  });

  // Submit Edits to Opensearch
  const submitEdits = (
    type: keyof Content,
    newValues: string | string[] | boolean
  ) => {
    const updatedData = {
      id: contentId,
      updatedAt: new Date(),
      [type.toString()]: newValues,
    };
    mutation.mutate({
      submitType: AmpDataSubmitTypes.Content,
      data: updatedData,
      apiToken,
    });
    console.log("Submitting edited data:", updatedData);
  };

  return {
    submitEdits,
    isLoading: !!mutation.isLoading ? loader : null,
  };
};

export default useEditContent;
