// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography } from "@mui/material";

// Local
import { ValueCountPair } from "../../models/Evidence";
import { COLORS } from "../../lib/theme/default";

interface Props {
  sentimentList?: ValueCountPair[];
}

const SentimentChart = (props: Props) => {
  if (!props.sentimentList || props.sentimentList.length < 1) {
    return null;
  }

  // Global
  const { t } = useTranslation();

  // Create an object with properties corresponding to the "value" of each item in props.sentimentList
  // The value of the property shall be the count
  const sentimentCounts = props.sentimentList.reduce((acc, sentiment) => {
    acc[sentiment.value] = sentiment.count;
    return acc;
  }, {} as { [key: string]: number });

  return (
      <Box>
        <Box display="flex" justifyContent="center" my={1}>
          <Typography variant="h3">{t("charts.sentiment.title")}</Typography>
        </Box>
        <Stack direction="row" justifyContent={"center"} alignItems={"center"} spacing={2}>
          {!!sentimentCounts.positive && (
            <Stack direction="row" display="flex" alignItems={"center"} spacing={1}>
              <Typography variant="h2" color={COLORS.success}>
                {sentimentCounts.positive}
              </Typography>
              <Typography variant="h4" color={COLORS.success}>
                {" "}
                {t("generateReport.sentiment.Positive")}{" "}
              </Typography>
            </Stack>
          )}
          {!!sentimentCounts.neutral && (
            <Stack direction="row" display="flex" alignItems={"center"} spacing={1}>
              <Typography variant="h2" color={COLORS.secondaryGray}>
                {sentimentCounts.neutral}
              </Typography>
              <Typography variant="h4" color={COLORS.secondaryGray}>
                {" "}
                {t("generateReport.sentiment.Neutral")}{" "}
              </Typography>
            </Stack>
          )}
          {!!sentimentCounts.negative && (
            <Stack direction="row" display="flex" alignItems={"center"} spacing={1}>
              <Typography variant="h2" color={"error"}>
                {sentimentCounts.negative}
              </Typography>
              <Typography variant="h4" color={"error"}>
                {" "}
                {t("generateReport.sentiment.Negative")}{" "}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
  );
};

export default SentimentChart;
