// Global
import { useTranslation } from "react-i18next";
import { Field } from "formik";

// Import MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Chip from "@mui/material/Chip";

// Local
import RequiredIcon from "./RequiredIcon";

interface Props {
  label: string;
  values: string[] | string;
  selectedValues?: string[] | string;
  exclusive?: boolean;
  required?: boolean;
  showTitle?: boolean;
  isDisabled?: boolean;
  updateFormData: (
    values: Record<string, string> | Record<string, string[]>
  ) => void;
}

// Build a text field component
const ChipInput = ({
  label,
  values,
  selectedValues,
  exclusive,
  required,
  showTitle,
  isDisabled,
  updateFormData,
}: Props) => {
  // Globals
  const { t } = useTranslation();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  // Forces values and selectedValues to be arrays in this component
  // If the data is Exclusive (no array), handleClick will return a string
  const valuesArray = Array.isArray(values) ? values : [values];
  const selectedValuesArray = Array.isArray(selectedValues)
    ? selectedValues
    : selectedValues !== undefined
    ? [selectedValues]
    : [];

  // Update which chips are selectedValues
  const handleClick = (value: string) => {
    if (exclusive) {
      // This path ensures maximum one chip is selectedValues (active)
      const data = {} as Record<string, string>;
      // Lookup if user click adds or removes the value
      data[label] = selectedValuesArray?.includes(value) ? "" : value;
      updateFormData(data);
    } else {
      const data = {} as Record<string, string[]>;
      // Add or remove the chip from the formData
      if (selectedValuesArray?.includes(value)) {
        data[label] = selectedValuesArray.filter(
          (item: string | null) => item !== value
        );
      } else {
        data[label] = [...selectedValuesArray, value];
      }
      updateFormData(data);
    }
  };

  return (
    <Box>
      {showTitle && (
        <Typography variant="h3">
          {t("forms.chipGroups." + label + ".label")}{" "}
          {required && <RequiredIcon />}
        </Typography>
      )}
      {/* Map each of the individual chips */}
      <Grid
        container
        spacing={1}
        mx={isMediumScreen ? 0.5 : 0}
        justifyContent={isMediumScreen ? "center" : "start"}
      >
        {valuesArray.map((value, index) => (
          <Grid key={index}>
            <Field
              component={Chip}
              name={value}
              label={t("forms.chipGroups." + label + "." + value)}
              variant={
                selectedValuesArray?.includes(value) ? "filled" : "outlined"
              }
              onClick={() => handleClick(value)}
              disabled={isDisabled}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ChipInput;
