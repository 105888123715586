// Global
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Import MUI
import { Box, Stack } from "@mui/material";
import AmpButton from "../buttons/AmpButton";

// Import local
import ShowBadge from "./ShowBadge";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  badgeCount?: number;
  isReviewSubmit?: boolean;
  submitAgain: () => void;
}

// Display success screen for submitEvidence or ReviewSubmit
const AddEvidenceSubmitted = ({ badgeCount, isReviewSubmit, submitAgain }: Props) => {
  // Global
  const navigate = useNavigate();
  const { user } = useAuth0();
  const isAdmin = "admin" === user?.["https://amp.rootwise.co/role"];

  const handleSubmitAgain = () => {
    isReviewSubmit ? navigate(RouteOptions.QA) : submitAgain();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <ShowBadge badgeCount={badgeCount} />

      <Stack spacing={2} width={300} marginTop={4}>
        <AmpButton text="SubmitAgain" symbol="plus" onClick={() => handleSubmitAgain()} />

        <AmpButton
          text="ViewCollection"
          type="secondary"
          symbol="view"
          onClick={() => navigate(RouteOptions.collection)}
        />
        {isAdmin && (
          <AmpButton
            text="GoQA"
            type="secondary"
            symbol="edit"
            onClick={() => navigate(RouteOptions.QA)}
          />
        )}
        <AmpButton
          text="GoHome"
          type="secondary"
          symbol="home"
          onClick={() => navigate(RouteOptions.landing)}
        />
      </Stack>
    </Box>
  );
};

export default AddEvidenceSubmitted;
