import { AddEvidenceForm } from "./AddEvidenceForm";
import { PlatformTypes, Platforms } from "../../models/Platform";

interface Props {
  formData: AddEvidenceForm;
  isReviewSubmit?: boolean;
}

// This function removes "ghost data" produced by user error
// User error comes from incorrect use of inputEvidence UI
const cleanFormData = ({ formData, isReviewSubmit }: Props) => {
  if (!formData?.platformObject) {
    throw new Error("Missing platform!");
  }

  // Add timestamps (created on new content only )
  if (!isReviewSubmit) {
    formData.createdAt = new Date();
  }
  formData.updatedAt = new Date();

  // Retweets only on Tweets
  if (formData.retweetCount && formData.platformObject !== Platforms.Twitter) {
    delete formData.retweetCount;
  }
  if (formData.platformObject?.type === PlatformTypes.SocialMedia) {
    // Clean up socialmedia
    delete formData.mediaName;
  } else {
    // Clean up !socialMedia
    delete formData.socialMediaPostType;
    delete formData.likeCount;
    delete formData.commentCount;
    delete formData.shareCount;
    delete formData.viewCount;
  }

  // Convert platform object to text, delete extraneous, and submit
  formData.platform =
    formData.platformObject?.userDefined || formData.platformObject?.name || undefined;
  delete formData.platformObject;
  delete formData.isQuickAdd;

  // Delete all falsy
  let k: keyof typeof formData;
  for (k in formData) {
    if (!formData[k]) delete formData[k];
  }

  return formData;
};

export default cleanFormData;
