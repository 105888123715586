// Global
import { useMemo } from "react";
import { FacebookEmbed, TwitterEmbed } from "react-social-media-embed";

// MUI
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

// Local
import { Content } from "../../../models/Content";
import useSocialShare from "../../../hooks/useSocialShare";
import { COLORS, responseCardSize } from "../../../lib/theme/default";
import { DefaultContent, TogoCheck, WHOContent } from "./CustomContentFormats";

// URL Constants
const WHO_URL = "www.who.int";
const TOGOCHECK_URL = "togocheck.com";
const TWITTER_URL = "twitter.com";
const X_URL = "/x.com";
const FACEBOOK_URL = "facebook.com";

interface Props {
  content: Content;
}

// This component returns a single share option
const ContentItem = ({ content }: Props) => {
  if (!content.binaryPath) {
    return null;
  }

  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Hook to manage share button
  const shareButtons = useSocialShare(content);

  let platformString: string = "";

  if (content.binaryPath.toLowerCase().includes(WHO_URL)) {
    platformString = WHO_URL;
  } else if (content.binaryPath.toLowerCase().includes(TOGOCHECK_URL)) {
    platformString = TOGOCHECK_URL;
  } else if (
    content.binaryPath.toLowerCase().includes(TWITTER_URL) ||
    content.binaryPath.toLowerCase().includes(X_URL)
  ) {
    platformString = TWITTER_URL;
  } else if (content.binaryPath.toLowerCase().includes(FACEBOOK_URL)) {
    platformString = FACEBOOK_URL;
  }

  const platform = useMemo(() => {
    switch (platformString) {
      case WHO_URL:
        return (
          <a href={content.binaryPath} target="_blank" rel="noopener noreferrer">
            <WHOContent content={content} />
          </a>
        );
      case TOGOCHECK_URL:
        return (
          <a href={content.binaryPath} target="_blank" rel="noopener noreferrer">
            <TogoCheck content={content} />
          </a>
        );
      case TWITTER_URL:
        return (
          <TwitterEmbed
            url={content.binaryPath || ""}
            width={isSmallScreen ? "100%" : responseCardSize.Twitter}
          />
        );
      case FACEBOOK_URL:
        return (
          <FacebookEmbed
            url={content.binaryPath || ""}
            width={isSmallScreen ? "100%" : responseCardSize.Facebook}
            height={400}
          />
        );
      default:
        // Your custom code here
        return <DefaultContent content={content} />;
    }
  }, [platformString, content.binaryPath, isSmallScreen]);

  // Displays the response content for the source platform
  return (
    <Stack spacing={1} p={1} boxShadow={"0 0 10px rgba(0,0,0,0.1)"} borderRadius={0.5}>
      {platform}
      {shareButtons}
    </Stack>
  );
};
export default ContentItem;
