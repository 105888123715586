// Global
import { useRef, useEffect, useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

// MUI
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

// Local
import MainStage from "../components/navigation/MainStage";
import useNewUserRequests, { UserRequest } from "../hooks/useNewUserRequests";
import CircleLoading from "../components/navigation/CircleLoading";
import AmpCard from "../components/cards/AmpCard";
import { AmpCardTypes, AmpDataSubmitTypes, RouteOptions } from "../lib/util/enums";
import { useTranslation } from "react-i18next";
import { COLORS } from "../lib/theme/default";
import { getUtcDateLocalized } from "../lib/util/dateFormatters";
import SubNav from "../components/navigation/SubNav";

import submitData from "../lib/util/submitData";
import { ApiTokenContext } from "../contexts/InitContexts";
import useQueryInvalidation from "../hooks/useQueryInvalidation";
import { getUserRequestsQuery } from "../lib/api/keyConstants";
import AmpButton from "../components/buttons/AmpButton";

/* This function displays the user account requests
 * and allows the admin users to approve or deny them */

const UserManager = () => {
  // Global hooks
  const { t } = useTranslation();
  const subNavRef = useRef<HTMLDivElement | null>(null);
  const [subNavHeight, setSubNavHeight] = useState(0);
  const apiToken = useContext(ApiTokenContext);
  const { invalidate } = useQueryInvalidation(getUserRequestsQuery);

  const { userRequests, isLoading } = useNewUserRequests();

  useEffect(() => {
    if (subNavRef.current) {
      setSubNavHeight(subNavRef.current.offsetHeight);
    }
  }, []);

  // Mutation to update the status of a userRequest in index
  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting update to database!");
    },
    onSuccess: () => {
      console.log("Submit successful.");
    },
  });

  // update the index, send update to Auth0
  // Action if the user changes the status of a userRequest
  const handleStatusChange = (userId: string, status: string) => {
    console.log(userId, status);
    mutation.mutate({
      submitType: AmpDataSubmitTypes.UserRegistration,
      data: { id: userId, status },
      apiToken,
    });
  };

  return isLoading ? (
    <CircleLoading />
  ) : (
    <Box>
      {/* Subnav for Content manager */}
      <SubNav view={RouteOptions.admin} ref={subNavRef}>
        <Typography variant="h2">New User Registrations</Typography>
      </SubNav>

      <MainStage subNavHeight={subNavHeight}>
        <Box m={1} display="flex" justifyContent="flex-end">
          <Tooltip title={t("Refresh")}>
            <RefreshIcon style={{ color: COLORS.secondaryGray }} onClick={invalidate} />
          </Tooltip>
        </Box>

        {userRequests &&
          userRequests.map((user) => {
            return (
              <UserRequestCard key={user.id} user={user} handleStatusChange={handleStatusChange} />
            );
          })}
      </MainStage>
    </Box>
  );
};
export default UserManager;

// Individual card for each user request
const UserRequestCard = ({
  user,
  handleStatusChange,
}: {
  user: UserRequest;
  handleStatusChange: (userId: string, status: string) => void;
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(true);
  const [result, setResult] = useState<string | null>(null);

  // Date formatting
  const localDate = getUtcDateLocalized({ date: new Date(user.lastUpdated), userLang: "en" });

  // Handle status change
  const resolveRequest = (userId: string, status: string) => {
    handleStatusChange(userId, status);
    setIsActive(false);
    setResult(status);
  };

  return (
    <AmpCard type={AmpCardTypes.report}>
      <Stack spacing={1}>
        <Typography variant="h2" color={COLORS.primaryBlue}>
          {user.email}
        </Typography>

        {user.role && (
          <Typography variant="body1">
            {t("userRequest.role")}: {user.role}
          </Typography>
        )}
        {user.organization && (
          <Typography variant="body1">
            {t("userRequest.organization")}: {user.organization}
          </Typography>
        )}

        {user.country && (
          <Typography variant="body1">
            {t("userRequest.country")}: {user.country}
          </Typography>
        )}
        {user.reason && (
          <Typography variant="body1">
            {t("userRequest.reason")}: {user.reason}
          </Typography>
        )}
        {/* STATUS PENDING: User feedback buttons */}
        {user.status === "pending" && (
          <Tooltip title={t("userRequest.statusTooltip")}>
            <Stack direction="row" spacing={2}>
              <AmpButton
                text="Deny"
                type="secondary"
                onClick={() => resolveRequest(user.id, "denied")}
                disabled={!isActive}
              />
              <AmpButton
                text="Approve"
                type="primary"
                onClick={() => resolveRequest(user.id, "approved")}
                disabled={!isActive}
              />
            </Stack>
          </Tooltip>
        )}
        {user.status !== "pending" && (
          <Typography variant="body2">
            This account request was <strong>{user.status}</strong>.
          </Typography>
        )}

        <Typography variant="body2" color="text.secondary">
          Last Updated: {localDate}. {result && <strong>Status: {result}</strong>}
        </Typography>
      </Stack>
    </AmpCard>
  );
};
