import axios, { AxiosError, ResponseType } from "axios";

interface AxiosConfig {
  method?: string;
  token?: string;
  baseURL?: string;
  url?: string;
  endpoint?: string;
  headers?: { Authorization?: string; "Content-Type"?: string };
  params?: any;
  data?: any;
  maxBodyLength?: number;
  responseType?: ResponseType;
}

export interface AxiosProps {
  method?: string;
  token?: string;
  headers?: { Authorization?: string; "Content-Type"?: string };
  external?: boolean;
  endpoint?: string;
  params?: object;
  data?: any;
  contentType?: string;
  responseType?: ResponseType;
}

// Resuable call to internal and external APIs
const useAxios = async ({
  method = "get", // Default: get
  token, // Token for API
  external, // true for external API
  endpoint = "", // API endpoint
  params,
  data,
  contentType,
  responseType,
}: AxiosProps) => {
  // Base config
  let config = { headers: {} } as AxiosConfig;

  // Set conditional headers
  if (token) {
    config.headers = { ...config.headers, Authorization: "Bearer " + token };
  }
  if (contentType) {
    config.headers = { ...config.headers, "Content-Type": contentType };
  }

  if (!external) {
    // If external=false, include baseURL to AMP API
    config = { ...config, baseURL: import.meta.env.REACT_APP_DEV_API_ENDPOINT };
  }

  // Axios config options: AMP API
  config = {
    ...config,
    method: method,
    url: endpoint,
    maxBodyLength: Infinity,
    params: params,
    data: data,
    responseType: responseType || "json",
  };

    const response = await axios.request(config);
    return response;

};

export default useAxios;
