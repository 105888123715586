// Global
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import { AmpCardTypes, BodyFontSize } from "../../lib/util/enums";
import EditableText from "./EditableText";
import { ReportDataWithFullText } from "../../lib/util/interfaces";
import AmpCard from "../cards/AmpCard";
import EvidenceCard from "../cards/EvidenceCard";
import AmpButton from "../buttons/AmpButton";
import HideListItem from "./HideListItem";
import ReportCharts from "./ReportCharts";
import { getUtcDateLocalized } from "../../lib/util/dateFormatters";
import LogoMenu from "./LogoMenu";

// Local

interface Props {
  isForExport?: boolean;
  exportData: ReportDataWithFullText;
  updateReport?: (name: string, text: string, index?: number) => void;
  archiveExample?: (id: string) => void;
}
const ReportMainStage = ({ exportData, isForExport, updateReport, archiveExample }: Props) => {
  // Global
  const { t, i18n } = useTranslation();
  const userLang = i18n.language;

  // Local
  const componentRef = useRef(null);
  // Get data date range from index
  const dateRangeString = exportData?.reportData?.data?.dateRange
    ? getUtcDateLocalized({
        date: exportData.reportData.data.dateRange.gte.toDate(),
        userLang: userLang,
      }) +
      " - " +
      getUtcDateLocalized({
        date: exportData.reportData.data.dateRange.lte.toDate(),
        userLang: userLang,
      })
    : undefined;

  const handleUpdateReportText = (name: string, text: string, index?: number) => {
    if (updateReport) {
      updateReport(name, text, index);
    }
  };

  return (
    <Box>
      {isForExport && (
        <Box my={2} display="flex" justifyContent={"center"}>
          <ReactToPrint
            trigger={() => <AmpButton text="PrintReport" symbol="print" />}
            content={() => componentRef.current}
          />
        </Box>
      )}
      <Grid container m={2} spacing={1} ref={componentRef} width={800}>
        <Grid xs={12} display="flex" justifyContent={"center"}>
          <Stack direction={"row"} spacing={4} display="flex" alignItems={"center"}>
           <LogoMenu />

            <EditableText
              name={"ReportTitle"}
              text={exportData.reportData?.data.ReportTitle || ""}
              isTranslationKey
              typographyVariant="h1"
              isEditable={!isForExport}
              updateText={handleUpdateReportText}
            />
          </Stack>
        </Grid>

        {dateRangeString && (
          <Grid xs={12} display="flex" justifyContent={"center"}>
            <Typography variant="body1">
              {t("generatedReport.DateRange")}
              {dateRangeString}
            </Typography>
          </Grid>
        )}

        {/* Top-level findings */}
        <Grid xs={12} md={12} mt={1}>
          <Box display="flex" justifyContent={"center"}>
            <EditableText
              name="Subtitle"
              text={exportData.reportData?.data.Subtitle || ""}
              isTranslationKey
              typographyVariant="h2"
              isEditable={!isForExport}
              updateText={handleUpdateReportText}
            />
          </Box>
          <Stack mt={2} spacing={2}>
            {exportData.reportData?.data.Headline && (
              <EditableText
                name="Headline"
                text={exportData.reportData.data.Headline}
                bodyFontSize={BodyFontSize.Default}
                // hasBulletPoint
                isEditable={!isForExport}
                updateText={handleUpdateReportText}
              />
            )}
          </Stack>
        </Grid>
        <Grid xs={12} md={12}>
          {exportData.reportData?.data.dataGroups?.map((group, index) => (
            <Stack key={index} display="flex" justifyContent={"center"} spacing={1}>
              {/* 
              Map all blocks of dataGroups (headline/analysis/examples)
              */}
              <EditableText
                name="Title"
                text={group.Title || ""}
                typographyVariant="h3"
                bodyFontSize={BodyFontSize.Large}
                isEditable={!isForExport}
                index={index}
                updateText={handleUpdateReportText}
              />
              <EditableText
                name="Analysis"
                text={group.Analysis || ""}
                bodyFontSize={BodyFontSize.Small}
                // hasBulletPoint
                isEditable={!isForExport}
                index={index}
                updateText={handleUpdateReportText}
              />
              {group.Ids.map((id: string) => {
                const evidence = exportData.evidenceList.find((evidence) => evidence.id === id);
                return (
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    key={id}
                    width={1}
                  >
                    {evidence && (
                      <AmpCard type={AmpCardTypes.evidence}>
                        <EvidenceCard evidence={evidence} isExample />
                      </AmpCard>
                    )}
                    {!isForExport && archiveExample && evidence && (
                      <HideListItem id={evidence.id} archiveItem={archiveExample} />
                    )}
                  </Box>
                );
              })}
            </Stack>
          ))}

          <Grid display="flex" justifyContent={"center"} my={2}>
            <EditableText
              name="ChartsTitle"
              text={exportData.reportData?.data.ChartsTitle || ""}
              isTranslationKey
              typographyVariant="h2"
              isEditable={!isForExport}
              updateText={handleUpdateReportText}
            />
          </Grid>
          <ReportCharts data={exportData.reportData?.data} />
          <Grid display="flex" justifyContent={"center"} alignItems={"center"} my={2} xs={12}>
            <EditableText
              name="MethodologyTitle"
              text={exportData.reportData?.data.MethodologyTitle || ""}
              isTranslationKey
              typographyVariant="h2"
              isEditable={!isForExport}
              updateText={handleUpdateReportText}
            />
          </Grid>
          <Grid display="flex" mb={2}>
            <Typography variant="body2"> {t("generateReport.Methodology")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportMainStage;
