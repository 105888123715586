import dayjs from "dayjs";
import { ChartDataProps, DateRangeProps } from "../lib/util/interfaces";
import { dateRangeStringToDayJs } from "../lib/util/dateFormatters";

// This is the Report model for useReport
export class Report {
  readonly id: string;
  readonly active: boolean;
  readonly authorId: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly data: ReportData;

  constructor(data: any) {
    const source = data._source;

    this.id = data._id;
    this.active = source.active === false ? false : true;
    this.createdAt = new Date(source.createdAt);
    this.updatedAt = new Date(source.updatedAt);
    this.authorId = source.authorId;

    const sourceData = JSON.parse(source.data);
    this.data = new ReportData(sourceData);
  }
}

// A class to build the data and analysis elements of the report
export class ReportData {
  ReportTitle: string;
  Subtitle: string;
  Headline: string;

  dataGroups?: { Title: string; Analysis: string; Ids: string[] }[];
  ChartsTitle: string;
  LineChartAnalysis: string;
  MapAnalysis: string;
  chartData?: ChartDataProps;
  dateRange?: DateRangeProps;
  MethodologyTitle: string;
  Methodology: string;

  constructor(sourceData: any) {
    this.ReportTitle = sourceData.ReportTitle || "generateReport.ReportTitle";
    this.Subtitle = sourceData.Subtitle || "generateReport.Subtitle";
    this.Headline = sourceData.Headline || "";

    this.dataGroups = sourceData?.dataGroups?.map((group: any) => ({
      Title: group.Title,
      Analysis: group.Analysis,
      Ids: group.Ids,
    }));
    this.ChartsTitle = sourceData.ChartsTitle || "generateReport.ChartsTitle";
    this.LineChartAnalysis = sourceData.LineChartAnalysis;
    this.MapAnalysis = sourceData.MapAnalysis;
    this.chartData = sourceData.chartData
      ? {
          ...sourceData.chartData,
          // Format lineChartData with dayjs formatting
          lineChartData: sourceData.chartData.lineChartData.map((day: any) => ({
            count: day.count,
            date: new Date(day.date),
          })),
        }
      : undefined;
    this.dateRange = dateRangeStringToDayJs({ range: sourceData.dateRange });
    this.MethodologyTitle = sourceData.MethodologyTitle || "generateReport.MethodologyTitle";
    this.Methodology = sourceData.Methodology || "generateReport.Methodology";
  }
}
