// MUI
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

// Local
import useContentCards from "../../hooks/useContentCards";
import { Content } from "../../models/Content";
import AmpCard from "../cards/AmpCard";
import { appMaxWidth, appMobileWidth, mainStageMax } from "../../lib/theme/default";
import CircleLoading from "../navigation/CircleLoading";
import DisplayResultCount from "../cards/DisplayResultCount";
import LoadMoreButton from "../buttons/LoadMoreButton";
import ContentCard from "../cards/ContentCard";
import { AmpCardTypes, AmpDataSubmitTypes } from "../../lib/util/enums";
import ContentGrid from "./ContentGrid";

const ViewContentCards = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks
  const {
    contentList,
    selectedRows,
    resultCount,
    isLoading,
    error,
    setSelectedRows,
  } = useContentCards();

  return isLoading ? (
    <CircleLoading />
  ) : !!error ? (
    <div>{error.toString()}</div>
  ) : (
    <Box
      maxWidth={isSmallScreen ? appMobileWidth : mainStageMax}
      justifyContent={"center"}
      mt={1}
    >
      {" "}
      {/* Display number of results to user  */}
      {!!resultCount && (
        <DisplayResultCount
          count={resultCount.toString()}
          dataType={AmpDataSubmitTypes.Content}
        />
      )}
      <Box mb={2}>
        <ContentGrid
          contentList={contentList}
          setSelectedRows={setSelectedRows}
        />
      </Box>
      <Box width={1} justifyContent={"center"} display="flex">
        <Stack>
          {selectedRows &&
            // Map each content card
            selectedRows.map((content: Content) => (
              <AmpCard type={AmpCardTypes.content} key={content.id}>
                <ContentCard data={content} />
              </AmpCard>
            ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default ViewContentCards;
