import { createContext } from "react";
import { labeledObject } from "../lib/util/interfaces";
import { Country } from "../models/Locations";

const ApiTokenContext = createContext("");

const ThemesContext = createContext<labeledObject[]>([]);
const IssuesContext = createContext<labeledObject[]>([]);

const CountriesContext = createContext<Country[]>([]);

export { ApiTokenContext, ThemesContext, IssuesContext, CountriesContext };
