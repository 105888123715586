// MUI
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import useContent from "../../hooks/useContent";
import CircleLoading from "../navigation/CircleLoading";
import ContentItem from "../drawers/content/ContentItem";
import AmpButton from "../buttons/AmpButton";

interface Props {
  issueIds?: string[]
}

const TrendingContent = (props: Props) => {
  // Global
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { contentList, isLoading, isNextButtonDisabled, page, setPage } = useContent({
    isGetRecent: true,
    issueIds: props.issueIds,
  });

  if (isLoading) {
    return <CircleLoading />;
  }

  return (
    <Stack spacing={2} width="100%">
      {/* Display individual response options */}
      <Grid container rowSpacing={2}>
        {contentList?.map((content) => (
          <Grid xs={12} md={6} lg={4}>
            <Box key={content.id} display="flex" justifyContent={"center"}>
              <ContentItem content={content} />
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* Pagination buttons */}
      <Stack
        direction="row"
        display="flex"
        justifyContent={!!page && page > 0 ? "space-between" : "end"}
      >
        {!!page && page > 0 && (
          <Box ml={isSmallScreen ? 0 : 2} mb={1}>
            <AmpButton text="Previous" type="secondary" onClick={() => setPage(page - 1)} />
          </Box>
        )}
        {page !== undefined && (
          <Box mr={isSmallScreen ? 0 : 2} mb={1}>
            <AmpButton
              text="Next"
              disabled={isNextButtonDisabled}
              onClick={() => setPage(page + 1)}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default TrendingContent;
