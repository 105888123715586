// Globals
import { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";

// Local
import { AddEvidenceForm } from "../lib/addEvidenceForms/AddEvidenceForm";
import { formatUniqueS3Filename } from "../lib/util/formatters";
import createPresignedUrl from "../lib/api/createPresignedUrl";
import uploadAttachments from "../lib/api/uploadAttachments";

export interface FileObjProps {
  file: File;
  key: string | undefined;
  url: string | undefined;
  path: string | undefined;
}

interface Props {
  formData: AddEvidenceForm;
  apiToken: string;
  finishSubmit: (attachments: object) => void;
}

// Manage user-selected attachments in AddEvidence wizard
const useAddAttachments = ({ formData, apiToken: token, finishSubmit }: Props) => {
  const [fileObjList, setFileObjList] = useState<FileObjProps[]>([]);
  const [needsUrl, setNeedsUrl] = useState<FileObjProps>();

  const existingAttachmentsCount = formData.attachments ? formData.attachments?.length : 0;

  // Update fileList when user adds attachment
  const handleChange = async (file: FileList) => {
    const fileListArray = Array.from(file);
    const newObjs: FileObjProps[] = fileListArray.map((item, index) => {
      const arrayIndex = index + 1 + fileObjList?.length;
      const fileExtension = item.name.slice(item.name.lastIndexOf("."));
      const key = formatUniqueS3Filename({
        uuid: formData.id,
        fileExtension,
        attachmentIndex: arrayIndex + existingAttachmentsCount,
      });

      return { file: item, key: key, url: undefined, path: undefined };
    });

    setFileObjList([...fileObjList, ...newObjs]);
  };

  // A series of useEffects to upload files on isSubmitting
  const mutation = useMutation(uploadAttachments);
  // Listen to additions to fileObjList, get key and presignedURL
  useEffect(() => {
    const fileWithoutUrl = fileObjList.find((fileObj) => fileObj.file && !fileObj.url);
    if (fileWithoutUrl) {
      setNeedsUrl(fileWithoutUrl);
    } else {
      setNeedsUrl({} as FileObjProps);
    }
  }, [fileObjList]);

  const { data: presignedUrl } = useQuery({
    queryKey: ["createPresignedUrl", { token, key: needsUrl?.key }],
    queryFn: createPresignedUrl,
    select: (data) => {
      return { url: data?.data.presignedUrl, key: data?.data.key };
    },
    enabled: !!needsUrl?.key && !!token,
  });

  // Set fileOjbList with new presignedUrl
  useEffect(() => {
    setFileObjList(
      fileObjList.map((fileObj) =>
        // CP
        fileObj.key === needsUrl?.key
          ? { ...fileObj, url: presignedUrl?.url, path: presignedUrl?.key }
          : fileObj
      )
    );
  }, [presignedUrl]);

  // Function to begin the upload process
  const doUpload = async () => {
    const uploadAll = fileObjList.map((item) =>
      mutation.mutateAsync({ presignedUrl: item.url, attachment: item.file })
    );

    try {
      await Promise.all(uploadAll);
      // CP
      const uploadedKeys = fileObjList.map((item) => item.path);
      finishSubmit({
        // Check if attachments already added by quickAdd
        attachments: formData.attachments
          ? fileObjList?.length > 0
            ? [...formData.attachments, ...uploadedKeys]
            : [...formData.attachments]
          : uploadedKeys,
      });
    } catch (error) {
      console.log("An error has occured: ", error);
    }
  };

  // Remove attachments when user clicks "X"
  const handleRemove = (index: number) => {
    const newArray = [...fileObjList];
    newArray.splice(index - 1, 1);
    setFileObjList(newArray);
  };

  // Select files to display in components and return
  const files = fileObjList.map((item) => item.file);
  return { fileArray: files, handleChange, handleRemove, setFileObjList, doUpload };
};
export default useAddAttachments;
