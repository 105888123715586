import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

// Local
import AttachedFile from "./AttachedFile";
import DragDropDesign from "./dragDropDesign";
import { FileTypes } from "../../models/FileTypes";
import { maxAttachmentSize } from "../../lib/configs";
import AlertDialogSlide from "../AlertSlideDialog";
import useCustomError from "../../hooks/useCustomError";
import { AttachmentHandlerProps } from "../../lib/util/interfaces";

interface Props {
  attachmentHandler: AttachmentHandlerProps;
}

// Display AddAttachments drop zone, manage attachments
const AddAttachments = ({ attachmentHandler }: Props) => {
  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { customError, setCustomError } = useCustomError();

  // Get allowable file types
  const extValues = FileTypes.map((fileType) => fileType.ext);

  // Text labels (prompt and innner instructions)
  const questionLabel = t("forms.DragDrop.Label");
  const innerLabel = t("forms.DragDrop.InnerLabel");

  return (
    <Box>
      <Typography variant="h3" mt={2}>
        {questionLabel}
      </Typography>
      {/* DragDrop zone */}
      <Box width={1} my={1} display={isSmallScreen ? "auto" : "inline-flex"} alignItems={"start"}>
        <Box
          display={isSmallScreen ? "auto" : "inline-flex"}
          width={isSmallScreen ? 1 : "50%"}
          height={160}
        >
          {/* This component handles attaching files */}
          <FileUploader
            handleChange={attachmentHandler.handleChange}
            name="file"
            types={extValues} // Allowable file extensions
            multiple={true} // Allow multiple files
            maxSize={maxAttachmentSize} // Max file size in MBs
            children={DragDropDesign(innerLabel)} // Component display style
            onTypeError={() => setCustomError({message: "errors.FileType"})}
            onSizeError={() => setCustomError({message: "errors.FileSize"})}
          />
        </Box>
        <Box ml={isSmallScreen ? 0 : 2} mt={isSmallScreen ? 2 : 0}>
          {/* Mapped reports of uploaded files */}
          {attachmentHandler.fileArray.length > 0 && (
            <Typography variant="h4">{t("forms.DragDrop.FileList")}</Typography>
          )}
          {attachmentHandler.fileArray.map((attachment, index) => (
            <Box key={index}>
              <AttachedFile
                attachment={attachment}
                attachmentIndex={index + 1}
                handleRemove={attachmentHandler.handleRemove}
              />
            </Box>
          ))}
          {/* Alert dialogue for errors: File Type or File Size */}
          <AlertDialogSlide
            title={customError?.message + ".title"}
            message={customError?.message + ".message"}
            confirm="Confirm"
            // Open on error message
            isOpen={!!customError}
            // Reload window on dialogue close
            handleUserChoice={() => setCustomError(undefined)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddAttachments;
