import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";

// This component handles the authorization for admin routes
export const ProtectedAdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth0();

  const isAdmin = "admin" === user?.["https://amp.rootwise.co/role"];

  return isAdmin ? <>{children}</> : <Navigate to="/unauthorized" />;
};

interface Props {
  environment?: boolean;
}

// This component handles unauthorized access to certain environments or functions
export const UnauthorizedRoute = ({ environment }: Props) => {
  const { user } = useAuth0();
  const location = useLocation();

  // AuthZ or AuthN
  const reason = environment ? " environment " : " function ";

  // Set login message for expired token or AuthZ block
  const message =
    location?.state?.status === "401"
      ? `The access token for ${user?.name} may have expired. Try logging out and in again.`
      : `${user?.name} does not have access to this ${reason} in AMP.`;

  // Need refresh
  return (
    <Box m={2} display="flex" justifyContent={"center"}>
      <Typography variant="h3">{message}</Typography>
    </Box>
  );
};
