// Global
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

// Local
import useAxios from "./useAxios";
import { scrapeEvidenceQuery } from "../lib/api/keyConstants";

interface QueryProps {
  queryKey: [string, { token: string; url: string; autoSubmit?: boolean }];
}

// Business logic to get back Social Media metadata from the provided URL
const scrapeEvidence = async ({ queryKey }: QueryProps) => {
  const [_key, { token, url }] = queryKey;

  // Add params. Delete autosubmit if false
  const params = { url: url };

  // Call endpoint, await results
  return await useAxios({
    token: token,
    endpoint: "/scrapeEvidence",
    params: params,
  });
};

interface Props {
  apiToken: string;
  stepHandler: {
    isFetching: boolean;
    setIsFetching: (value: boolean) => void;
    setIsDetailAdd: (value: boolean) => void;
  };
  updateFormData: (values: object) => void;
  setCustomError: (error: { message: string }) => void;
}

// The function supports AddDetail by retrieving scrape data
const useScrapeURL = (props: Props) => {
  const [url, setUrl] = useState<string>("");

  // Call useQuickAdd from component
  const handleScrapeUrl = (url: string) => {
    setUrl(url);
  };

  // Ping /scrapeEvidence endpoint
  const { error, data, isFetching } = useQuery({
    queryKey: [scrapeEvidenceQuery, { token: props.apiToken, url }],
    queryFn: scrapeEvidence,
    enabled: !!props.apiToken && !!url,
  });

  if (isFetching && !props.stepHandler.isFetching) {
    props.stepHandler.setIsFetching(true);
  }

  // Graceful error handle: Require manual user input
  if (error || (!!data?.status && data.status !== 200)) {
    props.setCustomError({ message: "503" });
    props.stepHandler.setIsFetching(false);
    setUrl("");
  }

  if (data?.status === 200) {
    // Clear the query, update the form and buttons
    props.updateFormData(data.data.response);
    props.stepHandler.setIsFetching(false);
    props.stepHandler.setIsDetailAdd(true);
    setUrl("");
  }
  return { handleScrapeUrl };
};

export default useScrapeURL;
