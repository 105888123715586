// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Local
import { Content } from "../../models/Content";
import { getUtcDateLocalized } from "../../lib/util/dateFormatters";
import { COLORS, smallFontSize } from "../../lib/theme/default";
import ThemeList from "./ThemeList";
import IssuePills from "./IssuePills";
import { idLabelLookup } from "../../lib/util/idLookups";
import { africanLanguages } from "../../models/Languages";
import { urlLengthCard } from "../../lib/configs";
import AmpSwitch from "../buttons/AmpSwitch";
import { content } from "../../lib/api/keyConstants";

// Local

interface Props {
  data: Content;
}

// Display a Content Card
const ContentCard = ({ data }: Props) => {
  // Global hooks
  const { t, i18n } = useTranslation();
  const userLang = i18n.language;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and state
  const date = data.updatedAt;

  const handleClick = () => {
    window.open(data.binaryPath, "_blank");
  };

  const urlLength = isSmallScreen ? urlLengthCard.xs : urlLengthCard.md;
  const urlDisplay =
    data.binaryPath &&
    (data.binaryPath.length > urlLength
      ? data.binaryPath.slice(0, urlLength) + "... "
      : data.binaryPath);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid xs={12} md={12}>
          {/* Text area */}
          <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent={"space-between"}>
              {/* Title */}
              <Typography variant="subtitle2">
                {data.nameTranslated?.fr || data.nameTranslated?.en}{" "}
              </Typography>
              <AmpSwitch id={data.id} isActive={data.active} />
            </Stack>

            {/* Theme List */}
            <ThemeList ids={data.themeIds} />

            {/* Description */}
            <Typography variant="h4" sx={{ fontSize: smallFontSize }}>
              {data.descriptionTranslated?.fr || data.descriptionTranslated?.en}{" "}
            </Typography>

            {/* Other Metadata */}
            {data?.code && (
              <Box display="flex" alignItems={"baseline"}>
                <Typography variant="h4" mr={0.25} sx={{ fontSize: smallFontSize }}>
                  {t("contentManager.contentCard.Code")}
                </Typography>
                <Typography variant="body1">{data.code}</Typography>
              </Box>
            )}
            <Stack direction="column" spacing={0.5}>
              {data.language && (
                <Box display="flex" alignItems={"baseline"}>
                  <Typography
                    variant="h4"
                    mr={0.25}
                    sx={{ fontSize: smallFontSize }}
                  >
                    {t("contentManager.contentCard.Language")}
                  </Typography>
                  <Typography variant="body1">
                    {idLabelLookup({
                      id: data.language,
                      data: africanLanguages,
                    })}
                  </Typography>
                </Box>
              )}
              <Box display="flex" alignItems={"baseline"}>
                <Typography variant="h4" mr={0.25} sx={{ fontSize: smallFontSize }}>
                  {t("contentManager.contentCard.AddedBy")}
                </Typography>
                <Typography variant="body1">{data.authorId}</Typography>
              </Box>
              <Box display="flex" alignItems={"baseline"}>
                <Typography variant="h4" mr={0.25} sx={{ fontSize: smallFontSize }}>
                  {t("contentManager.contentCard.LastUpdated")}
                </Typography>
                <Typography variant="body1">
                  {getUtcDateLocalized({ date, userLang })}{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems={"baseline"}>
                <Typography variant="h4" mr={0.25} sx={{ fontSize: smallFontSize }}>
                  {t("contentManager.contentCard.Id")}
                </Typography>
                <Typography variant="body1"> {data.id}</Typography>
              </Box>
              <Box>
                <Tooltip title={data.binaryPath}>
                  <Typography
                    variant="body1"
                    sx={{ cursor: "pointer", color: COLORS.hoverBlue }}
                    onClick={handleClick}
                  >
                    {urlDisplay}
                  </Typography>
                </Tooltip>
              </Box>
            </Stack>

            {/* Issues */}
            <IssuePills ids={data.issueIds} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentCard;
