// Platform class
export class Platform {
  type: PlatformTypes;
  name: string;
  icon?: string;
  userDefined?: string;
  isQuickAddType?: boolean;

  constructor(type: PlatformTypes, name: string, isQuickAddType: boolean = false, icon?: string) {
    this.type = type;
    this.name = name;
    this.isQuickAddType = isQuickAddType;
    this.icon = icon;
  }
}

// Platform Types
export enum PlatformTypes {
  Media = "Media",
  Other = "Other",
  SocialMedia = "SocialMedia",
  UserDefined = "UserDefined",
  QuickSubmit = "QuickSubmit",
}

// Platforms Data
export const Platforms = {
  // First quad
  Radio: new Platform(PlatformTypes.Media, "Radio", false, "Radio.svg"),
  TV: new Platform(PlatformTypes.Media, "TV", false, "TV.svg"),
  OnlineNews: new Platform(PlatformTypes.Media, "Online News", true, "News.svg"),
  WhatsApp: new Platform(PlatformTypes.Other, "WhatsApp", false, "WhatsApp.svg"),

  // Second quad
  Facebook: new Platform(PlatformTypes.SocialMedia, "Facebook", false, "Facebook.svg"),
  Twitter: new Platform(PlatformTypes.SocialMedia, "Twitter", false, "Twitter.svg"),
  TikTok: new Platform(PlatformTypes.SocialMedia, "TikTok", true, "TikTok.svg"),
  YouTube: new Platform(PlatformTypes.SocialMedia, "YouTube", false, "YouTube.svg"),

  // Third quad
  Instagram: new Platform(PlatformTypes.SocialMedia, "Instagram", false, "Instagram.svg"),
  Telegram: new Platform(PlatformTypes.SocialMedia, "Telegram", true, "Telegram.svg"),
  WordOfMouth: new Platform(PlatformTypes.Other, "Word Of Mouth", false, "WordOfMouth.svg"),
  Survey: new Platform(PlatformTypes.Other, "Survey", false),

  Other: new Platform(PlatformTypes.UserDefined, "Other", false),
};

// Platforms Data
export const QuickSubmitTypes = {
  URL: new Platform(PlatformTypes.QuickSubmit, "URL"),
  // Date: new Platform(PlatformTypes.QuickSubmit, "Date"),
  Text: new Platform(PlatformTypes.QuickSubmit, "Text"),

  Attachment: new Platform(PlatformTypes.QuickSubmit, "Attachment"),
};
