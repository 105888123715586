// Global
import { useTranslation } from "react-i18next";

// MUI and Formik
import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Form, Formik } from "formik";

// Local
import AutocompleteMultiple from "../../addEvidence/AutocompleteMultiple";
import { EvidenceFiltersProps } from "../../../lib/util/interfaces";
import AmpCalendar from "../../filters/Calendar";
import AmpButton from "../../buttons/AmpButton";
import { hasAllUndefinedProperties } from "../../../lib/util/dataCleaning";
import SortableFieldsControl from "../../filters/SortableFieldsControl";
import ChipInput from "../../addEvidence/ChipInput";
import { EvidenceStatus } from "../../../lib/util/enums";
import TextFieldInput from "../../addEvidence/TextFieldInput";
import { csvStringObjToList } from "../../../lib/util/formatters";

const FiltersDrawer = ({
  evidenceFilters,
  isQA,
  updateEvidenceFilters,
  clearEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and const
  const isClear = hasAllUndefinedProperties(evidenceFilters);

  // In QA, force Status = [] not undefined
  const handleStatus = (values: Record<string, string> | Record<string, string[]>) => {
    if (values?.status?.length === 0) {
      console.log("Must select a value!");
    } else {
      updateEvidenceFilters(values);
    }
  };

  const handleMisinformationSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEvidenceFilters({
      ...evidenceFilters,
      isMisinformation: event.target.checked ? true : undefined,
    });
  };

  return (
    <Box m={2}>
      <Stack direction="column" spacing={2} height={"100%"}>
        <Typography variant="h2">{t("drawers.filters.Title")}</Typography>

        {isSmallScreen && (
          <AmpCalendar
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={updateEvidenceFilters}
          />
        )}
        {isSmallScreen && isQA && (
          <SortableFieldsControl
            evidenceFilters={evidenceFilters}
            updateEvidenceFilters={updateEvidenceFilters}
          />
        )}

        {/* Evidence Status options (QA only) */}
        {isQA && (
          <Formik
            initialValues={{
              ids: evidenceFilters.ids ? evidenceFilters.ids.join(",") : "",
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              // TODO: Need something here to handle inputting IDs
              if ("ids" in values) {
                const cleanedValues = csvStringObjToList(values);
                updateEvidenceFilters(cleanedValues);
              } else {
                updateEvidenceFilters(values);
              }
              setSubmitting(false);
            }}
          >
            <Form>
              {/* Chips to filter Evidence status */}
              <Box>
                <ChipInput
                  label="status"
                  values={Object.values(EvidenceStatus)}
                  selectedValues={evidenceFilters.status}
                  updateFormData={handleStatus}
                  isDisabled={!!evidenceFilters.ids}
                />
                <TextFieldInput field="ids" />
              </Box>
            </Form>
          </Formik>
        )}

        {/* Misinformation switch */}
        <Box display="flex" alignItems="center">
          <FormControlLabel
            label={t("drawers.filters.misinformationLabel")}
            control={
              <Switch
                checked={!!evidenceFilters.isMisinformation}
                onChange={handleMisinformationSwitch}
                disabled={!!evidenceFilters.ids}
              />
            }
            labelPlacement="start"
            sx={{ ml: 0}}
          />
        </Box>

        {/* Multi-select filters */}
        <AutocompleteMultiple
          field="sentiment"
          isFilter
          selectedValues={evidenceFilters?.sentiment}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        <AutocompleteMultiple
          field="countries"
          isFilter
          selectedValues={evidenceFilters?.countries}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        <AutocompleteMultiple
          field="issueIds"
          isFilter
          selectedValues={evidenceFilters?.issueIds}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        <AutocompleteMultiple
          field="themeIds"
          isFilter
          selectedValues={evidenceFilters?.themeIds}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        <AutocompleteMultiple
          field="languages"
          isFilter
          selectedValues={evidenceFilters?.languages}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        <AutocompleteMultiple
          field="platforms"
          isFilter
          selectedValues={evidenceFilters?.platforms}
          updateFormData={updateEvidenceFilters}
          isDisabled={!!evidenceFilters.ids}
        />

        {!isClear && (
          <Box>
            <AmpButton type="text" text="Clear" onClick={clearEvidenceFilters} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
export default FiltersDrawer;
