// Global
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// MUI & Formik
import { Box, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { Form } from "react-router-dom";

// Local
import TextFieldInput from "../../addEvidence/TextFieldInput";
import CancelSubmitButtonPair from "../../buttons/CancelSubmitButtonPair";
import useSendEmail from "../../../hooks/useSendEmail";
import CircleLoading from "../../navigation/CircleLoading";

interface Props {}

const ContactAmp = (props: Props) => {
  // Global hooks
  const { t, i18n } = useTranslation();
  const { user } = useAuth0();

  // Local
  const { flagForReviewData, setEmailDescription, handleSendEmail, status } = useSendEmail({
    dataType: "Help Inquiry",
    user,
  });
  // Return visual based on mutation state
  return status === "loading" ? (
    <CircleLoading />
  ) : status === "error" || status === "success" ? (
    // Message for user post-submit success
    <Box m={2}>
      <Stack direction="column" spacing={2}>
        {status === "success" && (
          <>
            <Typography variant="h2">{t("flagForReview.Submitted1")}</Typography>
            <Typography variant="body1">
              {t("flagForReview.Submitted2")}
              {flagForReviewData.case}
            </Typography>
          </>
        )}
        {/* Message for user if error */}
        {status === "error" && <Typography variant="h2">{t("flagForReview.Error")}</Typography>}
      </Stack>
    </Box>
  ) : (
    // Pre-submit UI
    <Stack direction="column" spacing={2}>
      <Box>
        <Box>
          <Typography variant="h4" display={"inline"}>
            {t("flagForReview.Username")}
          </Typography>
          <Typography variant="body1" ml={0.5} display={"inline"}>
            {flagForReviewData.user}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4" display={"inline"}>
            {t("flagForReview.Date")}
          </Typography>
          <Typography variant="body1" ml={0.5} display={"inline"}>
            {flagForReviewData.date}
          </Typography>
        </Box>
      </Box>

      {/* Free text form */}
      <Formik
        initialValues={{
          flagForReview: flagForReviewData.description || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setEmailDescription(values?.flagForReview);
          setSubmitting(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <TextFieldInput field="flagForReview" rows={4} rowsMobile={5} noTitle />

            <CancelSubmitButtonPair
              submit={handleSendEmail}
              cancel={() => setFieldValue("flagForReview", "")}
            />
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default ContactAmp;
