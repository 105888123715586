import useAxios from "../../hooks/useAxios";

interface Props {
  presignedUrl: string | undefined;
  attachment: File;
}

// Build createPresignedUrl API call
const uploadAttachments = async ({ presignedUrl, attachment }: Props) => {
  if (!presignedUrl) {
    throw new Error("Missing presigned URL in uploadAttachments!");
  }
  const response = await useAxios({
    method: "PUT",
    external: true,
    endpoint: presignedUrl,
    data: attachment,
    contentType: attachment.type,
  });

  return response;
};

export default uploadAttachments;
