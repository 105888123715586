import { Platform } from "../../models/Platform";
import { EvidenceStatus, SocialMediaPostType } from "../util/enums";
import { interactionsSchema } from "../util/validators";

// This is the base data provided to each input step
// Master form data object
export class AddEvidenceForm {
  id: string;
  status?: EvidenceStatus;
  platformObject?: Platform;
  platform?: string;
  text?: string;
  date?: string;
  mediaName?: string;
  submittedLanguage?: string;
  socialMediaPostType?: SocialMediaPostType;
  locations?: string[]; // Syntax: US|New York, US, CA|Toronto
  url?: string;
  sentiment?: string;
  issueIds?: string[];
  themeIds?: string[];
  likeCount?: number;
  commentCount?: number;
  shareCount?: number;
  viewCount?: number;
  retweetCount?: number;
  originalPost?: string;
  notes?: string;
  attachments?: string[];
  authorId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isQuickAdd?: boolean;

  constructor(id: string) {
    this.id = id;
  }
}

export const formikEvidenceFormValues = (formData: AddEvidenceForm) => ({
  platform: formData.platformObject?.name || undefined,
  originalPost: formData.originalPost || undefined,
  text: formData.text || undefined,
  url: formData.url || undefined,
  mediaName: formData.mediaName || undefined,
  likeCount: formData.likeCount || undefined,
  commentCount: formData.commentCount || undefined,
  shareCount: formData.shareCount || undefined,
  viewCount: formData.viewCount || undefined,
  retweetCount: formData.retweetCount || undefined,
  notes: formData.notes || undefined,
});

export const formikValidationSchema = {
  likeCount: interactionsSchema,
  commentCount: interactionsSchema,
  shareCount: interactionsSchema,
  viewCount: interactionsSchema,
  retweetCount: interactionsSchema,
};
